import { v4 as uuidv4 } from "uuid";

export class Media {
  id = "";
  name = "";
  description = "";
  uri = "";

  constructor() {
    this.id = uuidv4();
  }
}
