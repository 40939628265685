<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />

  <ProductRelationHeader
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToProductList="routeToProductList"
    @onSave="saveRelations"
  />

  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="col-span-12">
        <Card>
          <template #content>
            <div class="flex items-center gap-2 mb-6">
              <div class="flex-1 text-color font-semibold leading-6">
                <Button
                  class="c-circular-button mr-4"
                  @click="showAddDialog = true"
                  data-testid="btn-commit"
                  id="btn-commit"
                  style="border-color: var(--p-button-outlined-secondary-border-color)"
                >
                  <i class="pi pi-check c-success-button c-circular-icon"></i>
                  <span class="px-4 font-normal">{{ t("New relation") }}</span>
                </Button>
              </div>
            </div>
            <div>
              <p class="text-xl font-bold mb-2">{{ t("product.relations.header-alternatives") }}</p>
              <ProductRelationList
                :products="relatedProducts"
                :relations="relations"
                :relationTypes="[
                  ProductRelationType.AlternativeCrossSell,
                  ProductRelationType.AlternativeDownSell,
                  ProductRelationType.AlternativeUpSell,
                ]"
                @removeRelation="removeRelation"
                @setRelationType="setRelationType"
              />
            </div>
            <div class="mt-8">
              <p class="text-xl font-bold mb-2">{{ t("product.relations.header-accessories") }}</p>
              <ProductRelationList
                :products="relatedProducts"
                :relations="relations"
                :relationTypes="[ProductRelationType.Accessory]"
                @removeRelation="removeRelation"
              />
            </div>
            <div class="mt-8">
              <p class="text-xl font-bold mb-2">{{ t("product.relations.header-accessory-to") }}</p>
              <ProductRelationList
                :products="relatedProducts"
                :relations="relations"
                :relationTypes="[ProductRelationType.AccessoryTo]"
                @removeRelation="removeRelation"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <ProductRelationAddDialog
    :relations="relations"
    :productId="product.id"
    v-model:visible="showAddDialog"
    @addRelations="addRelations"
  ></ProductRelationAddDialog>
</template>

<script setup lang="ts">
import { Product } from "@/repositories/product/model/Product";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { useProductStore } from "@/repositories/product/ProductStore";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import ProductRelationAddDialog from "@/product/components/ProductRelationAddDialog.vue";
import ProductRelationList from "@/product/components/ProductRelationList.vue";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import ProductRelationHeader from "../ProductRelationHeader.vue";
import router from "@/router/router";

const { getProduct, getProducts, updateProductRelations } = useProductStore();
const { t } = useI18n();
const route = useRoute();

const productId = ref("");
const product = ref<Product>(new Product());
const initialRelations = ref<ProductRelation[]>([]);
const relatedProducts = ref<Product[]>([]);
const relations = ref<ProductRelation[]>([]);
const showAddDialog = ref(false);
const isMounted = ref(false);
const unsavedChangesDialogVisible = ref(false);
const confirmedDiscard = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToProductList();
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  await featchProduct();
  await fetchRelatedProducts();
  initialRelations.value = cloneDeep(relations.value);
  isMounted.value = true;
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const featchProduct = async () => {
  productId.value = route.params.id as string;
  product.value = await getProduct(productId.value);
  relations.value = product.value.relations.sort((a, b) => (a.relationType > b.relationType ? -1 : 1)) ?? [];
};

const fetchRelatedProducts = async () => {
  const productIds = relations.value?.map((relation) => relation.productId) ?? [];
  const missingProductIds = productIds.filter((id) => !relatedProducts.value.some((p) => p.id === id));

  if (missingProductIds.length > 0) {
    const products = await getProducts(missingProductIds);
    relatedProducts.value = [...relatedProducts.value, ...products];
  }
};

const addRelations = async (newRelations: ProductRelation[]) => {
  relations.value = [...relations.value, ...newRelations];
  await fetchRelatedProducts();
};

const saveRelations = async () => {
  await updateProductRelations(productId.value, relations.value);
  relations.value = relations.value.sort((a, b) => (a.relationType > b.relationType ? -1 : 1));
  initialRelations.value = cloneDeep(relations.value);
};

const removeRelation = (relationId: string) => {
  relations.value = relations.value.filter((r) => r.id !== relationId);
};

const setRelationType = (relationId: string, relationType: ProductRelationType) => {
  const relation = relations.value.find((r) => r.id === relationId);
  if (relation) {
    relation.relationType = relationType;
  }
};

const hasUpdates = computed(() => {
  return !isEqual(relations.value, initialRelations.value);
});

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToProductList();
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const routeToProductList = async () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", product.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};
</script>
