<template>
  <label for="manufacturer-name" :class="{ 'c-focused': isInputFocused }">
    {{ t("product.manufacturer.name") }}
  </label>

  <InputText
    ref="manufacturerNameRef"
    type="text"
    class="inputfield w-full"
    maxlength="500"
    v-model="manufacturerNameComputed"
    :class="{ 'p-invalid': val.manufacturerName.$error }"
    :placeholder="t('placeholder.type', { property: t('product.manufacturer.name').toLowerCase() })"
    @focus="selectAllOnFocus"
    @blur="isInputFocused = false"
    data-testid="manufacturer-name"
  />

  <small
    id="manufacturer-name-help"
    class="p-error"
    data-testid="manufacturer-name-error"
    v-if="val.manufacturerName.$error"
  >
    {{ val.manufacturerName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  manufacturerName: string;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerName", value: string): void;
}>();

const { t } = useI18n();
const manufacturerNameRef = ref();
const isInputFocused = ref(false);

const manufacturerNameComputed = computed<string>({
  get() {
    return props.manufacturerName;
  },
  set(value: string) {
    emit("update:manufacturerName", value);
  },
});

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
  isInputFocused.value = true;
};

onMounted(() => {
  manufacturerNameRef.value.$el.focus();
});

const rules = {
  manufacturerName: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
