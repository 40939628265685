<template>
  <div class="flex gap-2">
    <span
      :class="[
        'text-xs px-2 py-1 rounded-full whitespace-nowrap',
        hasDefaultValue
          ? 'bg-blue-100 text-blue-800'
          : isMapped
            ? 'bg-green-100 text-green-800'
            : 'bg-yellow-100 text-yellow-800',
      ]"
    >
      {{
        hasDefaultValue
          ? t("product.import.default-set")
          : isMapped
            ? t("product.import.mapped")
            : t("product.import.not-mapped")
      }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps<{
  isMapped: boolean;
  hasDefaultValue: boolean;
}>();

const { t } = useI18n();
</script>
