import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { Manufacturer } from "./Manufacturer";

export class UpdateManufacturer extends Manufacturer {
  imagesToDelete: ImageInfo[] = [];

  constructor(manufacturer: Manufacturer, imagesToDelete: ImageInfo[] = []) {
    super(manufacturer);
    this.imagesToDelete = imagesToDelete;
  }
}
