import { ProductTax } from "./ProductTax";
import { type Tax } from "@/repositories/tax/model/Tax";

export class ProductTaxDisplay extends ProductTax {
  name = "";
  description = "";
  isDefault = false;

  constructor(tax: Tax | null = null) {
    super(tax);

    if (tax !== null) {
      this.name = tax.taxName;
      this.description = tax.description;
      this.isDefault = tax.isDefaultTaxForProduct;
    }
  }
}
