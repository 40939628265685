import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Attribute } from "@/models/attribute/Attribute";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";

export const useAddAttributeStore = defineStore("addAttributeStore", () => {
  const attribute = ref<Attribute>(new Attribute());
  const initialAttribute = ref<Attribute>(cloneDeep(attribute.value));
  const { languages } = useProductLanguageFunctions();

  const isAttributeModified = computed(() => {
    return !isEqual(attribute.value, initialAttribute.value);
  });

  const attributeNamesByLanguage = ref<Record<string, string>>({
    ["en-GB"]: "",
    ["nb-NO"]: "",
    ["sv-SE"]: "",
    ["fi-FI"]: "",
    ["da-DK"]: "",
  });

  const attributeValuesByLanguage = ref<Record<string, AttributeValueByLanguage[]>>({
    ["en-GB"]: [],
    ["nb-NO"]: [],
    ["sv-SE"]: [],
    ["fi-FI"]: [],
    ["da-DK"]: [],
  });

  const languageModified = ref<Record<string, boolean>>({
    ["en-GB"]: false,
    ["nb-NO"]: false,
    ["sv-SE"]: false,
    ["fi-FI"]: false,
    ["da-DK"]: false,
  });

  const setAttributeName = (language: string, value: string) => {
    attributeNamesByLanguage.value[language] = value;
    const index = attribute.value.names.findIndex((n) => n.languageIso === language);
    if (index !== -1) {
      attribute.value.names[index].text = value;
    } else {
      attribute.value.names.push(new LanguageText(language, value));
    }
    languageModified.value[language] = true;
    copyLanguageValues(language);
  };

  const setAttributeValue = (language: string, newValue: AttributeValueByLanguage) => {
    const values = attributeValuesByLanguage.value[language];
    const index = values.findIndex((v) => v.id === newValue.id);
    if (index !== -1) {
      values[index] = newValue;
    } else {
      values.push(newValue);
    }

    const attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
    if (attributeValue) {
      attributeValue.setValueForLanguage(language, newValue.value);
      attributeValue.setDescriptionForLanguage(language, newValue.description);
      attributeValue.additionalProperties = newValue.additionalProperties;
    } else {
      const newAttributeValue = new AttributeValue();
      newAttributeValue.id = newValue.id;
      newAttributeValue.setValueForLanguage(language, newValue.value);
      newAttributeValue.setDescriptionForLanguage(language, newValue.description);
      newAttributeValue.additionalProperties = newValue.additionalProperties;
      attribute.value.values.push(newAttributeValue);
    }
    languageModified.value[language] = true;
    setAdditionalPropertiesForAllLanguages(newValue);

    copyLanguageValues(language);
  };

  const setAdditionalPropertiesForAllLanguages = (newValue: AttributeValueByLanguage) => {
    languages.forEach((lang) => {
      const values = attributeValuesByLanguage.value[lang.code];
      const index = values.findIndex((v) => v.id === newValue.id);
      if (index !== -1) {
        values[index].additionalProperties = newValue.additionalProperties;
      } else {
        values.push(newValue);
      }

      const attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
      if (attributeValue) {
        attributeValue.additionalProperties = newValue.additionalProperties;
      } else {
        const newAttributeValue = new AttributeValue();
        newAttributeValue.id = newValue.id;
        newAttributeValue.additionalProperties = newValue.additionalProperties;
        attribute.value.values.push(newAttributeValue);
      }
    });
  };

  const addNewAttributeValue = () => {
    const newValue = new AttributeValue();
    attribute.value.values.push(newValue);
    languages.forEach((lang) => {
      attributeValuesByLanguage.value[lang.code].push({
        id: newValue.id,
        value: "",
        description: "",
        additionalProperties: {},
      });
    });
  };

  const copyLanguageValues = (sourceLanguage: string) => {
    const targetLanguage = sourceLanguage === "en-GB" ? "nb-NO" : "en-GB";

    if (!languageModified.value[targetLanguage]) {
      attributeNamesByLanguage.value[targetLanguage] = attributeNamesByLanguage.value[sourceLanguage];
      const nameIndex = attribute.value.names.findIndex((n) => n.languageIso === targetLanguage);
      if (nameIndex !== -1) {
        attribute.value.names[nameIndex].text = attributeNamesByLanguage.value[sourceLanguage];
      } else {
        attribute.value.names.push(new LanguageText(targetLanguage, attributeNamesByLanguage.value[sourceLanguage]));
      }

      attributeValuesByLanguage.value[sourceLanguage].forEach((sourceValue) => {
        const targetValue = attributeValuesByLanguage.value[targetLanguage].find((v) => v.id === sourceValue.id);
        if (targetValue) {
          targetValue.value = sourceValue.value;
          targetValue.description = sourceValue.description;
        } else {
          attributeValuesByLanguage.value[targetLanguage].push({ ...sourceValue });
        }

        const attributeValue = attribute.value.values.find((v) => v.id === sourceValue.id);
        if (attributeValue) {
          attributeValue.setValueForLanguage(targetLanguage, sourceValue.value);
          attributeValue.setDescriptionForLanguage(targetLanguage, sourceValue.description);
        }
      });
    }
  };

  const deleteValue = (valueId: string) => {
    attribute.value.values = attribute.value.values.filter((v) => v.id !== valueId);

    languages.forEach((lang) => {
      attributeValuesByLanguage.value[lang.code] = attributeValuesByLanguage.value[lang.code].filter(
        (v) => v.id !== valueId,
      );
    });
  };

  const resetStore = () => {
    attribute.value = new Attribute();
    initialAttribute.value = cloneDeep(attribute.value);
    attributeNamesByLanguage.value = {
      ["en-GB"]: "",
      ["nb-NO"]: "",
    };
    attributeValuesByLanguage.value = {
      ["en-GB"]: [],
      ["nb-NO"]: [],
    };
    languageModified.value = {
      ["en-GB"]: false,
      ["nb-NO"]: false,
    };
  };

  return {
    attribute,
    attributeNamesByLanguage,
    attributeValuesByLanguage,
    isAttributeModified,
    setAttributeName,
    setAttributeValue,
    addNewAttributeValue,
    deleteValue,
    copyLanguageValues,
    resetStore,
  };
});
