<template>
  <div>
    <h3 class="text-sm font-semibold mb-2 text-gray-700">
      {{ t("product.import.edit-product.groups-references") }}
    </h3>
    <div class="p-4 border rounded-md bg-gray-50">
      <div class="grid grid-cols-2 gap-x-4 gap-y-4">
        <div class="field">
          <label for="productGroup" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.productgroupnamelevel1") }}*
          </label>
          <Select
            id="productGroup"
            v-model="product.productGroupLevel1"
            :options="productGroupOptions"
            optionLabel="name"
            optionValue="name"
            :placeholder="
              t('placeholder.select', {
                property: t('product.import.system-fields.productgroupnamelevel1').toLowerCase(),
              })
            "
            class="w-full"
            :invalid="val.product.productGroupLevel1.$error"
            :filter="true"
            :loading="loadingProductGroups"
          />
          <small v-if="val.product.productGroupLevel1.$error" class="text-red-500">
            {{ val.product.productGroupLevel1.$errors[0]?.$message }}
          </small>
        </div>

        <div class="field">
          <label for="clients" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.clients") }}*
          </label>
          <MultiSelect
            id="clients"
            v-model="selectedClients"
            :options="clientOptions"
            optionLabel="name"
            :placeholder="
              t('placeholder.select', {
                property: t('product.import.system-fields.clients').toLowerCase(),
              })
            "
            class="w-full"
            :invalid="val.product.clientNames.$error"
            :filter="true"
            :loading="loadingClients"
            :max-selected-labels="3"
          />
          <small v-if="val.product.clientNames.$error" class="text-red-500">
            {{ val.product.clientNames.$errors[0]?.$message }}
          </small>
        </div>

        <div class="field">
          <label for="warehouses" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.warehouse") }}*
          </label>
          <MultiSelect
            id="warehouses"
            v-model="selectedWarehouses"
            :options="warehouseOptions"
            optionLabel="name"
            class="w-full"
            :invalid="val.product.warehouseNames.$error"
            :filter="true"
            :loading="loadingWarehouses"
            :max-selected-labels="3"
            :placeholder="
              t('placeholder.select', {
                property: t('product.import.system-fields.warehouse').toLowerCase(),
              })
            "
          />
          <small v-if="val.product.warehouseNames.$error" class="text-red-500">
            {{ val.product.warehouseNames.$errors[0]?.$message }}
          </small>
        </div>

        <div class="field">
          <label for="supplier" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.suppliernames") }}*
          </label>
          <Select
            id="supplier"
            v-model="primarySupplierName"
            :options="supplierOptions"
            optionLabel="name"
            optionValue="name"
            :placeholder="
              t('placeholder.select', {
                property: t('product.import.system-fields.suppliernames').toLowerCase(),
              })
            "
            class="w-full"
            :invalid="val.product.supplierPrices.$error"
            :filter="true"
            :loading="loadingSuppliers"
          />
          <small v-if="val.product.supplierPrices.$error" class="text-red-500">
            {{ val.product.supplierPrices.$errors[0]?.$message }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { MappedImportProduct } from "@/product/models/MappedImportProduct";
import type { Reference } from "@/product/models/ImportOptions";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

interface ProductGroupOption {
  name: string;
}

const product = defineModel<MappedImportProduct>("product", {
  required: true,
});

const props = defineProps<{
  productGroupOptions: ProductGroupOption[];
  clientOptions: Reference[];
  warehouseOptions: Reference[];
  supplierOptions: Reference[];
  loadingProductGroups: boolean;
  loadingClients: boolean;
  loadingWarehouses: boolean;
  loadingSuppliers: boolean;
  errors: [string, string][];
}>();

const emit = defineEmits<{
  (e: "update:product", value: MappedImportProduct): void;
  (e: "update:clientNames", value: string[]): void;
  (e: "update:warehouseNames", value: string[]): void;
  (e: "update:supplierName", value: string): void;
  (e: "fieldTouched", fieldName: string): void;
}>();

const { t } = useI18n();

const primarySupplierName = computed({
  get: (): string => {
    return product.value.supplierPrices?.[0]?.supplierName || "";
  },
  set: (value: string) => {
    emit("update:supplierName", value);
    emit("fieldTouched", "supplierPrices");
  },
});

const selectedClients = computed({
  get: (): Reference[] => {
    if (!product.value.clientNames || !props.clientOptions) return [];

    return product.value.clientNames
      .filter((name) => props.clientOptions.some((c) => c.name === name))
      .map((name) => {
        const found = props.clientOptions.find((client) => client.name === name);
        return found as Reference;
      });
  },
  set: (value: Reference[]) => {
    emit(
      "update:clientNames",
      value.map((v) => v.name),
    );
    emit("fieldTouched", "clients");
  },
});

const selectedWarehouses = computed({
  get: (): Reference[] => {
    if (!product.value.warehouseNames || !props.warehouseOptions) return [];

    return product.value.warehouseNames
      .filter((name) => props.warehouseOptions.some((w) => w.name === name))
      .map((name) => {
        const found = props.warehouseOptions.find((warehouse) => warehouse.name === name);
        return found as Reference;
      });
  },
  set: (value: Reference[]) => {
    emit(
      "update:warehouseNames",
      value.map((v) => v.name),
    );
    emit("fieldTouched", "warehouse");
  },
});

const getErrorForField = (fieldName: string): string => {
  let error = props.errors.find(([field]) => field?.toLowerCase() === fieldName.toLowerCase());

  if (!error) {
    error = props.errors.find(
      ([field]) =>
        field?.toLowerCase() === fieldName.toLowerCase() || fieldName.toLowerCase() === field.toLowerCase() || "",
    );
  }

  return error ? error[1] : "";
};

const rules = {
  product: {
    productGroupLevel1: {
      serverFailed: helpers.withMessage(getErrorForField("productGroupNameLevel1"), () => {
        return getErrorForField("productGroupNameLevel1") === "";
      }),
      required: helpers.withMessage(t("validations.required"), required),
    },
    clientNames: {
      serverFailed: helpers.withMessage(getErrorForField("clients"), () => {
        return getErrorForField("clients") === "";
      }),
      required: helpers.withMessage(t("validations.required"), required),
    },
    warehouseNames: {
      serverFailed: helpers.withMessage(getErrorForField("warehouse"), () => {
        return getErrorForField("warehouse") === "";
      }),
      required: helpers.withMessage(t("validations.required"), required),
    },
    supplierPrices: {
      serverFailed: helpers.withMessage(getErrorForField("supplierNames"), () => {
        return getErrorForField("supplierNames") === "";
      }),
      required: helpers.withMessage(t("validations.required"), required),
    },
  },
};

const val = useVuelidate(rules, { product: product.value });
</script>

<style scoped>
.field {
  margin-bottom: 1.5rem;
}
</style>
