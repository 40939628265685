import { AIPrompt } from "./AIPrompts";

export class UpdateAIPrompt {
  aiPrompt: AIPrompt = new AIPrompt();

  public static createUpdateAiPrompt(aiPrompt: AIPrompt): UpdateAIPrompt {
    const updateAiPrompt = new UpdateAIPrompt();
    updateAiPrompt.aiPrompt = aiPrompt;
    return updateAiPrompt;
  }
}
