import { httpClient, AuthHeaders } from "@cumulus/http";
import { ProductSearchRequest } from "./model/ProductSearchRequest";
import { ProductSearchResponse } from "./model/ProductSearchResponse";

import { ProductHierarchySearchRequest } from "./model/ProductHierarchySearchRequest";
import { ProductHierarchySearchResponse } from "./model/ProductHierarchySearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async productSearch(authHeaders: AuthHeaders, request: ProductSearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }

  public async productHierarchySearch(
    authHeaders: AuthHeaders,
    request: ProductHierarchySearchRequest,
  ): Promise<ProductHierarchySearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/product-hierarchies/search", request)
      .then(({ data }) => data);
  }
}
const searchApi = new SearchApi();
export { searchApi };
