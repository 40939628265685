import { onBeforeUnmount, onMounted, Ref } from "vue";

export function useUnsavedChangesHandling(
  unsavedChangesDialogVisible: Ref<boolean>,
  stayOnPage: () => void,
  discardChanges: () => void,
  onCancel: () => void,
) {
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (unsavedChangesDialogVisible.value) {
        stayOnPage();
      } else onCancel();
    } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
      discardChanges();
    }
  };

  onBeforeUnmount(() => document.removeEventListener("keydown", handleKeydown));

  onMounted(() => document.addEventListener("keydown", handleKeydown));
}
