import { defineStore } from "pinia";
import { ref } from "vue";
import { Product } from "../repositories/product/model/Product";
import { NewProduct } from "@/repositories/product/model/NewProduct";

export const useAccessoriesStore = defineStore("productAccessories", () => {
  const _product = ref<Product | NewProduct>(new Product());

  const addAccessories = (accessoryIds: string[]): void => {
    _product.value.accessoryProductIds = [..._product.value.accessoryProductIds, ...accessoryIds];
  };

  const removeAccessoryById = (id: string): void => {
    _product.value.accessoryProductIds = _product.value.accessoryProductIds.filter((x) => x !== id);
  };

  const useProduct = (product: Product | NewProduct): void => {
    _product.value = product;
  };

  return {
    product: _product,
    addAccessories,
    removeAccessoryById,
    useProduct,
  };
});
