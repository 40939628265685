<template>
  <div class="flex mt-4">
    <FloatLabel variant="on" :pt:root:class="`!w-full`">
      <Select
        id="ai-prompt"
        v-model="selectedAiPrompt"
        variant="filled"
        :options="prompts"
        optionLabel="promptTitle"
        class="inputfield w-full"
        :disabled="editable"
      >
        <template #option="{ option }">
          <i v-if="option.promptTitle === 'Standard'" class="pi pi-lock mr-2"></i>
          <span>{{ option.promptTitle }}</span>
        </template>
        <template #value="{ value }">
          <i v-if="value?.promptTitle === 'Standard'" class="pi pi-lock mr-2"></i>
          <span>{{ value?.promptTitle }}</span>
        </template>
      </Select>
      <label for="ai-prompt">{{ t("product.ai-search.style") }}</label>
    </FloatLabel>
    <PrimeButton
      severity="secondary"
      data-testid="btn-ai-prompt-chooser"
      @click="selectedAiPrompt && editSelectedPrompt(selectedAiPrompt)"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      outlined
      :pt:root:class="`bg-white ml-3 px-4`"
    >
      <i class="pi pi-file-edit"></i>
    </PrimeButton>
  </div>
</template>

<script setup lang="ts">
import { AIPrompt } from "@/models/product-info/AIPrompts";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const prompts = defineModel<AIPrompt[]>("prompts");
const editable = defineModel<boolean>("editable");
const lockPrompt = defineModel<boolean>("lockPrompt");

const emit = defineEmits<{
  (e: "editPrompt", value: AIPrompt): void;
}>();

const selectedAiPrompt = ref<AIPrompt | null>(null);

const editSelectedPrompt = (prompt: AIPrompt) => {
  emit("editPrompt", prompt);
  editable.value = !editable.value;
};

watch(
  () => selectedAiPrompt.value,
  (newVal) => {
    if (newVal && newVal.promptTitle === "Standard") {
      lockPrompt.value = true;
    } else {
      lockPrompt.value = false;
    }
  },
  { immediate: true },
);

watch(
  () => prompts.value,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      selectedAiPrompt.value = newVal[0];
    }
  },
  { immediate: true },
);
</script>
