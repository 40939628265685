<template>
  <div class="border-b pb-6 mt-6">
    <div class="flex flex-col sm:flex-row items-start sm:items-center gap-6 sm:gap-12">
      <div class="flex items-center gap-3">
        <div class="p-2 rounded-lg" :class="totalVariants > 50 ? 'bg-amber-100' : 'bg-green-100'">
          <i class="pi pi-box text-xl" :class="totalVariants > 50 ? 'text-amber-600' : 'text-green-600'"></i>
        </div>
        <div>
          <p class="text-l text-gray-600">{{ t("product.variants.total") }}</p>
          <p class="text-2xl font-semibold">{{ totalVariants }}</p>
        </div>
      </div>

      <div class="flex items-center gap-3">
        <div class="p-2 rounded-lg bg-blue-100">
          <i class="pi pi-tags text-xl text-blue-600"></i>
        </div>
        <div>
          <p class="text-l text-gray-600">{{ t("product.variants.attributes") }}</p>
          <p class="text-2xl font-semibold">{{ productAttributes.length }}</p>
        </div>
      </div>

      <div class="flex items-center gap-3">
        <div class="p-2 rounded-lg bg-indigo-100">
          <i class="pi pi-shopping-bag text-xl text-indigo-600"></i>
        </div>
        <div>
          <p class="text-l text-gray-600">{{ t("product.variants.base-product") }}</p>
          <p class="text-2xl font-semibold truncate max-w-[200px]">{{ baseProductName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";

defineProps<{
  productAttributes: ProductAttribute[];
  totalVariants: number;
  baseProductName: string;
}>();

const { t } = useI18n();
</script>
