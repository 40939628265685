import { LanguageText } from "../language/LanguageText";
import { v4 as uuidv4 } from "uuid";

export class AttributeValue {
  id = "";
  values: LanguageText[] = [];
  descriptions: LanguageText[] = [];
  additionalProperties: { [key: string]: string } = {};

  constructor() {
    this.id = uuidv4();
    this.values = [new LanguageText("en-GB", ""), new LanguageText("nb-NO", "")];
    this.descriptions = [new LanguageText("en-GB", ""), new LanguageText("nb-NO", "")];
  }

  getValueForLanguage(language: string) {
    const languageText = this.values.find((v) => v.languageIso === language);
    return languageText ? languageText.text : "";
  }

  getDescriptionForLanguage(language: string) {
    const languageText = this.descriptions.find((d) => d.languageIso === language);
    return languageText ? languageText.text : "";
  }

  setValueForLanguage(language: string, value: string) {
    const languageText = this.values.find((v) => v.languageIso === language);
    if (languageText) {
      languageText.text = value;
    } else {
      this.values.push(new LanguageText(language, value));
    }
  }

  setDescriptionForLanguage(language: string, description: string) {
    const languageText = this.descriptions.find((d) => d.languageIso === language);
    if (languageText) {
      languageText.text = description;
    } else {
      this.descriptions.push(new LanguageText(language, description));
    }
  }
}
