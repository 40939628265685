import { AttributeState } from "./AttributeState";
import { AttributeType } from "./AttributeType";
import { AttributeValueByLanguageIso } from "./AttributeValueByLanguageIso";

export class AttributeByLanguageIso {
  id = "";
  name = "";
  attributeType: AttributeType = AttributeType.Standard;
  attributeState: AttributeState = AttributeState.Active;
  values: AttributeValueByLanguageIso[] = [];
}
