<template>
  <FloatLabel variant="on">
    <InputText
      id="product-gti-number"
      v-model="gtiNumberComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product.gti-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="product-gti-number"
      class="w-full"
      type="text"
      maxlength="8000"
    />
    <label for="product-gti-number">{{ t("product.gti-number") }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  gtin: string | null;
}>();

const emit = defineEmits<{
  (e: "update:gtin", value: string): void;
}>();

const gtiNumberComputed = computed<string>({
  get: () => {
    return props.gtin ?? "";
  },
  set: (value) => {
    emit("update:gtin", value);
  },
});
</script>
