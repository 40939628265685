export class ProductWithLanguage {
  productId: string = "";
  languages: Language[] = [];
}

export class Language {
  languageIso: string = "nb-NO";
  name: string = "";
  description: string = "";
  productInfo: string = "";
}
