import { AccountGroup } from "@/product/models/AccountGroup";
import { AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";

class AccountGroupApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/account-groups"
        : `${import.meta.env.VITE_APP_API_URL as string}/account-groups`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<AccountGroup[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, accountGroup: AccountGroup): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("", accountGroup);
  }

  public async update(authHeaders: AuthHeaders, accountGroup: AccountGroup): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("", accountGroup);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

export const accountGroupApi = new AccountGroupApi();
