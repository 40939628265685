<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />

  <ProductHeader
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :collapsedPanels="panelCollapsed"
    :showDialog="showDialog"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToProductList="routeToProductList"
    @onSave="saveProduct"
    @onDelete="onDeleteProduct"
    @onToggleAllPanels="onToggleAllPanels()"
    @update:showDialog="showDialog = $event"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        iconPos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <div data-testid="edit-product" class="c-page-content mt-1 ml-5 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-10">
        <template #content>
          <div id="general-info" class="grid grid-cols-12">
            <div class="col-span-12 lg:col-span-8 lg:border-r pr-8">
              <div class="grid grid-cols-12">
                <div class="col-span-12 lg:col-span-4">
                  <ProductNumber v-model:productNumber="product.productNumber" :focusInput="true" :is-editing="true" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Name v-model:name="product.name" />
                </div>
                <div class="col-span-12 lg:col-span-4">
                  <Gtin v-model:gtin="product.gtin" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Description v-model:description="product.description" />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <ProductHierarchySearchInput v-model:productHierarchyId="product.productHierarchyId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <SelectAccountGroup v-model:accountGroupId="product.accountGroupId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <Manufacturer v-model:manufacturerId="product.manufacturerId" />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2 lg:border-r lg:pt-6">
              <div class="grid grid-cols-12">
                <div class="col-span-12">
                  <ProductType v-model:productType="product.productType" />
                </div>
                <div class="col-span-12">
                  <ProductStatus v-model:productStatus="product.productStatus" :focusDropdown="setFocusProductStatus" />
                </div>
                <div class="col-span-12">
                  <SalesUnit v-model:salesUnit="product.salesUnitId" />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2">
              <DimensionAndWeight v-model:dimension="product.dimension" v-model:weight="product.weight" />
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-2">
        <template #content>
          <div class="navigation-shortcut lg:col-span-2">
            <ul class="flex-col m-0">
              <!-- TODO: Icons need to be changed -->
              <li class="p-2">
                <a href="#" tabindex="-1" class="flex items-center" @click="scrollToTarget('productInfoCollapsed')">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("product.product-info") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" tabindex="-1" class="flex items-center" @click="scrollToTarget('warehouseClientCollapsed')">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("product.warehouse-and-client") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" tabindex="-1" class="flex items-center" @click="scrollToTarget('suppliersCollapsed')">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span> {{ t("product.supplier.label") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" tabindex="-1" class="flex items-center" @click="scrollToTarget('pricesAndFeesCollapsed')">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span> {{ t("product.prices-and-fees") }} </span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" tabindex="-1" class="flex items-center" @click="scrollToTarget('attributesCollapsed')">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("product.attributes-and-connections") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      id="productInfoCollapsed"
      :header="t('product.product-info')"
      toggleable
      :collapsed="panelCollapsedInfo"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedInfo = !panelCollapsedInfo)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8">
          <ProductInfo v-model:productInfo="product.productInfo" />
        </div>
        <div class="col-span-12 lg:col-span-4">
          <MediaTabs
            v-model:productImages="product.productImages"
            v-model:productVideos="product.media"
            v-model:productDocuments="product.documents"
          />
        </div>
      </div>
    </Panel>

    <Panel
      id="warehouseClientCollapsed"
      :header="t('product.warehouse-and-client')"
      toggleable
      :collapsed="panelCollapsedWarehouseClient"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedWarehouseClient = !panelCollapsedWarehouseClient)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8 lg:border-r">
          <Warehouse
            :warehouseIds="product.warehouseIds"
            :productId="product.id"
            @addWarehouse="addWarehouse"
            @deleteWarehouse="deleteWarehouse"
          />
        </div>

        <div class="col-span-12 lg:col-span-4">
          <Client :clients="product.clientIds" @addClient="addClient" @deleteClient="deleteClient" />

          <WebshopActive v-model:activeOnWebshop="product.activeOnWebshop" />
        </div>
      </div>
    </Panel>

    <Panel
      id="pricesAndFeesCollapsed"
      :header="t('product.supplier.label')"
      toggleable
      :collapsed="panelCollapsedSupplier"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedSupplier = !panelCollapsedSupplier)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <SupplierPrices
            :supplierPrices="product.supplierPrices"
            :suppliers="suppliers"
            :clientCurrencyIso="userClient.currencyIso"
            :currencies="currencies"
            @mainSupplierCostPriceUpdated="($event) => (mainSupplierCostPrice = $event)"
            @update:supplierPrices="updateSupplierPrices"
            @addSupplierPrice="addSupplierPrice"
            @deleteSupplierPrice="deleteSupplierPrice"
          />
        </div>
      </div>
    </Panel>

    <Panel
      id="pricesAndFeesCollapsed"
      :header="t('product.prices-and-fees')"
      toggleable
      :collapsed="panelCollapsedPricesAndFees"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedPricesAndFees = !panelCollapsedPricesAndFees)"
    >
      <div class="grid grid-cols-1 gap-4">
        <div>
          <Price
            :mainSupplierCostPrice="mainSupplierCostPrice"
            :clientCurrencyIso="userClient.currencyIso"
            :prices="product.prices"
            :vatPercentage="defaultOutgoingVatPercentage(product)"
            @updatePrice="updatePrice($event.price)"
          />
        </div>

        <div>
          <Tax :productTaxes="product.taxes" @update:productTaxes="updateTaxes" />
        </div>
        <div>
          <ExciseDuties />
        </div>
      </div>
    </Panel>

    <Panel
      id="attributesCollapsed"
      :header="t('product.attributes-and-connections')"
      toggleable
      :collapsed="panelCollapsedAttribues"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedAttribues = !panelCollapsedAttribues)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-4">
          <ProductAttributes v-model:productAttributes="product.attributes" />
        </div>
      </div>
    </Panel>

    <Panel
      id="variantsCollapsed"
      :header="t('product.variants.label')"
      toggleable
      :collapsed="panelCollapsedVariants"
      class="mb-4"
      :pt:header:onClick="() => (panelCollapsedVariants = !panelCollapsedVariants)"
    >
      <ViewVariants :product="product" />
    </Panel>
  </div>

  <AIProductInfoDialogDialog
    v-if="showDialog"
    v-model:visible="showDialog"
    v-model:product="product"
    data-testid="description-dialog"
    @addToProductFromAIAssistant="addToProductFromAIAssistant"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { Product } from "@/repositories/product/model/Product";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { type Supplier } from "@/repositories/supplier/model/Supplier";
import { ProductStatus as ProductState } from "@/repositories/product/model/ProductStatus";
import { ProductTax } from "@/repositories/product/model/ProductTax";

import { usePriceFunctions } from "@/product/PriceFunctions";
import { BeforeUnloadBrowserTab } from "@cumulus/components";

import ProductNumber from "@/product/components/ProductNumber.vue";
import Gtin from "@/product/components/Gtin.vue";
import Name from "@/product/components/Name.vue";
import Description from "@/product/components/Description.vue";
import ProductStatus from "@/product/components/ProductStatus.vue";
import SalesUnit from "@/product/components/sales-unit/SalesUnit.vue";
import ProductType from "@/product/components/ProductType.vue";
import ProductInfo from "@/product/components/ProductInfo.vue";
import SupplierPrices from "@/product/components/SupplierPrices.vue";
import Client from "@/product/components/Client.vue";
import Manufacturer from "@/product/components/manufacturer/Manufacturer.vue";
import Tax from "@/product/components/Tax.vue";
import WebshopActive from "@/product/components/WebshopActive.vue";
import Price from "@/product/components/Price.vue";
import DimensionAndWeight from "@/product/components/DimensionAndWeight.vue";
import ExciseDuties from "@/product/components/ExciseDuties.vue";
import Warehouse from "@/product/components/Warehouse.vue";
import ProductHeader from "@/product/components/ProductHeader.vue";
import ProductHierarchySearchInput from "@/product/components/ProductHierarchySearchInput.vue";
import SelectAccountGroup from "@/product/components/account-group/SelectAccountGroup.vue";
import ProductAttributes from "@/product/components/attribute/ProductAttributes.vue";
import ViewVariants from "@/product/components/variants/ProductVariantsView.vue";

import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useProductStore } from "@/repositories/product/ProductStore";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { Currency } from "@/repositories/currency/model/Currency";
import { useCurrencyService } from "@/repositories/currency/CurrencyService";
import { Client as ClientModel } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { useAuth } from "@cumulus/event-bus";
import { useAccountGroupStore } from "@/stores/AccountGroupStore";
import MediaTabs from "@/product/components/MediaTabs.vue";
import AIProductInfoDialogDialog from "@/product/components/ai-assistant/AIProductInfoDialog.vue";
import { Dimension } from "@/repositories/product/model/Dimension";
import { Weight } from "@/repositories/product/model/Weight";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const productId = ref("");
const suppliers = ref<Supplier[]>([]);
const product = ref<Product>(new Product());
const currencies = ref<Currency[]>([]);
const userClient = ref<ClientModel>(new ClientModel());
const mainSupplierCostPrice = ref<number>(0);

const imagesToDelete: ProductImage[] = [];
const isSaving = ref(false);
const loadFailed = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialProduct = ref<Product | null>(null);
const errorReason = ref("");
const { defaultOutgoingVatPercentage } = usePriceFunctions();
const { getAllSuppliers } = useSupplier();
const { getProduct, updateProduct, deleteProduct } = useProductStore();
const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
const { getAllAccountGroups } = useAccountGroupStore();
const confirmedDiscard = ref(false);
const { getAllCurrencies } = useCurrencyService();
const { getClient } = useClient();
const { getAuthHeaders } = useAuth();

const routeToProductList = async () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", product.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

//Nested validation
const validate = useValidate();

const fetchProduct = async () => {
  productId.value = route.params.id as string;
  product.value = await getProduct(productId.value);
};

const getPrice = async (): Promise<PriceModel> => {
  const defaultPrice = await getDefaultPriceGroupByUsersClient();

  const price = new PriceModel();
  price.priceGroupId = defaultPrice.id;
  price.priceGroupName = defaultPrice.name;
  price.currencyIso = defaultPrice.currencyIso;

  return price;
};

const getSuppliers = async () => {
  suppliers.value = await getAllSuppliers();
};

const getCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const getUserClient = async () => {
  const authHeaders = await getAuthHeaders();
  const clientId = authHeaders.clientId;
  userClient.value = await getClient(clientId);
};

const getAccountGroups = async () => {
  await getAllAccountGroups();
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([getPrice(), fetchProduct(), getSuppliers(), getUserClient(), getCurrencies(), getAccountGroups()]).then(
    (values) => {
      if (product.value.prices.length === 0) {
        product.value.prices.push(values[0]);
      }
      initialProduct.value = cloneDeep(product.value);
    },
  );
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToProductList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const saveProduct = async () => {
  //Wait for onblur functions
  await timeout(300);

  validate.value.$touch();

  if (!(await validate.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  isSaving.value = true;

  await updateProduct(product.value, imagesToDelete);

  toast.add({
    severity: "success",
    summary: t("product.toast.success-edit.summary"),
    detail: t("product.toast.success-edit.detail", { name: product.value.name }),
    closable: true,
  });

  routeToProductList();
};

const addSupplierPrice = async (supplierPrice: SupplierPrice) => {
  if (product.value.supplierPrices.length === 0) {
    supplierPrice.isDefaultSupplier = true;
  }
  product.value.supplierPrices.push(supplierPrice);

  const index = product.value.supplierPrices.length - 1;
  await nextTick();
  const productNumberInput = document.getElementById(`supplier-product-number-${index}`) as HTMLInputElement;
  if (productNumberInput) {
    productNumberInput.focus();
  }
};

const deleteSupplierPrice = (supplierPrice: SupplierPrice) => {
  const index = product.value.supplierPrices.findIndex((s) => s.id === supplierPrice.id);
  if (index !== -1) {
    product.value.supplierPrices.splice(index, 1);
  }

  if (supplierPrice.isDefaultSupplier === true && product.value.supplierPrices.length > 0)
    product.value.supplierPrices[0].isDefaultSupplier = true;
};

const addClient = async (clientId: string) => {
  product.value.clientIds.push(clientId);
  await nextTick();
  document.getElementById("add-client-button")?.focus();
};

const deleteClient = (clientId: string) => {
  const index = product.value.clientIds.findIndex((c) => c === clientId);
  if (index !== -1) product.value.clientIds.splice(index, 1);
};
const addWarehouse = async (warehouseId: string[]) => {
  product.value.warehouseIds = [...product.value.warehouseIds, ...warehouseId];
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const deleteWarehouse = async (warehouseId: string) => {
  const index = product.value.warehouseIds.findIndex((c) => c === warehouseId);
  if (index !== -1) product.value.warehouseIds.splice(index, 1);
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const updatePrice = (price: PriceModel) => {
  const priceToUpdate = product.value.prices.find((s) => s.priceGroupId === price.priceGroupId);
  if (priceToUpdate === undefined) return;
  priceToUpdate.listPrice = price.listPrice;
};

const updateTaxes = (taxes: ProductTax[]) => {
  product.value.taxes = taxes;
};

const updateSupplierPrices = (supplierPrices: SupplierPrice[]) => {
  product.value.supplierPrices = supplierPrices;
};

const onDeleteProduct = async () => {
  if (product.value.productStatus === ProductState.Active) {
    toast.add({
      severity: "warn",
      summary: t("product.active-error.header"),
      detail: t("product.active-error.detail"),
      life: 5000,
    });
    return;
  }

  await deleteProduct(product.value.id);

  toast.add({
    severity: "success",
    summary: t("product.toast.success-delete.summary"),
    detail: t("product.toast.success-delete.detail", { name: product.value.name }),
    closable: true,
  });

  routeToProductList();
};

const setFocusProductStatus = ref(false);

const hasUpdates = computed(() => {
  return !isEqual(product.value, initialProduct.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToProductList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

useShortcut(ShortcutAction.save, saveProduct);

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const scrollToTarget = (targetId: string) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

const panelCollapsedInfo = ref(false);
const panelCollapsedWarehouseClient = ref(false);
const panelCollapsedSupplier = ref(false);
const panelCollapsedPricesAndFees = ref(false);
const panelCollapsedAttribues = ref(false);
const panelCollapsedVariants = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedInfo.value &&
    panelCollapsedWarehouseClient.value &&
    panelCollapsedSupplier.value &&
    panelCollapsedPricesAndFees.value &&
    panelCollapsedAttribues.value &&
    panelCollapsedVariants.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedInfo.value = newState;
  panelCollapsedWarehouseClient.value = newState;
  panelCollapsedSupplier.value = newState;
  panelCollapsedPricesAndFees.value = newState;
  panelCollapsedAttribues.value = newState;
  panelCollapsedVariants.value = newState;
};

const showDialog = ref<boolean>(false);
const addToProductFromAIAssistant = (
  valueProductInfo: string,
  valueProductDescription: string,
  valueProductName: string,
  valueWeight: Weight,
  valueDimenstion: Dimension,
) => {
  product.value.productInfo = valueProductInfo;
  product.value.description = valueProductDescription;
  product.value.name = valueProductName;
  product.value.weight = valueWeight;
  product.value.dimension = valueDimenstion;

  showDialog.value = false;
};

const reload = () => {
  window.location.reload();
};
</script>

<style scoped lang="scss">
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}

.navigation-shortcut {
  height: auto;
  a:focus {
    outline: none;
    border: 2px solid #59b5e8;
  }
  ul {
    list-style-type: none;
    padding: 0.2rem;
    li {
      outline: none;
    }
  }
}
@media (max-width: 992px) {
  .navigation-shortcut {
    display: none;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev),
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next) {
  color: #fff;
  margin: 0.1rem;
}
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus) {
  box-shadow: none;
}

.c-header {
  color: var(--text-color);
  font-weight: bold;
  font-size: large;
}
</style>
