<template>
  <div class="place-items-center mx-6 mt-4">
    <InputGroup>
      <InputGroupAddon><Checkbox v-model="transferName" :binary="true"></Checkbox></InputGroupAddon>
      <FloatLabel variant="on">
        <InputText
          v-model="name"
          id="ai-prod-name-result"
          data-testid="ai-prod-name-result"
          type="text"
          class="inputfield w-full"
          :maxlength="100"
        />
        <label for="ai-prod-name-result">{{ t("product.name") }}</label>
      </FloatLabel>
    </InputGroup>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const name = defineModel<string>("name");
const transferName = defineModel<boolean>("transferName");
</script>
