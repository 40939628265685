export function useProductLanguageFunctions() {
  const languages = [
    {
      code: "en-GB",
      name: "English",
    },
    {
      code: "nb-NO",
      name: "Norwegian",
    },
    {
      code: "sv-SE",
      name: "Swedish",
    },
    {
      code: "fi-FI",
      name: "Finnish",
    },
    {
      code: "da-DK",
      name: "Danish",
    },
  ];

  const transformLanguageCode = (languageCode: string): string => {
    switch (languageCode) {
      case "EN":
        return "en-GB";
      case "NO":
        return "nb-NO";
      case "SE":
        return "sv-SE";
      case "FI":
        return "fi-FI";
      case "DK":
        return "da-DK";
      default:
        return languageCode;
    }
  };

  return {
    languages,
    transformLanguageCode,
  };
}
