<template>
  <div class="w-full">
    <Card class="border-2 border-dashed transition-all duration-200">
      <template #content>
        <div class="space-y-4">
          <div class="flex items-center gap-2">
            <i class="pi pi-tag text-gray-400"></i>
            <h3 class="text-lg font-medium">
              {{ t("placeholder.select", { property: t("product.attribute.attribute").toLowerCase() }) }}
            </h3>
          </div>

          <div class="space-y-4">
            <div class="grid grid-cols-2 gap-4">
              <Select
                v-model="selectedAttributeId"
                :options="availableAttributes"
                optionLabel="name"
                optionValue="id"
                :placeholder="t('placeholder.select', { property: t('product.attribute.attribute').toLowerCase() })"
                class="w-full"
                data-testid="select-variant-attribute-name"
              />

              <div class="space-y-2">
                <MultiSelect
                  v-model="selectedValues"
                  :options="getAttributeValues(selectedAttributeId)"
                  optionLabel="value"
                  optionValue="id"
                  :placeholder="
                    t('placeholder.select', { property: t('product.attribute.attribute-values').toLowerCase() })
                  "
                  class="w-full"
                  display="chip"
                  data-testid="select-variant-attribute-values"
                >
                  <template #option="{ option }">
                    <div class="flex items-center justify-between">
                      <ColorPicker
                        v-if="option.additionalProperties.hexCode"
                        :modelValue="option.additionalProperties.hexCode"
                        :disabled="true"
                        class="mr-2"
                        pt:preview:class="!opacity-100"
                      />
                      <div>
                        {{ option.value }} -
                        {{ option.description }}
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>

            <div class="flex justify-end gap-2 pt-2">
              <PrimeButton
                :label="t('common.cancel')"
                severity="secondary"
                text
                @click="cancelAddAttribute"
                class="hover:bg-gray-100"
              />
              <PrimeButton
                :label="t('common.add')"
                :disabled="!canAddAttribute"
                @click="confirmAddAttribute"
                severity="secondary"
                class="hover:bg-gray-200"
                data-testid="add-variant-attribute-button"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { useAttributeFunctions } from "@/product/composables/attributeFunctions";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const productAttributes = defineModel<ProductAttribute[]>("productAttributes", {
  required: true,
});

const props = defineProps<{
  attributes: AttributeByLanguageIso[];
}>();

const emit = defineEmits<{
  (e: "cancelClicked"): void;
}>();

const { t } = useI18n();
const attributes = ref(props.attributes);
const { getAttributeValues } = useAttributeFunctions(attributes);

const selectedAttributeId = ref<string>("");
const selectedValues = ref<string[]>([]);

const availableAttributes = computed(() => {
  return props.attributes.filter((attr) => !productAttributes.value.some((pa) => pa.attributeId === attr.id));
});

const canAddAttribute = computed(() => {
  return selectedAttributeId.value && selectedValues.value.length > 0;
});

const resetAttributeForm = () => {
  selectedAttributeId.value = "";
  selectedValues.value = [];
};

const cancelAddAttribute = () => {
  resetAttributeForm();
  emit("cancelClicked");
};

const confirmAddAttribute = () => {
  if (canAddAttribute.value) {
    const newAttribute: ProductAttribute = {
      attributeId: selectedAttributeId.value,
      attributeValueIds: selectedValues.value,
    };
    productAttributes.value.push(newAttribute);
    resetAttributeForm();
  }
};
</script>
