import { LanguageIsoType } from "../language/LanguageIsoType";
import { LanguageText } from "../language/LanguageText";
import { v4 as uuidv4 } from "uuid";

export class AttributeValue {
  id = "";
  values: LanguageText[] = [];
  descriptions: LanguageText[] = [];
  additionalProperties: { [key: string]: string } = {};

  constructor() {
    this.id = uuidv4();
    this.values = [new LanguageText(LanguageIsoType.English, ""), new LanguageText(LanguageIsoType.Norwegian, "")];
    this.descriptions = [
      new LanguageText(LanguageIsoType.English, ""),
      new LanguageText(LanguageIsoType.Norwegian, ""),
    ];
  }

  getValueForLanguage(language: LanguageIsoType) {
    const languageText = this.values.find((v) => v.languageIso === language);
    return languageText ? languageText.text : "";
  }

  getDescriptionForLanguage(language: LanguageIsoType) {
    const languageText = this.descriptions.find((d) => d.languageIso === language);
    return languageText ? languageText.text : "";
  }

  setValueForLanguage(language: LanguageIsoType, value: string) {
    const languageText = this.values.find((v) => v.languageIso === language);
    if (languageText) {
      languageText.text = value;
    } else {
      this.values.push(new LanguageText(language, value));
    }
  }

  setDescriptionForLanguage(language: LanguageIsoType, description: string) {
    const languageText = this.descriptions.find((d) => d.languageIso === language);
    if (languageText) {
      languageText.text = description;
    } else {
      this.descriptions.push(new LanguageText(language, description));
    }
  }
}
