<template>
  <label for="manufacturer-description" :class="{ 'c-focused': isInputFocused }">
    {{ t("product.manufacturer.description") }}
  </label>

  <InputText
    v-model="manufacturerDescriptionComputed"
    type="text"
    class="inputfield w-full"
    maxlength="500"
    :placeholder="t('placeholder.type', { property: t('product.manufacturer.description').toLowerCase() })"
    data-testid="manufacturer-description"
    @focus="selectAllOnFocus"
    @blur="isInputFocused = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  manufacturerDescription: string;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerDescription", value: string): void;
}>();

const { t } = useI18n();
const isInputFocused = ref(false);

const manufacturerDescriptionComputed = computed<string>({
  get() {
    return props.manufacturerDescription;
  },
  set(value: string) {
    emit("update:manufacturerDescription", value);
  },
});

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
  isInputFocused.value = true;
};
</script>
