import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { productImportApi } from "./ProductImportApi";
import type { ProductImportSession } from "@/product/models/ProductImportSession";

export function useProductImportService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getImportSession = async (id: string): Promise<ProductImportSession> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getImportSession(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const startImport = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await productImportApi.startImport(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWebPubSubUrl = async (): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getWebPubSubUrl(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getImportSession,
    startImport,
    getWebPubSubUrl,
  };
}
