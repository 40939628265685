<template>
  <MultiSelect
    :modelValue="selectedValueIds"
    @update:modelValue="emit('update:selectedValueIds', $event)"
    :options="availableValues"
    id="select-attribute-values"
    data-testid="select-attribute-values"
    optionLabel="value"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('product.attribute.attribute-values').toLowerCase() })"
    class="w-full"
  >
    <template #option="{ option }">
      <div class="flex items-center">
        <div class="mr-2" v-if="option.additionalProperties?.hexCode">
          <ColorPicker
            :modelValue="option.additionalProperties.hexCode"
            :disabled="true"
            pt:preview:class="!opacity-100"
          />
        </div>
        <div>{{ option.value }}</div>
      </div>
    </template>
  </MultiSelect>
  <small class="p-error" v-if="val.selectedValueIds.$error" data-testid="product-attribute-values-error">
    {{ val.selectedValueIds.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { AttributeValueByLanguageIso } from "@/models/attribute/AttributeValueByLanguageIso";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  selectedValueIds: string[];
  availableValues: AttributeValueByLanguageIso[];
}>();

const emit = defineEmits<{
  (e: "update:selectedValueIds", value: string[]): void;
}>();

const rules = {
  selectedValueIds: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
