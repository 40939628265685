import { AIJsonModel } from "@/repositories/product/model/AIJsonModel";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";

import { aiProductApi } from "./AiProductApi";
import { ref } from "vue";
import { AIPrompt } from "@/models/product-info/AIPrompts";
import { UpdateAIPrompt } from "@/models/product-info/UpdateAIPrompts";

export function useAiProduct() {
  const productPrompts = ref<AIPrompt[]>([]);
  const saving = ref(false);

  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const generateProductDescription = async (
    productName: string,
    prompt: string,
    openAIVersion: string,
    method: string,
    language: string,
  ): Promise<AIJsonModel> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await aiProductApi.getProductDescription(
        authHeaders,
        openAIVersion,
        method,
        productName,
        prompt,
        language,
      );
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllPromps = async (): Promise<AIPrompt[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await aiProductApi.getAllPrompts(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createPrompt = async (prompt: AIPrompt): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await aiProductApi.createPrompt(authHeaders, prompt);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const deletePrompt = async (promptId: string): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await aiProductApi.deletePrompt(authHeaders, promptId);
      productPrompts.value = productPrompts.value.filter((cg) => cg.id !== promptId);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const updatePrompt = async (prompt: UpdateAIPrompt): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await aiProductApi.updatePrompt(authHeaders, prompt);
      const index = productPrompts.value.findIndex((cg) => cg.id === prompt.aiPrompt.id);
      productPrompts.value[index] = prompt.aiPrompt;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  return { productPrompts, generateProductDescription, getAllPromps, createPrompt, deletePrompt, updatePrompt };
}
