<template>
  <Card>
    <template #header>
      <div class="flex justify-between items-center py-2 border-b mx-2">
        <div class="flex items-center">
          <Button
            data-testid="previous-product"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('previousProduct')"
            :disabled="props.productIndex === 0"
            v-tooltip.bottom="{
              value: t('product-info-dialog.previous'),
              showDelay: 1000,
              hideDelay: 300,
            }"
            v-if="hasSplitView"
          >
            <div class="inline-block material-symbols-outlined">arrow_back</div>
          </Button>
          <Button
            data-testid="next-product"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('nextProduct')"
            :disabled="props.productIndex === props.maxProductIndex"
            v-tooltip.bottom="{
              value: t('product-info-dialog.next'),
              showDelay: 1000,
              hideDelay: 300,
            }"
            v-if="hasSplitView"
          >
            <div class="inline-block material-symbols-outlined">arrow_forward</div>
          </Button>
        </div>
        <div class="pt-2 font-bold whitespace-nowrap">
          <FloatLabel variant="on" class="inline-block">
            <Select
              id="info-language"
              data-testid="language"
              pt:list:data-testid="language-list"
              class="w-full"
              :options="props.languages"
              optionLabel="name"
              optionValue="code"
              v-model="selectedLanguage"
              v-tooltip.bottom="{
                value: t('placeholder.select', { property: t('product-info-dialog.language-selector').toLowerCase() }),
                showDelay: 1000,
                hideDelay: 300,
              }"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <img
                    src="@/assets/flag_placeholder.png"
                    :class="'flag flag-' + flagConversion(slotProps.option.code.substring(3).toLowerCase())"
                    width="16"
                  />
                  <div class="ml-4">{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Select>
            <label for="info-language">
              {{ t(`product-info-dialog.language-selector`) }}
            </label>
          </FloatLabel>
        </div>
        <div class="flex items-center gap-2">
          <Button
            data-testid="slitt-view"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('toggleSplitView')"
          >
            <span v-if="hasSplitView" class="material-symbols-outlined">
              {{ showSplitView ? "left_panel_close" : "left_panel_open" }}
            </span>

            <span v-else class="material-symbols-outlined"> close_small </span>
          </Button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-cols-12 gap-y-4 mb-2 mt-4">
        <div class="col-span-12">
          <ProductName v-model:name="activeProductInfoLanguage.name" :languageIso="selectedLanguage" />
        </div>

        <div class="col-span-12">
          <ProductDescription
            v-model:description="activeProductInfoLanguage.description"
            :languageIso="selectedLanguage"
          />
        </div>

        <div class="col-span-12">
          <ProductInformation
            v-model:information="activeProductInfoLanguage.productInfo"
            :languageIso="selectedLanguage"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ProductName from "./ProductName.vue";
import ProductDescription from "./ProductDescription.vue";
import ProductInformation from "./ProductInformation.vue";
import { LanguageViewModel } from "@/product/models/LanguageViewModel";
import { ref, computed } from "vue";
import { ProductWithLanguage, Language } from "@/product/models/ProductWithLanguage";

const { t } = useI18n();

const props = defineProps<{
  languages: LanguageViewModel[];
  hasSplitView: boolean;
  showSplitView: boolean;
  productIndex: number;
  maxProductIndex: number;
}>();

const emit = defineEmits<{ (e: "toggleSplitView"): void; (e: "previousProduct"): void; (e: "nextProduct"): void }>();
const productLanguage = defineModel<ProductWithLanguage | undefined>("productLanguage", {
  required: true,
});
const selectedLanguage = ref<string>("nb-NO");

const activeProductInfoLanguage = computed(() => {
  return productLanguage.value?.languages?.find((x) => x.languageIso === selectedLanguage.value) ?? new Language();
});

const flagConversion = (language: string) => {
  switch (language) {
    case "en":
      return "gb";
  }
  return language;
};
</script>
