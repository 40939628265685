import { useAuth } from "@cumulus/event-bus";
import { Attribute } from "../../models/attribute/Attribute";
import { attributeApi } from "./AttributeApi";
import { useErrorHandler } from "../ErrorHandler";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";

export function useAttribute() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAttributeById = async (id: string): Promise<Attribute> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await attributeApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAttributes = async (languageIso?: string): Promise<AttributeByLanguageIso[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await attributeApi.getAttributes(authHeaders, languageIso);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createAttribute = async (attribute: Attribute): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await attributeApi.create(authHeaders, attribute);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateAttribute = async (attribute: Attribute): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await attributeApi.update(authHeaders, attribute);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteAttribute = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await attributeApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAttributeById, getAttributes, createAttribute, updateAttribute, deleteAttribute };
}
