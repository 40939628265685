<template>
  <div class="flex place-items-start mx-4 mt-4">
    <FloatLabel variant="on" :pt:root:class="`w-full mx-2`">
      <PrimeTextarea
        id="ai-prod-info"
        v-model="productInfo"
        class="inputfield w-full pt-3"
        dataTestId="ai-prod-info"
        :autoResize="true"
        rows="21"
        :disabled="lockPrompt"
      />
      <label for="delivery-address-lines">{{ t("product.ai-search.productInfo") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const productInfo = defineModel<string>("productInfo");
const lockPrompt = defineModel<boolean>("lockPrompt");
</script>
