import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { ManufacturerStatus } from "./ManufacturerStatus";
import { NIL as emptyUuid } from "uuid";

export class Manufacturer {
  id = emptyUuid;
  companyId = "";
  name = "";
  description = "";
  logo: ImageInfo = new ImageInfo();
  websiteUrl = "";
  manufacturerStatus = ManufacturerStatus.Active;
}
