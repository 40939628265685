<template>
  <div class="place-items-center mx-6 mt-4">
    <FloatLabel variant="on" :pt:root:class="`w-full`">
      <InputText
        v-model="name"
        id="ai-prompt-name"
        data-testid="ai-prompt-name"
        type="text"
        class="inputfield w-full"
        :maxlength="100"
        :disabled="lockPrompt"
      />
      <label for="ai-prompt-name">{{ t("product.ai-search.style-name") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const name = defineModel<string | undefined>("name");
const lockPrompt = defineModel<boolean>("lockPrompt");
</script>
