import { defineStore } from "pinia";
import { onBeforeUnmount, ref } from "vue";
import type { Reference } from "@/product/models/ImportOptions";
import type { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import type { Tax } from "@/repositories/tax/model/Tax";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import { useClient } from "@/repositories/client/ClientService";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useTax } from "@/repositories/tax/TaxService";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { useSalesUnitService } from "@/repositories/sales-unit/SalesUnitService";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useProductHierarchyService } from "../api/product-hierarchies/ProductHierarchyService";

interface ReferenceData {
  clients: Reference[];
  warehouses: Reference[];
  salesUnits: Reference[];
  productGroupNames: ProductGroupName[];
  suppliers: Reference[];
  defaultPriceGroup: Reference | null;
  taxes: Tax[];
}

export const useProductImportReferenceStore = defineStore("productImportReference", () => {
  const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
  const { getAllClients } = useClient();
  const { getProductGroupNames } = useProductHierarchyService();
  const { getAllSuppliers } = useSupplier();
  const { getTaxes } = useTax();
  const { getAllWarehouses } = useWarehouse();
  const { getSalesUnits } = useSalesUnitService();
  const { handleError } = useErrorHandler();

  const referenceData = ref<ReferenceData>({
    clients: [],
    warehouses: [],
    salesUnits: [],
    productGroupNames: [],
    suppliers: [],
    defaultPriceGroup: null,
    taxes: [],
  });

  const resetState = (): void => {
    referenceData.value = {
      clients: [],
      warehouses: [],
      salesUnits: [],
      productGroupNames: [],
      suppliers: [],
      defaultPriceGroup: null,
      taxes: [],
    };
  };

  const getClients = async (): Promise<Reference[]> => {
    try {
      if (referenceData.value.clients.length === 0) {
        const clients = await getAllClients();
        referenceData.value.clients = clients.map((client) => ({
          id: client.id,
          name: client.name,
        }));
      }
      return referenceData.value.clients;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWarehouses = async (): Promise<Reference[]> => {
    try {
      if (referenceData.value.warehouses.length === 0) {
        const warehouses = await getAllWarehouses();
        referenceData.value.warehouses = warehouses.map((warehouse) => ({
          id: warehouse.id,
          name: warehouse.name,
        }));
      }
      return referenceData.value.warehouses;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAvailableSalesUnits = async (): Promise<Reference[]> => {
    try {
      if (referenceData.value.salesUnits.length === 0) {
        const salesUnits = await getSalesUnits();
        referenceData.value.salesUnits = salesUnits.map((unit) => ({
          id: unit.id,
          name: unit.name,
        }));
      }
      return referenceData.value.salesUnits;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductGroups = async (): Promise<ProductGroupName[]> => {
    try {
      if (referenceData.value.productGroupNames.length === 0) {
        referenceData.value.productGroupNames = await getProductGroupNames();
      }
      return referenceData.value.productGroupNames;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getSuppliers = async (): Promise<Reference[]> => {
    try {
      if (referenceData.value.suppliers.length === 0) {
        const suppliers = await getAllSuppliers();
        referenceData.value.suppliers = suppliers.map((supplier) => ({
          id: supplier.id,
          name: supplier.name,
        }));
      }
      return referenceData.value.suppliers;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getDefaultPriceGroup = async (): Promise<Reference | null> => {
    try {
      if (referenceData.value.defaultPriceGroup === null) {
        const priceGroup = await getDefaultPriceGroupByUsersClient();
        if (priceGroup) {
          referenceData.value.defaultPriceGroup = priceGroup;
        }
      }
      return referenceData.value.defaultPriceGroup;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAvailableTaxes = async (): Promise<Tax[]> => {
    try {
      if (referenceData.value.taxes.length === 0) {
        referenceData.value.taxes = await getTaxes();
      }
      return referenceData.value.taxes;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  onBeforeUnmount(() => {
    resetState();
  });

  return {
    getClients,
    getWarehouses,
    getAvailableSalesUnits,
    getProductGroups,
    getSuppliers,
    getDefaultPriceGroup,
    getAvailableTaxes,
    resetState,
  };
});
