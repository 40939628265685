import { useAuth } from "@cumulus/event-bus";
import { type Supplier } from "./model/Supplier";
import { useErrorHandler } from "../ErrorHandler";
import { supplierApi } from "./SupplierApi";

export function useSupplier() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getSupplier = async (id: string): Promise<Supplier> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllSuppliers = async (): Promise<Supplier[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getSupplier, getAllSuppliers };
}
