<template>
  <div class="flex items-center space-x-2">
    <Checkbox v-model="importOptions.overwriteExisting" :binary="true" inputId="overwrite" />
    <label for="overwrite">{{ t("product.import.options.has-headers") }}</label>
  </div>
  <div class="flex items-center space-x-2">
    <Checkbox v-model="importOptions.createNew" :binary="true" inputId="createNew" />
    <label for="createNew">{{ t("product.import.options.create-new") }}</label>
  </div>
  <div class="flex items-center space-x-2">
    <Checkbox v-model="importOptions.updateEmpty" :binary="true" inputId="updateEmpty" />
    <label for="updateEmpty">{{ t("product.import.options.update-existing") }}</label>
  </div>

  <div class="ml-auto flex items-center space-x-4">
    <div class="flex items-center">
      <span class="mr-2">{{ t("product.import.options.product-identifier") }}</span>
      <Select
        v-model="selectedIdentifier"
        :options="systemFields"
        optionLabel="label"
        :placeholder="t('product.import.options.product-number-placeholder')"
        class="w-48"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const optionsStore = useProductImportOptionsStore();
const sessionStore = useProductImportSessionStore();
const { importOptions } = storeToRefs(optionsStore);
const { systemFields } = storeToRefs(sessionStore);

const { t } = useI18n();

const selectedIdentifier = ref<string>("productNumber");
</script>
