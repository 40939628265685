import "@cumulus/components/scss";
import "@/assets/flags.scss";

import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";
import router from "./router/router";
import configureAzureB2C from "./azure-b2c";
import configureApp from "./common";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App);
    },
  },

  async handleInstance(app) {
    app.use(router);
    configureAzureB2C(
      app,
      import.meta.env.VITE_APP_STANDALONE === "true",
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY
        : `${import.meta.env.VITE_APP_API_URL as string}`,
      import.meta.env.VITE_APP_B2C_DOMAIN as string,
      import.meta.env.VITE_APP_B2C_CLIENTID as string,
      import.meta.env.VITE_APP_B2C_INSTANCE as string,
      import.meta.env.VITE_APP_B2C_LOG_LEVEL as string,
    );

    configureApp(app);

    router.isReady().then(() => {
      app.mount();
    });
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
