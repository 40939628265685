import { defineStore } from "pinia";
import { ref } from "vue";
import { Product } from "../repositories/product/model/Product";
import { NewProduct } from "@/repositories/product/model/NewProduct";

export const useAlternativesStore = defineStore("productAlternatives", () => {
  const _product = ref<Product | NewProduct>(new Product());

  const addAlternatives = (alternativeIds: string[]): void => {
    _product.value.alternativeProductIds = [..._product.value.alternativeProductIds, ...alternativeIds];
  };

  const removeAlternativeById = (id: string): void => {
    _product.value.alternativeProductIds = _product.value.alternativeProductIds.filter((x) => x !== id);
  };

  const useProduct = (product: Product | NewProduct): void => {
    _product.value = product;
  };

  return {
    product: _product,
    addAlternatives,
    removeAlternativeById,
    useProduct,
  };
});
