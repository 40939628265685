<template>
  <FloatLabelDropdown
    id="product-type"
    dataTestId="product-type"
    v-model:value="selectedProductType"
    :options="dropdownOptions"
    :label="t(`product.type.label`)"
    :isFloatingLabel="isFloatingLabel"
  />
  <small id="product-type-help" class="p-error" v-if="val.productType.$error">
    {{ val.productType.$errors[0].$message }}
  </small>

  <!-- Hack for miss PrimeVue css variables from CumulusComponents -->
  <Select style="display: none"></Select>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, WritableComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { ProductType } from "@/repositories/product/model/ProductType";
import { required } from "@/locales/i18n-validators";
import { FloatLabelDropdown } from "@cumulus/components";

const props = defineProps<{
  productType: string;
}>();

const emit = defineEmits<{
  (e: "update:productType", type: string): void;
}>();

const { t } = useI18n();
const languageKey = "product.type.";

const selectedProductType: WritableComputedRef<string> = computed({
  get: () => {
    return props.productType ?? "";
  },
  set: (value) => {
    emit("update:productType", value);
  },
});

const productTypes = ref<Record<string, string>[]>([]);

const rules = {
  productType: {
    required: required,
  },
};

const val = useValidate(rules, props);

const fetchStatusTypes = () => {
  for (const prType in ProductType) {
    productTypes.value.push({
      label: t(`${languageKey}${prType.toLowerCase()}`),
      value: prType,
    });
  }
  if (selectedProductType.value === "" && productTypes.value.length > 0) {
    selectedProductType.value = productTypes.value[0].code;
  }
};
onMounted(fetchStatusTypes);

const dropdownOptions = computed(() => {
  return productTypes.value.map((productType) => {
    return { name: productType.label, value: productType.value };
  });
});

const isFloatingLabel = computed(() => {
  return selectedProductType.value !== "";
});
</script>
<style scoped lang="scss">
label {
  color: #999;
  top: -2px;
}
</style>
