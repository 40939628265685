<template>
  <FloatLabelInput
    :inputType="'text'"
    :label="t(`product.description`)"
    v-model:value="descriptionComputed"
    :placeholder="t('placeholder.type', { property: t('product.description').toLowerCase() })"
    :maxlength="8000"
    dataTestId="product-description"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  description: string | null;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value) => {
    emit("update:description", value);
  },
});
</script>
