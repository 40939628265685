import { Product } from "@/repositories/product/model/Product";
import { Price } from "@/repositories/product/model/Price";
import { NewProduct } from "@/repositories/product/model/NewProduct";
import { TaxType } from "@/repositories/tax/model/TaxType";

export function usePriceFunctions() {
  const priceById = (prices: Price[], id: string) => {
    return prices.find((s) => s.priceGroupId === id);
  };

  const priceIncVatFromExVat = (price: number, vatPercentage: number | null) => {
    if (vatPercentage === null || vatPercentage === 0) {
      return price;
    }
    return price * (1 + vatPercentage / 100);
  };

  const contributionMargin = (price: number, costPrice: number) => {
    if (price <= 0) {
      return 0;
    }
    const cm = ((price - costPrice) / price) * 100;
    return Math.min(cm, 100);
  };

  const grossMargin = (price: number, costPrice: number) => {
    return price - costPrice;
  };

  const priceFromIncVat = (priceIncVat: number, vatPercentage: number | null) => {
    if (vatPercentage === null || vatPercentage === 0) {
      return priceIncVat;
    }
    return priceIncVat / (1 + vatPercentage / 100);
  };

  const priceFromContributionMargin = (costPrice: number, contributionMargin: number) => {
    let cm = Math.max(0, contributionMargin);
    cm = cm >= 100 ? 99.99 : cm;
    return costPrice / (1 - cm / 100);
  };

  const priceFromGrossMargin = (costPrice: number, grossMargin: number) => {
    return Math.max(0, costPrice + grossMargin);
  };

  const defaultOutgoingVatPercentage = (product: Product | NewProduct) => {
    return product.taxes?.find((t) => t.taxType === TaxType.Outgoing && t.countryIso === "NO")?.taxRate ?? 0;
  };

  return {
    priceById,
    priceIncVatFromExVat,
    contributionMargin,
    grossMargin,
    priceFromIncVat,
    priceFromContributionMargin,
    priceFromGrossMargin,
    defaultOutgoingVatPercentage,
  };
}
