<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <label class="block text-xs font-medium text-gray-700">
        {{
          t("product.import.set-default-field", {
            field: t("product.import.system-fields.warehouse").toLowerCase(),
          })
        }}
      </label>
    </div>

    <div v-if="loading" class="flex items-center justify-center py-2">
      <ProgressSpinner style="width: 20px; height: 20px" />
      <span class="ml-2 text-sm text-gray-600">{{ t("common.loading") }}</span>
    </div>

    <div v-else>
      <MultiSelect
        v-model="selectedWarehouses"
        :options="availableWarehouses"
        option-label="name"
        :placeholder="t('placeholder.select', { property: t('product.import.system-fields.warehouse').toLowerCase() })"
        class="w-full"
        :max-selected-labels="3"
        @change="applySelection"
      />

      <div class="flex justify-end mt-3">
        <Button
          :label="t('common.clear')"
          size="small"
          severity="secondary"
          :aria-label="t('common.clear')"
          @click="clearSelection"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { Reference } from "@/product/models/ImportOptions";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const loading = ref(true);
const availableWarehouses = ref<Reference[]>([]);
const selectedWarehouses = ref<Reference[]>([]);

const referenceStore = useProductImportReferenceStore();
const optionsStore = useProductImportOptionsStore();
const { clearMappingForColumn } = useProductImportSessionStore();
const { importOptions } = storeToRefs(optionsStore);
const { t } = useI18n();

onMounted(async () => {
  try {
    const warehouses = await referenceStore.getWarehouses();
    availableWarehouses.value = warehouses.map((warehouse) => ({
      id: warehouse.id,
      name: warehouse.name,
    }));

    selectedWarehouses.value = importOptions.value.defaultValues.warehouses;
  } finally {
    loading.value = false;
  }
});

const clearSelection = () => {
  selectedWarehouses.value = [];
  optionsStore.setDefaultWarehouses([]);
};

const applySelection = () => {
  optionsStore.setDefaultWarehouses(selectedWarehouses.value);
  clearMappingForColumn("warehouse");
};
</script>
