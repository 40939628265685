<template>
  <div class="p-6 h-full font-bold">
    <label class="c-under-construction">{{ t("product.excise-duties") }}</label>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
