import { accountGroupApi } from "@/product/api/account-group/AccountGroupApi";
import { AccountGroup } from "@/product/models/AccountGroup";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAccountGroupStore = defineStore("account-group", () => {
  const accountGroups = ref<AccountGroup[]>([]);

  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllAccountGroups = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      accountGroups.value = await accountGroupApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addAccountGroup = async (accountGroup: AccountGroup) => {
    try {
      const authHeaders = await getAuthHeaders();
      await accountGroupApi.create(authHeaders, accountGroup);
      accountGroups.value = [...accountGroups.value, accountGroup];
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateAccountGroup = async (accountGroup: AccountGroup) => {
    try {
      const authHeaders = await getAuthHeaders();
      await accountGroupApi.update(authHeaders, accountGroup);
      accountGroups.value = accountGroups.value.map((x) => (x.id === accountGroup.id ? accountGroup : x));
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteAccountGroup = async (id: string) => {
    try {
      const authHeaders = await getAuthHeaders();
      await accountGroupApi.delete(authHeaders, id);
      accountGroups.value = accountGroups.value.filter((x) => x.id !== id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    accountGroups,
    getAllAccountGroups,
    addAccountGroup,
    updateAccountGroup,
    deleteAccountGroup,
  };
});
