<template>
  <FloatLabel variant="on">
    <InputText
      :id="`product-description-${props.languageIso.toLowerCase()}`"
      :data-testid="`product-description-${props.languageIso.toLowerCase()}`"
      v-model="description"
      type="text"
      class="w-full"
      :invalid="val.description.$error"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product-info-dialog.description').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label :for="`product-description-${props.languageIso.toLowerCase()}`">{{
      t("product-info-dialog.description")
    }}</label>
  </FloatLabel>
  <small
    class="p-error"
    v-if="val.description.$error"
    :data-testid="`product-description-error-${props.languageIso.toLowerCase()}`"
  >
    {{ val.description.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{ languageIso: string }>();
const description = defineModel<string>("description", { required: true, default: "" });

const { t } = useI18n();

const rules = {
  description: {
    required,
  },
};

const val = useVuelidate(rules, { description: description });
</script>
