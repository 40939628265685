import { useAuth } from "@cumulus/event-bus";
import { taxApi } from "./TaxApi";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { Tax } from "./model/Tax";

export function useTax() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getTaxes = async (): Promise<Tax[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await taxApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getTaxes,
  };
}
