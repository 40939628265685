import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { AttributeType } from "@/models/attribute/AttributeType";
import { Ref } from "vue";

export function useAttributeFunctions(attributes: Ref<AttributeByLanguageIso[]>) {
  const getAttributeById = (attributeId: string) => {
    return attributes.value.find((attr) => attr.id === attributeId) || new AttributeByLanguageIso();
  };

  const getAttributeValue = (attributeId: string, attributeValueId: string): string => {
    const attribute = attributes.value.find((a) => a.id === attributeId);
    const value = attribute?.values.find((v) => v.id === attributeValueId);

    return value?.value ?? "";
  };

  const getAttributeValues = (attributeId: string) => {
    return attributes.value.find((attr) => attr.id === attributeId)?.values || [];
  };

  const getAttributeValueAdditionalProperty = (attributeId: string, attributeValueId: string, key: string): string => {
    const attribute = attributes.value.find((a) => a.id === attributeId);
    const value = attribute?.values.find((v) => v.id === attributeValueId);

    return value?.additionalProperties[key] ?? "";
  };

  const isColorAttribute = (attributeId: string) => {
    return getAttributeById(attributeId).attributeType === AttributeType.Color;
  };

  return {
    getAttributeById,
    getAttributeValue,
    getAttributeValues,
    getAttributeValueAdditionalProperty,
    isColorAttribute,
  };
}
