import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export enum ProductHierarchyRoutes {
  productHierarchies = "product-hierarchies",
}

export const productHierarchyRoutes: RouteRecordRaw[] = [
  {
    path: "/product/product-hierarchy",
    name: ProductHierarchyRoutes.productHierarchies,
    component: (): Component => import("@/product-hierarchy/views/ProductHierarchy.vue"),
    meta: {
      authRequired: true,
      title: "product-hierarchy.title",
    },
  },
];
