<template>
  <div class="c-attributes">
    <AttributeToolbar />

    <Card>
      <template #content>
        <div class="c-attribute-list">
          <div
            class="table-header flex flex-col md:flex-row md:justiify-content-between justify-between flex-wrap mb-4 mt-2"
          >
            <div class="formgroup-inline">
              <span class="p-inputgroup">
                <InputText
                  v-model="filter['global'].value"
                  :placeholder="t('common.search')"
                  type="text"
                  data-testid="input-search-attributes"
                  @focus="selectAllOnFocus"
                  autofocus
                />
                <span class="p-button p-inputgroup-addon bg-transparent border-none z-10 -ml-10"
                  ><i class="pi pi-search text-zinc-500"
                /></span>
              </span>
            </div>
            <div class="flex mt-2 md:mt-0 justify-center">
              <div class="flex items-center justify-center">
                <PrimeButton
                  type="button"
                  class="p-button-text p-button-sm w-full"
                  data-testid="add-attribute-btn"
                  @click="visibleAddDialog = true"
                >
                  <i class="c-plus"></i>
                  <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
                </PrimeButton>
              </div>
            </div>
          </div>
          <DataTable
            :value="attributes"
            dataKey="id"
            :autoLayout="true"
            responsiveLayout="scroll"
            selectionMode="single"
            @row-dblclick="onRowSelect"
            :loading="loading"
            stripedRows
            sortField="name"
            :sortOrder="1"
            :paginator="true"
            :rows="25"
            v-model:filters="filter"
            data-testid="attribute-table"
            class="c-datatable"
          >
            <Column
              field="name"
              :header="t(`attribute.name`)"
              :sortable="true"
              filterField="name"
              filterMatchMode="contains"
              headerClass="c-attribute-header w-1/12"
            >
            </Column>
            <Column
              field="attributeType"
              :header="t(`attribute.type`)"
              :sortable="true"
              headerClass="c-attribute-header"
            >
              <template #body="{ data }">
                <span>{{ t(`attribute.types.${data.attributeType.toLowerCase()}`) }}</span>
              </template>
            </Column>
            <Column field="values" :header="t(`attribute.values`)" :sortable="true" headerClass="c-attribute-header">
              <template #body="{ data }">
                <span>{{ data.values.map((x: AttributeValueByLanguageIso) => x.value).join(", ") }}</span>
              </template>
            </Column>
            <Column :header="t('attribute.state')">
              <template #body="{ data }">
                <Checkbox :value="data" v-model="activeAttributes" :disabled="true" />
              </template>
            </Column>

            <Column style="min-width: 8rem" headerClass="c-attribute-header" bodyClass="c-attribute-body text-right">
              <template #body="{ data }">
                <PrimeButton
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-text mr-2"
                  @click.stop="openEditDialog(data.id)"
                />
              </template>
            </Column>

            <template #empty>{{ t("attribute.no-attributes-found") }} </template>
            <template #loading>{{ t("attribute.loading") }}</template>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>

  <AddAttributeDialog v-if="visibleAddDialog" v-model:visibleDialog="visibleAddDialog" />

  <EditAttributeDialog
    v-if="visibleEditDialog"
    :attributeId="selectedAttributeId"
    v-model:visibleDialog="visibleEditDialog"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref, computed, watch } from "vue";
import { FilterMatchMode } from "@primevue/core/api";
import AddAttributeDialog from "@/attribute/components/AddAttributeDialog.vue";
import { AttributeState } from "@/models/attribute/AttributeState";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import AttributeToolbar from "../components/AttributeToolbar.vue";
import EditAttributeDialog from "../components/EditAttributeDialog.vue";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { i18n } from "@/locales/i18n";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { AttributeValueByLanguageIso } from "@/models/attribute/AttributeValueByLanguageIso";

const { t } = useI18n();
const loading = ref(false);
const selectedAttributeId = ref<string>("");
const attributes = ref<AttributeByLanguageIso[]>([]);
const visibleAddDialog = ref(false);
const visibleEditDialog = ref(false);
const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });
const { getAttributes } = useAttribute();

const onRowSelect = (event: DataTableRowDoubleClickEvent) => {
  selectedAttributeId.value = event.data.id;
  visibleEditDialog.value = true;
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const activeAttributes = computed(() => {
  return attributes.value.filter((attribute) => attribute.attributeState === AttributeState.Active);
});

const openEditDialog = (attributeId: string) => {
  selectedAttributeId.value = attributeId;
  visibleEditDialog.value = true;
};

const loadAttributes = async () => {
  loading.value = true;

  let languageIso;

  switch (i18n.global.locale.value) {
    case "en-GB":
      languageIso = LanguageIsoType.English;
      break;
    case "nb-NO":
      languageIso = LanguageIsoType.Norwegian;
      break;
    default:
      languageIso = LanguageIsoType.English;
  }

  try {
    attributes.value = await getAttributes(languageIso);
  } finally {
    loading.value = false;
  }
};
watch(i18n.global.locale, loadAttributes);
onMounted(loadAttributes);
</script>

<style lang="scss" scoped>
.c-attributes {
  margin: var(--default-content-margin);
}
</style>
