<template>
  <div class="ml-8">
    <FloatLabel variant="on">
      <Select
        id="manufacturer-dropdown"
        v-model="selectedManufacturerComputed"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('product.manufacturer.select').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        overlayClass="hidden"
        optionLabel="name"
        data-testid="product-manufacturer"
        :options="manufacturerStore.manufacturers"
        class="w-full"
        pt:list:data-testid="product-manufacturer-list"
        @click="showManufacturerPanel"
      />

      <label for="manufacturer-dropdown">
        {{ t(`product.manufacturer.select`) }}
      </label>
    </FloatLabel>
    <ManufacturerPanel ref="manufacturerPanelRef" v-model:selectedManufacturer="selectedManufacturerComputed" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ManufacturerPanel from "./ManufacturerPanel.vue";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturerStore } from "@/repositories/manufacturer/ManufacturerStore";

const manufacturerStore = useManufacturerStore();

const { t } = useI18n();

const props = defineProps<{
  manufacturerId: string;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerId", value: string): void;
  (e: "update:selectedManufacturer", value: Manufacturer): void;
}>();

const selectedManufacturerComputed = computed<Manufacturer>({
  get: () => manufacturerStore.manufacturerById(props.manufacturerId) ?? new Manufacturer(),
  set: (value) => {
    if (value?.id) {
      emit("update:manufacturerId", value.id);
    }
  },
});

const manufacturerPanelRef = ref();
const showManufacturerPanel = (event: Event) => {
  manufacturerPanelRef.value.toggle(event);
};
</script>
