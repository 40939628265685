import { ProductGroupName } from "./ProductGroupName";

export class ProductHierarchy {
  id = "";
  companyId = "";
  level1: ProductGroupName | null = null;
  level2: ProductGroupName | null = null;
  level3: ProductGroupName | null = null;
  level4: ProductGroupName | null = null;
  level5: ProductGroupName | null = null;
}
