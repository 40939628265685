<template>
  <Card class="w-full">
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2 p-4">
          <i class="pi pi-tag text-lg"></i>
          <h3 class="text-lg font-medium">{{ attribute.name }}</h3>
          <Badge
            :value="productAttribute.attributeValueIds.length + ' ' + t('product.variants.values')"
            severity="info"
          />
        </div>
        <PrimeButton
          icon="pi pi-trash"
          severity="danger"
          text
          @click="removeAttribute(productAttribute.attributeId)"
          :aria-label="t('common.remove')"
        />
      </div>
    </template>
    <template #content>
      <MultiSelect
        v-model="productAttribute.attributeValueIds"
        :options="attribute.values"
        optionLabel="value"
        optionValue="id"
        :placeholder="t('product.variants.select-values')"
        class="w-full"
        display="chip"
      >
        <template #value="{ value }">
          <Chip v-for="valueId in value" :key="valueId" :label="getAttributeValue(valueId)" class="mr-2">
            <template #default v-if="getAttributeAdditionalProperty(valueId, 'hexCode')">
              <ColorPicker
                :modelValue="getAttributeAdditionalProperty(valueId, 'hexCode')"
                :disabled="true"
                pt:preview:class="!opacity-100"
              />
            </template>
          </Chip>
        </template>
        <template #option="{ option }">
          <div class="flex items-center justify-between">
            <ColorPicker
              v-if="option.additionalProperties.hexCode"
              :modelValue="attribute.values.find((val) => val.id === option.id)?.additionalProperties.hexCode"
              :disabled="true"
              class="mr-2"
              pt:preview:class="!opacity-100"
            />
            <div>
              {{ getAttributeValue(option.id) }} -
              {{ getAttributeDescription(option.id) }}
            </div>
          </div>
        </template>
      </MultiSelect>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { useI18n } from "vue-i18n";

const productAttribute = defineModel<ProductAttribute>("productAttribute", {
  required: true,
});

const props = defineProps<{
  attribute: AttributeByLanguageIso;
}>();

const emit = defineEmits<{
  (e: "removeAttribute", attributeId: string): void;
}>();

const { t } = useI18n();

const getAttributeValue = (valueId: string): string => {
  return props.attribute?.values.find((val) => val.id === valueId)?.value || "";
};

const getAttributeAdditionalProperty = (valueId: string, property: string): string => {
  return props.attribute?.values.find((val) => val.id === valueId)?.additionalProperties[property] || "";
};

const getAttributeDescription = (valueId: string): string => {
  return props.attribute?.values.find((val) => val.id === valueId)?.description || "";
};

const removeAttribute = (attributeId: string) => {
  emit("removeAttribute", attributeId);
};
</script>
