import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { productHierarchyApi } from "./ProductHierarchyApi";
import { ProductHierarchy } from "../model/ProductHierarchy";
import { NewProductGroupName } from "../model/NewProductGroupName";
import { ProductGroupName } from "../model/ProductGroupName";
import { useErrorHandler } from "./ErrorHandler";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue";

export const useProductHierarchyStore = defineStore("productHierarchies", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { t } = useI18n();

  const productHierarchies = ref<ProductHierarchy[]>([]);
  const productGroupNames = ref<ProductGroupName[]>([]);
  const loading = ref(false);
  const saving = ref(false);
  const toast = useCumulusToast(useToast());

  const getProductHierarchy = async (productHierarchyId: string): Promise<ProductHierarchy | null> => {
    try {
      loading.value = true;
      if (productHierarchies.value.length === 0) {
        await getProductHierarchies();
      }

      return productHierarchies.value.find((x: ProductHierarchy) => x.id === productHierarchyId) ?? null;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return null;
  };

  const getProductHierarchies = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      productHierarchies.value = await productHierarchyApi.getProductHierarchies(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const addProductHierarchy = async (productHierarchy: ProductHierarchy): Promise<boolean> => {
    let success = true;
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      productHierarchy.id = uuidv4();
      await productHierarchyApi.addProductHierarchy(authHeaders, productHierarchy);
      productHierarchies.value.push(productHierarchy);

      toast.add({
        severity: "success",
        summary: t("product-hierarchy.toast.add-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }

    return success;
  };

  const updateProductHierarchy = async (productHierarchy: ProductHierarchy): Promise<boolean> => {
    let success = true;
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      productHierarchy.companyId = authHeaders.companyId;
      await productHierarchyApi.updateProductHierarchy(authHeaders, productHierarchy);
      const index = productHierarchies.value.findIndex((x) => x.id === productHierarchy.id);
      productHierarchies.value[index] = productHierarchy;

      toast.add({
        severity: "success",
        summary: t("product-hierarchy.toast.update-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }

    return success;
  };

  const deleteProductHierarchy = async (productHierarchyId: string): Promise<boolean> => {
    let success = true;
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productHierarchyApi.deleteProductHierarchy(authHeaders, productHierarchyId);
      const index = productHierarchies.value.findIndex((x) => x.id === productHierarchyId);
      productHierarchies.value.splice(index, 1);

      toast.add({
        severity: "success",
        summary: t("product-hierarchy.toast.delete-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }

    return success;
  };

  const getProductGroupNames = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      productGroupNames.value = await productHierarchyApi.getProductGroupNames(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const addProductGroupName = async (newProductGroupName: NewProductGroupName): Promise<boolean> => {
    let success = true;

    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      newProductGroupName.id = uuidv4();
      await productHierarchyApi.addProductGroupName(authHeaders, newProductGroupName);
      const productGroupName = new ProductGroupName();
      productGroupName.id = newProductGroupName.id;
      productGroupName.names = newProductGroupName.names;
      productGroupNames.value.push(productGroupName);

      toast.add({
        severity: "success",
        summary: t("product-group-name.toast.add-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }
    return success;
  };

  const updateProductGroupName = async (productGroupName: ProductGroupName): Promise<boolean> => {
    let success = true;
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      productGroupName.companyId = authHeaders.companyId;
      await productHierarchyApi.updateProductGroupName(authHeaders, productGroupName);
      const index = productGroupNames.value.findIndex((x) => x.id === productGroupName.id);
      productGroupNames.value[index] = productGroupName;

      toast.add({
        severity: "success",
        summary: t("product-group-name.toast.update-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }

    return success;
  };

  const deleteProductGroupName = async (productGroupNameId: string): Promise<boolean> => {
    let success = true;
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productHierarchyApi.deleteProductGroupName(authHeaders, productGroupNameId);
      const index = productGroupNames.value.findIndex((x) => x.id === productGroupNameId);
      productGroupNames.value.splice(index, 1);

      toast.add({
        severity: "success",
        summary: t("product-group-name.toast.delete-success"),
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      saving.value = false;
    }

    return success;
  };

  return {
    saving,
    loading,
    productHierarchies,
    productGroupNames,
    getProductHierarchy,
    getProductHierarchies,
    addProductHierarchy,
    updateProductHierarchy,
    deleteProductHierarchy,
    getProductGroupNames,
    addProductGroupName,
    updateProductGroupName,
    deleteProductGroupName,
  };
});
