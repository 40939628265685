<template>
  <Button
    v-if="!isOpen"
    class="lg:hidden fixed bottom-4 right-4 z-50 !shadow-lg"
    severity="primary"
    rounded
    icon="pi pi-plus"
    @click="isOpen = true"
  />

  <Dialog
    v-model:visible="isOpen"
    position="right"
    :modal="true"
    :dismissableMask="true"
    :showHeader="false"
    :breakpoints="{ '960px': '100vw' }"
    :style="{ width: '24rem', height: '100vh', margin: 0, padding: 0 }"
    class="lg:hidden p-0"
  >
    <div class="flex flex-col h-full">
      <div class="flex justify-between items-center p-4 sm:p-6 border-b flex-none">
        <h2 class="text-lg font-semibold">{{ t("product.import.system-fields-header") }}</h2>
        <Button icon="pi pi-times" text rounded @click="isOpen = false" />
      </div>

      <div class="flex-1 overflow-y-auto">
        <SystemFieldsContent
          :column-mappings="columnMappings"
          :selected-field="selectedField"
          @toggle-field="toggleField"
          @update:columnMappings="emit('update:columnMappings', $event)"
          @default-value-set="emit('defaultValueSet', $event.fieldName, $event.value)"
        />
      </div>
    </div>
  </Dialog>

  <div class="hidden lg:flex w-96 bg-white border-r flex-col min-h-[50vh] h-full">
    <div class="p-4 sm:p-6 border-b flex-none">
      <h2 class="text-lg font-semibold mb-2">{{ t("product.import.system-fields-header") }}</h2>
      <div v-if="hasUnmappedRequired">
        <div class="flex items-center">
          <i class="pi pi-exclamation-circle text-yellow-500 mr-2" />
          <span class="text-sm">
            {{ t("product.import.required-fields-notice") }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex-1 overflow-y-auto">
      <SystemFieldsContent
        :column-mappings="columnMappings"
        :selected-field="selectedField"
        @toggle-field="toggleField"
        @update:columnMappings="emit('update:columnMappings', $event)"
        @default-value-set="emit('defaultValueSet', $event.fieldName, $event.value)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { SystemField } from "@/product/models/SystemField";
import type { ColumnMapping } from "@/product/models/ColumnMapping";
import { DefaultValueMapping } from "@/product/models/ImportOptions";
import SystemFieldsContent from "./SystemFieldsContent.vue";

const props = defineProps<{
  columnMappings: ColumnMapping[];
}>();

const emit = defineEmits<{
  (e: "update:columnMappings", mappings: ColumnMapping[]): void;
  (e: "defaultValueSet", fieldName: string, value: DefaultValueMapping | null): void;
}>();

const { t } = useI18n();
const isOpen = ref(false);
const selectedField = ref<SystemField | null>(null);

const hasUnmappedRequired = computed(() => {
  return props.columnMappings.some((mapping) => !mapping.systemField);
});

const toggleField = (field: SystemField): void => {
  selectedField.value = selectedField.value?.name === field.name ? null : field;
};
</script>

<style scoped>
:deep(.p-dialog-content) {
  padding: 0 !important;
  overflow: hidden !important;
}

:deep(.p-dialog) {
  margin: 0;
  height: 100vh;
}
</style>
