import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Client } from "./model/Client";

class ClientApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? `${import.meta.env.VITE_APP_API_COMPANY as string}/clients`
        : `${import.meta.env.VITE_APP_API_URL as string}/clients`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }: AxiosResponse) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Client> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }: AxiosResponse) => data);
  }
}

const clientApi = new ClientApi();
export { clientApi };
