import { useAuth } from "@cumulus/event-bus";
import { Client } from "./model/Client";
import { useErrorHandler } from "../ErrorHandler";
import { clientApi } from "./ClientApi";

export function useClient() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getClient = async (id: string): Promise<Client> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllClients = async (): Promise<Client[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getClient, getAllClients };
}
