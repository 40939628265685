<template>
  <Card class="shadow-none dark:bg-zinc-800">
    <template #header>
      <div class="p-6">
        <div class="flex items-center space-x-4">
          <div class="p-3 bg-indigo-50 rounded-xl">
            <i class="pi pi-sitemap text-xl text-indigo-600"></i>
          </div>
          <div>
            <h3 class="text-xl font-semibold">{{ t("product.variants.variant-details.header") }}</h3>
            <p class="text-sm mt-1">{{ t("product.variants.variant-details.sub-header") }}</p>
          </div>
        </div>
      </div>
    </template>

    <template #content>
      <div class="p-6">
        <div v-if="parentProduct">
          <div
            :toggleable="false"
            class="shadow-none border-none dark:bg-zinc-900 dark:hover:bg-zinc-950 bg-slate-100 hover:bg-slate-200 transition-all duration-200 cursor-pointer rounded-xl"
            @click="navigateToProduct(parentProduct.id)"
          >
            <div class="flex items-center justify-between p-4">
              <div class="flex items-center gap-4">
                <div class="w-16 h-16 bg-white rounded-lg overflow-hidden flex items-center justify-center">
                  <img
                    v-if="parentProduct.productImages?.[0]?.url"
                    :src="parentProduct.productImages[0].url"
                    :alt="parentProduct.name"
                    class="!w-full !h-full object-cover"
                    :pt="{ image: 'w-full h-full object-cover' }"
                    :preview="true"
                  />
                  <i v-else class="pi pi-image text-2xl text-gray-400"></i>
                </div>

                <div>
                  <div class="flex items-center gap-2">
                    <h4 class="font-medium">{{ parentProduct.name }}</h4>
                    <Tag
                      :value="t('product.variants.variant-details.base-product-tag')"
                      severity="info"
                      class="text-xs"
                      :style="{ padding: '0.25rem 0.5rem' }"
                    />
                  </div>
                  <p class="text-sm">{{ parentProduct.productNumber }}</p>

                  <div class="flex flex-wrap gap-2 mt-2">
                    <Chip
                      v-for="attr in product.attributes"
                      :key="attr.attributeId"
                      :label="getAttributeValueLabel(attr)"
                      class="!px-2 !py-1 !text-xs !bg-white !text-gray-700"
                    />
                  </div>
                </div>
              </div>

              <div class="flex items-center gap-2 group">
                <span class="text-sm">{{ t("product.variants.variant-details.view-base-product") }}</span>
                <i class="pi pi-chevron-right text-gray-400 group-hover:text-gray-600"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { Product } from "@/repositories/product/model/Product";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps<{
  product: Product;
  parentProduct: Product | null;
  attributes: AttributeByLanguageIso[];
}>();

const router = useRouter();
const { t } = useI18n();

const getAttributeValueLabel = (attr: ProductAttribute) => {
  const attribute = props.attributes.find((a) => a.id === attr.attributeId);
  const value = attribute?.values.find((v) => attr.attributeValueIds.includes(v.id));
  return `${attribute?.name}: ${value?.value ?? t("common.unknown")}`;
};

const navigateToProduct = (id: string) => {
  const url = router.resolve({ path: `/product/edit/${id}` }).href;
  window.open(url, "_blank");
};
</script>
