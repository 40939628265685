<template>
  <div class="flex flex-col gap-1">
    <label :for="`product-information-${props.languageIso.toLowerCase()}`">{{
      t("product-info-dialog.information")
    }}</label>
    <Editor
      :id="`product-information-${props.languageIso.toLowerCase()}`"
      ref="editorRef"
      v-model="information"
      @keydown.escape="handleEscape"
      @load="quillLoaded"
      :editorStyle="editorStyleHeight"
    >
    </Editor>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, nextTick } from "vue";
import Editor from "primevue/editor";

const { t } = useI18n();

const props = defineProps<{
  languageIso: string;
}>();

const information = defineModel<string>("information", { required: true, default: "" });

const editorRef = ref();
const editorStyleHeight = window.innerWidth < 992 ? "height: 7.5rem" : "height: 30rem";

const handleEscape = (event: KeyboardEvent): void => {
  if (event.key === "Escape") {
    event.stopPropagation();
    const editorComponent = editorRef.value;
    if (editorComponent) {
      const inputElement = editorComponent.$el.querySelector(".p-editor-content .ql-editor");
      if (inputElement) {
        inputElement.blur();
        nextTick(() => {
          document.getElementById("editor-wrapper")?.focus();
        });
      }
    }
  }
};

const quillLoaded = () => {
  // Remove tab functionality from quill editor
  try {
    editorRef.value.quill.keyboard.bindings[9] = null;

    const toolbarItems: NodeList = editorRef.value?.$el?.querySelectorAll(".ql-picker-label");
    toolbarItems &&
      toolbarItems.length > 0 &&
      toolbarItems.forEach((item) => {
        item instanceof HTMLElement && item.setAttribute("tabindex", "-1");
      });

    const listItems: NodeList = editorRef.value?.$el?.querySelectorAll(".ql-list");
    listItems &&
      listItems.length &&
      listItems.forEach((item: Node) => {
        item instanceof HTMLElement && item.setAttribute("tabindex", "-1");
      });

    const buttons = [
      ".ql-bold",
      ".ql-italic",
      ".ql-underline",
      ".ql-stroke",
      ".ql-link",
      ".ql-image",
      ".ql-code-block",
      ".ql-clean",
    ];
    buttons.forEach((button) => {
      const element = editorRef.value?.$el?.querySelector(button);
      element && element.setAttribute("tabindex", "-1");
    });

    // This hack is necessary because of https://github.com/primefaces/primevue/issues/5369
    // Broken buttons are instantiated when v-model of editor is changed, and we don't want them to be tabable.
    const specialNeedsButtons = ["select.ql-color", "select.ql-background"];
    const startTime = Date.now();
    const tabIndexInterval = setInterval(() => {
      specialNeedsButtons.forEach((button) => {
        const element = editorRef.value?.$el?.querySelector(button);
        element && element.setAttribute("tabindex", "-1");
      });
      Date.now() - startTime > 5000 && clearInterval(tabIndexInterval);
    }, 200);
  } catch (e) {
    console.error(e);
  }
};
</script>
