import { ProductImage } from "./ProductImage";
import { Product } from "./Product";

export class UpdateProduct extends Product {
  imagesToDelete: ProductImage[] = [];

  constructor(product: Product, imagesToDelete: ProductImage[]) {
    super(product);
    this.imagesToDelete = imagesToDelete;
  }
}
