<template>
  <Dialog
    v-model:visible="visible"
    @keydown.escape.stop="visible = false"
    :modal="true"
    :style="{
      width: showSplitView ? `110vw` : `50vw`,
      maxWidth: `90%`,
      height: `63vh`,
      maxHeight: `95%`,
    }"
    :breakpoints="{ '1199px': '85vw', '575px': '90vw' }"
    :closable="!isSaving"
    :closeOnEscape="!isSaving"
    class="c-form-dialog"
    pt:content:class="h-full"
  >
    <template #header>
      <div class="flex justify-center w-full">
        <div class="font-bold whitespace-nowrap">
          {{ t("product-info-dialog.title") }} :: {{ currentProduct?.productNumber }} - {{ currentProduct?.name }}
        </div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-6 pt-1 h-full">
      <div class="col-span-12" :class="!showSplitView ? 'lg:col-span-12' : 'lg:col-span-6'">
        <ProductInfoPanel
          :languages="languages"
          :hasSplitView="true"
          :showSplitView="showSplitView"
          :productIndex="currentIndex"
          :maxProductIndex="props.products.length - 1"
          v-model:productLanguage="activeProductLanguages"
          @previousProduct="store.previousProduct"
          @nextProduct="store.nextProduct"
          @toggleSplitView="showSplitView = !showSplitView"
        />
      </div>

      <div v-if="showSplitView" class="col-span-12 lg:col-span-6">
        <ProductInfoPanel
          :languages="languages"
          :showSplitView="showSplitView"
          :hasSplitView="false"
          :productIndex="currentIndex"
          :maxProductIndex="props.products.length - 1"
          v-model:productLanguage="activeProductLanguages"
          @previousProduct="store.previousProduct"
          @nextProduct="store.nextProduct"
          @toggleSplitView="showSplitView = !showSplitView"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4 pt-2">
        <Button
          type="button"
          data-testid="ai-btn-ok"
          @click="save"
          class="c-default-button"
          :disabled="isSaving"
          :label="t('common.save-all')"
        >
        </Button>
        <Button
          @click="visible = false"
          data-testid="ai-btn-cancel"
          severity="cancel"
          text
          :disabled="isSaving"
          :label="t('common.cancel')"
        >
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useProductStore } from "@/repositories/product/ProductStore";
import { useProductLanguageStore } from "@/stores/ProductLanguageStore";
import { Product } from "@/repositories/product/model/Product";
import { Language } from "@/product/models/ProductWithLanguage";
import ProductInfoPanel from "./ProductInfoPanel.vue";

const { t } = useI18n();

const visible = defineModel<boolean>("visible", { required: true, default: false });
const showSplitView = ref(false);

const props = defineProps<{
  products: Product[];
}>();

const store = useProductLanguageStore();
const { activeProductLanguages, languages, currentIndex, defaultLanguage } = storeToRefs(store);

watch(visible, async (newValue) => {
  if (newValue) {
    await store.init(props.products.map((x) => x.id));
    await store.getAvailableLanguages();
    requiredLanguages();
  }
});

const { updateProductsDefaultLanguage } = useProductStore();

const toast = useCumulusToast(useToast());

const isSaving = ref(false);
const save = async () => {
  try {
    isSaving.value = true;
    const changedProductsLanguages = store.getChangedProductsLanguages();
    await store.saveChanges();
    await updateProductsDefaultLanguage(changedProductsLanguages, defaultLanguage.value);

    toast.add({
      severity: "success",
      summary: t("product-info-dialog.toast.success.summary"),
      detail: t("product-info-dialog.toast.success.detail"),
      closable: true,
    });

    visible.value = false;
  } catch {
  } finally {
    isSaving.value = false;
  }
};

watch(currentIndex, () => requiredLanguages());

const requiredLanguages = () => {
  languages.value.forEach((language) => {
    const producLanguage = activeProductLanguages.value?.languages.find((x) => x.languageIso === language.code) ?? null;

    if (producLanguage === null) {
      addProductLanguage(language.code);
    }
  });
};

const addProductLanguage = (languageIso: string) => {
  const language = new Language();
  language.languageIso = languageIso;

  if (languageIso === defaultLanguage.value) {
    const product = currentProduct.value;
    if (product) {
      language.name = product.name;
      language.description = product.description;
      language.productInfo = product.productInfo;
      activeProductLanguages.value.languages.push(language);
      return;
    }
  }

  language.name = "";
  language.description = "";
  language.productInfo = "";
  activeProductLanguages.value.languages.push(language);
};

const currentProduct = computed(() => {
  const productId = activeProductLanguages.value?.productId;
  if (!productId) return null;

  return props.products.find((p) => p.id === activeProductLanguages.value?.productId);
});
</script>
