<template>
  <div class="space-y-4">
    <div class="space-y-6">
      <div
        v-if="productAttributes.length === 0 && !showAddAttributeCard"
        class="text-center py-8 bg-gray-50 rounded-lg"
      >
        <i class="pi pi-tags text-3xl text-gray-400 mb-2"></i>
        <p class="text-gray-600">
          {{ t("product.variants.no-variants-configured.header") }}
        </p>
        <Button
          :label="t('common.add')"
          icon="pi pi-plus"
          data-testid="add-variant-attribute-button"
          severity="secondary"
          class="mt-3"
          @click="addAttribute"
        />
      </div>
      <div v-else>
        <VariantHeader
          :totalVariants="variantStore.totalVariants"
          :productAttributes="productAttributes"
          :baseProductName="baseProduct.name"
        />

        <div class="space-y-4 mt-6">
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 grid-rows-[172px]">
            <AttributeCard
              v-for="attr in productAttributes"
              :key="attr.attributeId"
              :attribute="getAttributeById(attr.attributeId)"
              :productAttribute="attr"
              @removeAttribute="removeAttribute"
            />

            <AddAttributeButton
              v-if="productAttributes.length < 5 && !showAddAttributeCard"
              @addAttributeClicked="addAttribute"
            />
            <AddAttributeCard
              v-if="productAttributes.length < 5 && showAddAttributeCard"
              v-model:productAttributes="productAttributes"
              :attributes="attributes"
              @cancelClicked="showAddAttributeCard = false"
            />

            <div
              v-if="productAttributes.length >= 5"
              class="lg:col-span-5 flex items-center justify-center gap-2 py-3 bg-gray-50 rounded-lg border border-gray-200"
            >
              <i class="pi pi-info-circle text-gray-500"></i>
              <span class="text-sm text-gray-600">{{
                t("product.variants.max-attributes-reached", { maximum: 5 })
              }}</span>
            </div>
          </div>
        </div>

        <VariantsPreviewCard
          v-if="variantStore.totalVariants > 0"
          :variants="variants"
          :attributes="attributes"
          @variantsBulkUpdated="variantStore.updateBulkVariants"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { ref, onMounted, watch, computed } from "vue";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import { i18n } from "@/locales/i18n";
import { useI18n } from "vue-i18n";
import { NewProduct } from "@/repositories/product/model/NewProduct";
import { Product } from "@/repositories/product/model/Product";
import { useAttributeFunctions } from "@/product/composables/attributeFunctions";
import { type TrackedBaseProductProperties, useVariantStore } from "@/stores/VariantStore";
import AddAttributeCard from "./AddAttributeCard.vue";
import AddAttributeButton from "./AddAttributeButton.vue";
import AttributeCard from "./AttributeCard.vue";
import VariantHeader from "./VariantHeader.vue";
import VariantsPreviewCard from "./VariantsPreviewCard.vue";
import { storeToRefs } from "pinia";

const productAttributes = defineModel<ProductAttribute[]>("productAttributes", {
  required: true,
});

const props = defineProps<{
  baseProduct: NewProduct | Product;
}>();

const attributes = ref<AttributeByLanguageIso[]>([]);
const loading = ref(false);
const showAddAttributeCard = ref(false);
const { getAttributes } = useAttribute();
const { getAttributeById } = useAttributeFunctions(attributes);
const { t } = useI18n();
const variantStore = useVariantStore();
const { variants } = storeToRefs(variantStore);

onMounted(async () => {
  await loadAttributesByPortalLanguage();
});

watch(
  productAttributes,
  () => {
    if (attributes.value.length > 0) {
      variantStore.generateVariants(productAttributes.value, props.baseProduct, attributes.value);
    }
  },
  { deep: true },
);

const trackedBaseProductProperties = computed<TrackedBaseProductProperties>(() => ({
  name: props.baseProduct.name,
  productImages: props.baseProduct.productImages,
  supplierPrices: props.baseProduct.supplierPrices,
  prices: props.baseProduct.prices,
}));

watch(
  trackedBaseProductProperties,
  () => {
    variantStore.updateVariantsFromBaseProduct(trackedBaseProductProperties.value, attributes.value);
  },
  { deep: true },
);

const loadAttributesByPortalLanguage = async () => {
  loading.value = true;
  const languageIso = i18n.global.locale.value;
  try {
    attributes.value = await getAttributes(languageIso);
    variantStore.generateVariants(productAttributes.value, props.baseProduct, attributes.value);
  } finally {
    loading.value = false;
  }
};

const addAttribute = () => {
  showAddAttributeCard.value = true;
};

const removeAttribute = (attributeId: string) => {
  const index = productAttributes.value.findIndex((attr) => attr.attributeId === attributeId);
  if (index !== -1) {
    productAttributes.value.splice(index, 1);
  }
};
</script>
