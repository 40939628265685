<template>
  <div class="mb-6">
    <h3 class="text-sm font-semibold mb-2 text-gray-700">
      {{ t("product.import.edit-product.basic-information") }}
    </h3>
    <div class="p-4 border rounded-md bg-gray-50">
      <div class="grid grid-cols-2 gap-x-4 gap-y-4">
        <div class="field">
          <label for="productNumber" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.productnumber") }}*
          </label>
          <InputText
            id="productNumber"
            v-model="product.productNumber"
            class="w-full"
            :invalid="val.product.productNumber.$error"
            :placeholder="
              t('placeholder.type', { property: t('product.import.system-fields.productnumber').toLowerCase() })
            "
            @update:model-value="() => handleFieldChange('productNumber')"
          />
          <small class="p-error" v-if="val.product.productNumber.$error" :data-testid="`product-number-error`">
            {{ val.product.productNumber.$errors[0].$message }}
          </small>
        </div>

        <div class="field">
          <label for="name" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.name") }}*
          </label>
          <InputText
            id="name"
            v-model="product.name"
            class="w-full"
            :placeholder="t('placeholder.type', { property: t('product.import.system-fields.name').toLowerCase() })"
            :invalid="val.product.name.$error"
            data-testid="product-name"
            @update:model-value="() => handleFieldChange('name')"
          />
          <small class="p-error" v-if="val.product.name.$error" :data-testid="`product-name-error`">
            {{ val.product.name.$errors[0].$message }}
          </small>
        </div>

        <div class="field">
          <label for="productType" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.producttype") }}*
          </label>
          <Select
            id="productType"
            v-model="product.productType"
            :options="productTypeOptions"
            class="w-full"
            :placeholder="
              t('placeholder.select', { property: t('product.import.system-fields.producttype').toLowerCase() })
            "
            :invalid="val.product.productType.$error"
            @change="() => handleFieldChange('productType')"
          />
          <small class="p-error" v-if="val.product.productType.$error" :data-testid="`product-type-error`">
            {{ val.product.productType.$errors[0].$message }}
          </small>
        </div>

        <div class="field">
          <label for="productStatus" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.productstatus") }}*
          </label>
          <Select
            id="productStatus"
            v-model="product.productStatus"
            :options="productStatusOptions"
            class="w-full"
            :placeholder="
              t('placeholder.select', { property: t('product.import.system-fields.productstatus').toLowerCase() })
            "
            :invalid="val.product.productStatus.$error"
            @change="() => handleFieldChange('productStatus')"
          />
          <small class="p-error" v-if="val.product.productStatus.$error" :data-testid="`product-status-error`">
            {{ val.product.productStatus.$errors[0].$message }}
          </small>
        </div>

        <div class="field">
          <label for="salesUnit" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.salesunitname") }}*
          </label>
          <Select
            id="salesUnit"
            v-model="product.salesUnitName"
            :options="salesUnitOptions"
            optionLabel="name"
            optionValue="name"
            class="w-full"
            :filter="true"
            :loading="loadingSalesUnits"
            :placeholder="
              t('placeholder.select', { property: t('product.import.system-fields.salesunitname').toLowerCase() })
            "
            :invalid="val.product.salesUnitName.$error"
            @change="() => handleFieldChange('salesUnitName')"
          />
          <small class="p-error" v-if="val.product.salesUnitName.$error" :data-testid="`sales-unit-error`">
            {{ val.product.salesUnitName.$errors[0].$message }}
          </small>
        </div>

        <div class="field">
          <label for="vat" class="block font-medium text-sm mb-1">
            {{ t("product.import.system-fields.vat") }}
          </label>
          <Select
            v-model="product.vatRate"
            :options="vatOptions"
            optionLabel="display"
            optionValue="rate"
            class="w-full"
            :filter="true"
            :loading="loadingTaxes"
            :placeholder="t('placeholder.select', { property: t('product.import.system-fields.vat').toLowerCase() })"
            :invalid="val.product.vatRate.$error"
            @change="() => handleFieldChange('vat')"
          />
          <small class="p-error" v-if="val.product.vatRate.$error" :data-testid="`vat-error`">
            {{ val.product.vatRate.$errors[0].$message }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { MappedImportProduct } from "@/product/models/MappedImportProduct";
import type { Reference } from "@/product/models/ImportOptions";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import { ProductType } from "@/repositories/product/model/ProductType";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import type { VatOption } from "@/product/models/VatOption";

const product = defineModel<MappedImportProduct>("product", {
  required: true,
});

const props = defineProps<{
  salesUnitOptions: Reference[];
  vatOptions: VatOption[];
  loadingSalesUnits: boolean;
  loadingTaxes: boolean;
  errors: [string, string][];
}>();

const emit = defineEmits<{
  (e: "fieldChanged", fieldName: string): void;
}>();

const { t } = useI18n();

const productTypeOptions = Object.values(ProductType);
const productStatusOptions = Object.values(ProductStatus);

const getErrorForField = (fieldName: string): string => {
  let error = props.errors.find(([field]) => field?.toLowerCase() === fieldName.toLowerCase());

  if (!error) {
    error = props.errors.find(
      ([field]) =>
        field?.toLowerCase() === fieldName.toLowerCase() || fieldName.toLowerCase() === field.toLowerCase() || "",
    );
  }

  return error ? error[1] : "";
};

const handleFieldChange = (fieldName: string): void => {
  emit("fieldChanged", fieldName);
};

const rules = {
  product: {
    productNumber: {
      serverFailed: helpers.withMessage(getErrorForField("productNumber"), () => {
        return getErrorForField("productNumber") === "";
      }),
    },
    name: {
      required: helpers.withMessage(t("validations.required"), required),
      serverFailed: helpers.withMessage(getErrorForField("name"), () => {
        return getErrorForField("name") === "";
      }),
    },
    productType: {
      required: helpers.withMessage(t("validations.required"), required),
      serverFailed: helpers.withMessage(getErrorForField("productType"), () => {
        return getErrorForField("productType") === "";
      }),
    },
    productStatus: {
      required: helpers.withMessage(t("validations.required"), required),
      serverFailed: helpers.withMessage(getErrorForField("productStatus"), () => {
        return getErrorForField("productStatus") === "";
      }),
    },
    salesUnitName: {
      required: helpers.withMessage(t("validations.required"), required),
      serverFailed: helpers.withMessage(getErrorForField("salesUnitName"), () => {
        return getErrorForField("salesUnitName") === "";
      }),
    },
    vatRate: {
      serverFailed: helpers.withMessage(getErrorForField("vat"), () => {
        return getErrorForField("vat") === "";
      }),
      required: helpers.withMessage(t("validations.required"), required),
    },
  },
};

const val = useVuelidate(rules, { product: product.value });
</script>

<style scoped>
.field {
  margin-bottom: 1.5rem;
}
</style>
