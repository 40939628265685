<template>
  <FileUpload
    accept=".doc,.docx,.pdf,.txt,.rtf"
    :multiple="true"
    :maxFileSize="10000000"
    :auto="true"
    :customUpload="true"
    data-testid="product-documents-upload"
    class="w-full"
    pt:root:class="border-none"
    @uploader="uploader"
  >
    <template #header="{ chooseCallback }">
      <div class="flex flex-wrap justify-center items-center flex-1 gap-4">
        <div class="flex gap-2">
          <Button class="c-circular-button mr-4" @click="chooseCallback()">
            <i class="pi pi-book c-default-button c-circular-icon"></i>
            <span class="px-1 text-xs">
              {{ t("product.documents.upload") }}
            </span>
          </Button>
          <Button
            class="c-circular-button mr-4"
            :disabled="documents.length === 0"
            @click="emit('clearDocumentsClicked', $event)"
          >
            <i class="pi pi-times c-delete-button c-circular-icon"></i>
            <span class="px-1 text-xs">{{ t("product.documents.clear") }} </span>
          </Button>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="documents.length === 0" class="flex items-center justify-center flex-col c-empty-state">
        <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
        <p class="mt-2 mb-0">{{ t("product.documents.drag-and-drop") }}</p>
        <p class="text-sm text-gray-500">{{ t("product.documents.supported-formats") }}</p>
      </div>
    </template>
  </FileUpload>
</template>

<script setup lang="ts">
import { type FileUploadUploaderEvent } from "primevue/fileupload";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";

import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { ProductDocument } from "@/repositories/product/model/ProductDocument";
import { useDocumentService } from "@/product/api/document/DocumentService";

const uploadInProgress = ref(false);
const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { uploadDocument } = useDocumentService();

defineProps<{
  documents: ProductDocument[];
}>();

const emit = defineEmits<{
  (e: "documentUploaded", documentInfo: ProductDocument): void;
  (e: "clearDocumentsClicked", event: Event): void;
}>();

const uploader = async (event: FileUploadUploaderEvent) => {
  const files = event.files as File[];

  if (files.length === 0) {
    return;
  }

  try {
    uploadInProgress.value = true;
    for (const file of files as File[]) {
      const formData = new FormData();
      formData.append("file", file);

      const documentInfo = await uploadDocument(formData);
      emit("documentUploaded", documentInfo);
    }
    toast.add({
      severity: "success",
      summary: t("product.upload-started"),
      detail: t("product.upload-started-details"),
    });
  } finally {
    uploadInProgress.value = false;
  }
};
</script>
<style scoped>
.c-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32rem;
  padding: 2rem;
}

:deep(.c-circular-button) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
