<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <label class="block text-xs font-medium text-gray-700">
        {{
          t("product.import.set-default-field", {
            field: t("product.import.system-fields.suppliernames").toLowerCase(),
          })
        }}
      </label>
    </div>

    <div v-if="loading" class="flex items-center justify-center py-2">
      <ProgressSpinner style="width: 20px; height: 20px" />
      <span class="ml-2 text-sm text-gray-600">{{ t("common.loading") }}</span>
    </div>

    <div v-else>
      <Select
        v-model="selectedSupplier"
        :options="availableSuppliers"
        option-label="name"
        :placeholder="
          t('placeholder.select', { property: t('product.import.system-fields.suppliernames').toLowerCase() })
        "
        class="w-full"
        @change="applySelection"
      />

      <div class="flex justify-end mt-3">
        <Button
          :label="t('common.clear')"
          size="small"
          severity="secondary"
          :aria-label="t('common.clear')"
          @click="clearSelection"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { Reference } from "@/product/models/ImportOptions";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const referenceStore = useProductImportReferenceStore();
const optionsStore = useProductImportOptionsStore();
const { importOptions } = storeToRefs(optionsStore);
const { clearMappingForColumn } = useProductImportSessionStore();
const { t } = useI18n();

const loading = ref(true);
const availableSuppliers = ref<Reference[]>([]);
const selectedSupplier = ref(importOptions.value.defaultValues.mainSupplier);

onMounted(async () => {
  try {
    availableSuppliers.value = await referenceStore.getSuppliers();
  } finally {
    loading.value = false;
  }
});

const clearSelection = () => {
  selectedSupplier.value = null;
  optionsStore.setDefaultSupplier(null);
};

const applySelection = () => {
  optionsStore.setDefaultSupplier(selectedSupplier.value);
  clearMappingForColumn("supplierNames");
};
</script>
