<template>
  <div class="pb-6 pl-4">
    <label class="font-bold"> {{ t("product.attribute.label") }} </label>
  </div>
  <div class="space-y-4">
    <ProductAttributeList v-model:productAttributes="mappedProductAttributes" @editAttribute="onEditAttribute" />
    <PrimeButton
      type="button"
      class="p-button-text p-button-sm"
      data-testid="manage-attributes-btn"
      @click="visibleManageAttributeDialog = true"
      id="manage-attributes-btn"
    >
      <i class="pi pi-cog"></i>
      <span class="ml-2 c-default-text">{{ t("product.attribute.manage-attributes") }}</span>
    </PrimeButton>

    <ManageAttributes
      v-model:visible="visibleManageAttributeDialog"
      v-model:productAttributes="productAttributes"
      :attributes="attributes"
    />
  </div>
</template>

<script setup lang="ts">
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { computed, onMounted, ref, watch } from "vue";
import { i18n } from "@/locales/i18n";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import ProductAttributeList from "./ProductAttributeList.vue";
import ManageAttributes from "./ManageAttributes.vue";
import { useI18n } from "vue-i18n";

const productAttributes = defineModel<ProductAttribute[]>("productAttributes", {
  required: true,
});

const loading = ref(false);
const visibleManageAttributeDialog = ref(false);
const selectedProductAttribute = ref<ProductAttribute>(new ProductAttribute());
const attributes = ref<AttributeByLanguageIso[]>([]);
const { getAttributes } = useAttribute();
const { t } = useI18n();

const loadAttributesByPortalLanguage = async () => {
  loading.value = true;
  const languageIso = i18n.global.locale.value === "nb-NO" ? LanguageIsoType.Norwegian : LanguageIsoType.English;
  try {
    attributes.value = await getAttributes(languageIso);
  } finally {
    loading.value = false;
  }
};

const onEditAttribute = (attributeId: string, valueIds: string[]) => {
  const productAttribute = new ProductAttribute();
  productAttribute.attributeId = attributeId;
  productAttribute.attributeValueIds = valueIds;
  selectedProductAttribute.value = productAttribute;
};

const mappedProductAttributes = computed({
  get: () => {
    return productAttributes.value
      .map((pa) => {
        const attribute = attributes.value.find((a) => a.id === pa.attributeId);
        if (!attribute) return null;

        return {
          ...attribute,
          values: attribute.values.filter((v) => pa.attributeValueIds.includes(v.id)),
        };
      })
      .filter(Boolean) as AttributeByLanguageIso[];
  },
  set: (newValue: AttributeByLanguageIso[]) => {
    productAttributes.value = newValue.map((attr) => ({
      attributeId: attr.id,
      attributeValueIds: attr.values.map((v) => v.id),
    }));
  },
});

onMounted(() => {
  loadAttributesByPortalLanguage();
});

watch(
  () => i18n.global.locale.value,
  () => {
    loadAttributesByPortalLanguage();
  },
);
</script>
