<template>
  <div class="p-4 sm:p-6 space-y-4 sm:space-y-6">
    <div v-if="requiredFields.length">
      <h3 class="text-sm font-medium text-gray-500 mb-3 sticky top-0 bg-white py-2 z-10">
        {{ t("product.import.required-fields") }}
      </h3>
      <div class="space-y-3">
        <div
          v-for="field in requiredFields"
          :key="field.name"
          :class="[
            'rounded-lg border overflow-hidden',
            selectedField?.name === field.name ? 'border-blue-500 bg-blue-50' : 'border-gray-200',
          ]"
        >
          <div
            class="p-4 sm:p-3 cursor-pointer touch-manipulation active:bg-gray-50"
            @click="emit('toggleField', field)"
          >
            <div class="flex items-center justify-between mb-1 flex-wrap gap-2">
              <span class="font-medium text-sm truncate max-w-[60%]">
                {{ t(`product.import.system-fields.${field.name.toLowerCase()}`) }}
                <span class="text-red-500 ml-0.5">*</span>
              </span>
              <FieldStatusTag
                :is-mapped="isFieldMapped(props.columnMappings, field.name)"
                :has-default-value="hasDefaultValue(field.name)"
              />
            </div>
            <p class="text-xs text-gray-500 break-words">
              {{ t(`product.import.system-fields.${field.name.toLowerCase()}-description`) }}
            </p>
          </div>

          <div class="border-t bg-gray-50 p-4 sm:p-3" v-if="selectedField?.name === 'productNumber'">
            <div class="flex justify-between items-center mb-2">
              <label class="block text-xs font-medium text-gray-700">
                {{ t("product.import.product-number-info") }}
              </label>
            </div>
          </div>

          <SetDefaultName v-if="selectedField?.name === field.name && field.name === 'name'" />

          <SetDefaultProductGroup
            v-if="selectedField?.name === field.name && field.name === 'productGroupNameLevel1'"
          />

          <SetDefaultSalesUnit v-if="selectedField?.name === field.name && field.name === 'salesUnitName'" />

          <SetDefaultWarehouses v-if="selectedField?.name === field.name && field.name === 'warehouse'" />

          <SetDefaultClients v-if="selectedField?.name === field.name && field.name === 'clients'" />

          <SetDefaultSupplier v-if="selectedField?.name === field.name && field.name === 'supplierNames'" />

          <SetDefaultPriceGroups v-if="selectedField?.name === field.name && field.name === 'priceGroupNames'" />

          <SetDefaultVat v-if="selectedField?.name === field.name && field.name === 'vat'" />

          <SetDefaultProductType v-if="selectedField?.name === field.name && field.name === 'productType'" />

          <SetDefaultProductStatus v-if="selectedField?.name === field.name && field.name === 'productStatus'" />
        </div>
      </div>
    </div>

    <div v-if="optionalFields.length">
      <h3 class="text-sm font-medium text-gray-500 mb-3 sticky top-0 bg-white py-2 z-10">
        {{ t("product.import.optional-fields") }}
      </h3>
      <div class="space-y-3">
        <div
          v-for="field in optionalFields"
          :key="field.name"
          class="p-4 sm:p-3 rounded-lg border border-gray-200 touch-manipulation"
        >
          <div class="flex justify-between items-center">
            <span class="text-sm break-words">
              {{ t(`product.import.system-fields.${field.name.toLowerCase()}`) }}
            </span>
            <FieldStatusTag
              v-if="isFieldMapped(props.columnMappings, field.name)"
              :is-mapped="true"
              :has-default-value="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import type { SystemField } from "@/product/models/SystemField";
import type { ColumnMapping } from "@/product/models/ColumnMapping";
import { DefaultValueMapping } from "@/product/models/ImportOptions";
import SetDefaultClients from "./SetDefaultClients.vue";
import SetDefaultName from "./SetDefaultName.vue";
import SetDefaultProductGroup from "./SetDefaultProductGroup.vue";
import SetDefaultProductStatus from "./SetDefaultProductStatus.vue";
import SetDefaultProductType from "./SetDefaultProductType.vue";
import SetDefaultSalesUnit from "./SetDefaultSalesUnit.vue";
import SetDefaultWarehouses from "./SetDefaultWarehouses.vue";
import SetDefaultSupplier from "./SetDefaultSupplier.vue";
import SetDefaultPriceGroups from "./SetDefaultPriceGroups.vue";
import SetDefaultVat from "./SetDefaultVat.vue";
import FieldStatusTag from "../FieldStatusTag.vue";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import { useProductImportFields } from "@/product/composables/productImportFields";

const props = defineProps<{
  columnMappings: ColumnMapping[];
  selectedField: SystemField | null;
}>();

const emit = defineEmits<{
  (e: "toggleField", field: SystemField): void;
  (e: "defaultValueSet", event: { fieldName: string; value: DefaultValueMapping | null }): void;
}>();

const { t } = useI18n();
const sessionStore = useProductImportSessionStore();
const { hasDefaultValue, isFieldMapped } = useProductImportFields();
const { systemFields } = storeToRefs(sessionStore);

const requiredFields = computed(() => systemFields.value.filter((field) => field.required));
const optionalFields = computed(() => systemFields.value.filter((field) => !field.required));
</script>
