<template>
  <Popover
    id="manufacturer_overlay_panel"
    ref="manufacturerPanelRef"
    appendTo="body"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '400px' }"
    class="manufacture-panel"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <ManufacturerForm
      v-if="showManufacturerForm"
      :manufacturer="manufacturer"
      @cancel="showManufacturerForm = false"
      @manufacturerCreated="onManufacturerCreated($event)"
      @manufacturerUpdated="onManufacturerUpdated($event)"
      @manufacturerDeleted="onManufacturerDeleted($event)"
    />
    <ManufacturerList
      v-else
      :loadingManufacturers="loading"
      :manufacturers="manufacturers"
      @createNewManufacturer="onCreateNewManufacturer()"
      @manufacturerSelected="onManufacturerSelected($event)"
      @editManufacturer="editManufacturer($event)"
      @hideOverlayPanel="hideOverlayPanel"
    />
  </Popover>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturerStore } from "@/repositories/manufacturer/ManufacturerStore";
import ManufacturerList from "./ManufacturerList.vue";
import ManufacturerForm from "./ManufacturerForm.vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

const props = defineProps<{
  selectedManufacturer: Manufacturer;
}>();

const emit = defineEmits<{
  (e: "update:selectedManufacturer", value: Manufacturer): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const manufacturerStore = useManufacturerStore();
const { manufacturers, loading } = storeToRefs(manufacturerStore);

const manufacturerPanelRef = ref();
const showManufacturerForm = ref(false);
const manufacturer = ref<Manufacturer>(new Manufacturer());

const selectedManufacturerComputed = computed<Manufacturer>({
  get: () => props.selectedManufacturer,
  set: (value) => {
    emit("update:selectedManufacturer", value);
  },
});

const toggle = (event: Event) => {
  manufacturerPanelRef.value.toggle(event);
};

const hideOverlayPanel = () => {
  manufacturerPanelRef.value.hide();
  document.getElementById("manufacturer-dropdown")?.focus();
};

onMounted(() => {
  manufacturerStore.fetchManufacturers();
});

const onManufacturerSelected = (manufacturer: Manufacturer) => {
  selectedManufacturerComputed.value = manufacturer;
  hideOverlayPanel();
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onManufacturerUpdated = (manufacturer: Manufacturer) => {
  selectedManufacturerComputed.value = manufacturer;
  showManufacturerForm.value = false;
  hideOverlayPanel();
};

const onManufacturerCreated = async (newManufacturer: Manufacturer) => {
  await timeout(300);

  await manufacturerStore.fetchManufacturers();

  const manufacturer = manufacturerStore.manufacturerByName(newManufacturer.name);
  if (manufacturer.name !== "") {
    selectedManufacturerComputed.value = manufacturer;
  }

  toast.add({
    severity: "success",
    summary: t("product.manufacturer.toast.success-add.summary"),
    detail: t("product.manufacturer.toast.success-add.detail", { name: newManufacturer.name }),
    closable: true,
  });

  showManufacturerForm.value = false;
  hideOverlayPanel();
};

const onManufacturerDeleted = (manufacturer: Manufacturer) => {
  if (selectedManufacturerComputed.value.id === manufacturer.id) {
    selectedManufacturerComputed.value = new Manufacturer();
  }
  showManufacturerForm.value = false;
};

const onCreateNewManufacturer = () => {
  manufacturer.value = new Manufacturer();
  showManufacturerForm.value = true;
};

const editManufacturer = (editManufacturer: Manufacturer) => {
  manufacturer.value = editManufacturer;
  showManufacturerForm.value = true;
};

defineExpose({ toggle: toggle });
</script>

<style scoped lang="scss">
.manufacture-panel .p-overlaypanel-content {
  height: auto;
}
</style>
