<template>
  <Dialog
    :visible="visibleDialog"
    :header="t('attribute.add-attribute')"
    :modal="true"
    data-testid="attribute-form-dialog"
    class="max-w-3xl mx-auto"
    @update:visible="onCloseDialog"
  >
    <div class="px-4">
      <Tabs value="0" class="mb-6">
        <TabList>
          <Tab
            v-for="(lang, index) in languages"
            :key="index"
            :value="index.toString()"
            :pt:root:data-testid="`attribute-form-tab-${lang}`"
          >
            {{ t(`common.languages.${lang.code.toLowerCase()}`) }}
          </Tab>
        </TabList>
        <TabPanel v-for="(lang, index) in languages" :key="index" :value="index.toString()">
          <div class="px-4 pt-4">
            <AttributeForm
              :attribute="attribute"
              :attributeName="attributeNamesByLanguage[lang.code]"
              :attributeValues="attributeValuesByLanguage[lang.code]"
              :languageIso="lang.code"
              @setAttributeName="setAttributeName"
              @setAttributeValue="setAttributeValue"
              @addNewAttributeValue="addNewAttributeValue"
              @deleteValue="deleteValue"
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>

    <template #footer>
      <AttributeFooter
        :isEditing="false"
        :isSaving="isSaving"
        :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
        @saveClicked="onSave"
        @closeClicked="onCloseDialog"
        @stayOnPage="closeDialog"
        @routeToAttributeList="onDiscard"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import AttributeForm from "./AttributeForm.vue";
import { storeToRefs } from "pinia";
import { useAddAttributeStore } from "../stores/useAddAttributeStore";
import AttributeFooter from "./AttributeFooter.vue";
import { useUnsavedChangesHandling } from "../composables/useUnsavedChangesHandling";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);
const unsavedChangesDialogVisible = ref(false);

const addAttributeStore = useAddAttributeStore();
const { attribute, attributeNamesByLanguage, attributeValuesByLanguage, isAttributeModified } =
  storeToRefs(addAttributeStore);
const { setAttributeName, setAttributeValue, addNewAttributeValue, deleteValue, resetStore } = addAttributeStore;
const { languages } = useProductLanguageFunctions();

const { createAttribute } = useAttribute();

const closeDialog = () => {
  unsavedChangesDialogVisible.value = false;
};

const onDiscard = () => {
  resetStore();
  visibleDialog.value = false;
  unsavedChangesDialogVisible.value = false;
};

const onCloseDialog = () => {
  if (isAttributeModified.value) {
    unsavedChangesDialogVisible.value = true;
  } else {
    resetStore();
    visibleDialog.value = false;
  }
};

useUnsavedChangesHandling(unsavedChangesDialogVisible, closeDialog, onDiscard, onCloseDialog);

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;
    await createAttribute(attribute.value);

    toast.add({
      severity: "success",
      summary: t("attribute.toast.add-success.summary"),
      closable: true,
    });

    resetStore();
    visibleDialog.value = false;
  } finally {
    isSaving.value = false;
  }
};
</script>
