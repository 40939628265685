<template>
  <div class="c-video-card flex flex-col h-full" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="relative">
      <img :src="thumbnailUrl" alt="Video thumbnail" class="w-full h-48 object-cover rounded-t-lg" />
      <div v-if="showPreview" class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <button @click="emit('openPlayDialog')" class="text-white text-4xl">
          <i class="pi pi-play"></i>
        </button>
      </div>
    </div>
    <div class="px-4 pt-4 h-full flex flex-col">
      <div v-if="!isEditing" class="flex-grow overflow-hidden">
        <h3 class="text-lg font-semibold mb-2 line-clamp-1" data-testid="video-name">{{ video.name }}</h3>
        <p class="text-sm text-gray-600 mb-2 line-clamp-3" data-testid="video-description">{{ video.description }}</p>
        <a
          :href="video.uri"
          target="_blank"
          data-testid="video-uri"
          class="text-blue-500 hover:underline text-sm line-clamp-1"
        >
          {{ video.uri }}
        </a>
      </div>
      <div v-else class="flex-grow overflow-y-auto">
        <InputText
          id="video-name"
          :label="t('product.video.name')"
          v-model="editingName"
          class="w-full mb-4"
          data-testid="edit-video-name"
          :placeholder="t('placeholder.type', { property: t('product.video.name').toLowerCase() })"
        />
        <PrimeTextarea
          id="video-description"
          :label="t('product.media.description')"
          v-model="editingDescription"
          class="w-full mb-2"
          data-testid="edit-video-description"
          :placeholder="t('placeholder.type', { property: t('product.video.description').toLowerCase() })"
        />
        <InputText
          id="video-uri"
          :label="t('product.media.uri')"
          v-model="editingUri"
          class="w-full mb-2"
          data-testid="edit-video-uri"
          :placeholder="t('placeholder.type', { property: t('product.video.uri').toLowerCase() })"
        />
      </div>
      <div v-if="!isEditing" class="flex justify-between items-center mt-auto pt-4 pb-2">
        <PrimeButton
          icon="pi pi-pencil"
          class="p-button-text p-button-sm"
          data-testid="edit-video"
          @click="isEditing = true"
        />
        <PrimeButton
          icon="pi pi-trash"
          class="p-button-text p-button-danger p-button-sm"
          data-testid="delete-video"
          @click="onConfirmDelete"
        />
      </div>
      <div v-else class="flex justify-between w-full">
        <PrimeButton text icon="pi pi-times" :label="t('common.cancel')" class="mr-2" @click="onCancel" />
        <PrimeButton
          text
          icon="pi pi-check"
          @click="onEditComplete"
          :label="t('common.save')"
          class="p-button-success"
          data-testid="save-video"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { Media } from "@/repositories/product/model/Media";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import videoPlaceholder from "@/assets/placeholder-video.png";

const props = defineProps<{
  video: Media;
}>();

const emit = defineEmits<{
  (e: "openPlayDialog"): void;
  (e: "deleteVideo"): void;
  (e: "videoEdited", value: Media): void;
}>();

const showPreview = ref(false);
const { t } = useI18n();
const confirm = useConfirm();
const isEditing = ref(false);

const isYouTubeVideo = computed(() => {
  return extractYouTubeId(props.video.uri) !== null;
});

const thumbnailUrl = computed(() => {
  const videoId = extractYouTubeId(props.video.uri);
  return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : videoPlaceholder;
});

const extractYouTubeId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const handleMouseEnter = () => {
  if (isYouTubeVideo.value) {
    showPreview.value = true;
  }
};

const handleMouseLeave = () => {
  showPreview.value = false;
};

const editingName = ref(props.video.name);
const editingDescription = ref(props.video.description);
const editingUri = ref(props.video.uri);

const onEditComplete = () => {
  isEditing.value = false;
  emit("videoEdited", {
    ...props.video,
    name: editingName.value,
    description: editingDescription.value,
    uri: editingUri.value,
  });
};

const onCancel = () => {
  isEditing.value = false;
};

const onConfirmDelete = () => {
  confirm.require({
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: () => emit("deleteVideo"),
  });
};
</script>

<style scoped>
.c-video-card {
  @apply bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg;
  height: 382px;
}
</style>
