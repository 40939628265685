<template>
  <FloatLabelDropdownPanel
    :selectedItemName="selectedManufacturerComputed.name"
    :label="t('product.manufacturer.label')"
    :selectLabel="t('product.manufacturer.select')"
    @toggleDropdownPanel="showManufacturerPanel"
    id="manufacturer-dropdown"
    dataTestId="product-manufacturer"
    :setFocus="true"
  />
  <ManufacturerPanel ref="manufacturerPanelRef" v-model:selectedManufacturer="selectedManufacturerComputed" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ManufacturerPanel from "./ManufacturerPanel.vue";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturerStore } from "@/repositories/manufacturer/ManufacturerStore";

const manufacturerStore = useManufacturerStore();

const { t } = useI18n();

const props = defineProps<{
  manufacturerId: string;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerId", value: string): void;
  (e: "update:selectedManufacturer", value: Manufacturer): void;
}>();

const selectedManufacturerComputed = computed<Manufacturer>({
  get: () => manufacturerStore.manufacturerById(props.manufacturerId),
  set: (value) => {
    if (value?.id) {
      emit("update:manufacturerId", value.id);
    }
  },
});

const manufacturerPanelRef = ref();
const showManufacturerPanel = (event: Event) => {
  manufacturerPanelRef.value.toggle(event);
};
</script>
