<template>
  <div class="flex flex-col items-center justify-center p-8 space-y-6">
    <div v-if="error" class="text-red-500">
      <i class="pi pi-exclamation-circle mr-2"></i>
      {{ error }}
    </div>
    <div v-else>
      <div class="text-center mb-4 h-12 flex items-center justify-center">
        <div class="w-12 h-12 relative flex items-center justify-center">
          <ProgressSpinner
            v-if="isInProgress"
            class="absolute inset-0"
            style="width: 100%; height: 100%"
            strokeWidth="4"
            fill="transparent"
            :pt="{ spinner: { style: { stroke: statusColor } } }"
          />
          <i v-else :class="statusIcon" class="absolute" :style="{ color: statusColor, fontSize: '3rem' }"></i>
        </div>
      </div>

      <h3 class="text-xl font-medium text-center mb-4">{{ statusMessage }}</h3>

      <ProgressBar :value="progress" :style="{ width: '300px' }" class="mb-4" />

      <p class="text-center text-gray-600">
        {{ t("product.import.progress.progress-complete", { progressPercentage: progress }) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ImportStatus } from "@/product/composables/productImportProgress";

const props = defineProps<{
  status: ImportStatus;
  progress: number;
  error: string | null;
}>();

const { t } = useI18n();

const isInProgress = computed(() => {
  return [ImportStatus.Mapping, ImportStatus.Validating, ImportStatus.Importing].includes(props.status);
});

const statusIcon = computed(() => {
  switch (props.status) {
    case ImportStatus.MappingComplete:
    case ImportStatus.ValidationComplete:
    case ImportStatus.ImportComplete:
      return "pi pi-check-circle";
    case ImportStatus.Failed:
      return "pi pi-times-circle";
    default:
      return "pi pi-clock";
  }
});

const statusColor = computed(() => {
  switch (props.status) {
    case ImportStatus.MappingComplete:
    case ImportStatus.ValidationComplete:
    case ImportStatus.ImportComplete:
      return "#22c55e"; // green-500
    case ImportStatus.Failed:
      return "#ef4444"; // red-500
    default:
      return "#3b82f6"; // blue-500
  }
});

const statusMessage = computed(() => {
  switch (props.status) {
    case ImportStatus.Mapping:
      return t("product.import.progress.mapping");
    case ImportStatus.MappingComplete:
      return t("product.import.progress.mapping-complete");
    case ImportStatus.Validating:
      return t("product.import.progress.validating");
    case ImportStatus.ValidationComplete:
      return t("product.import.progress.validation-complete");
    case ImportStatus.Importing:
      return t("product.import.progress.importing");
    case ImportStatus.ImportComplete:
      return t("product.import.progress.import-complete");
    case ImportStatus.Failed:
      return t("product.import.progress.error-occurred");
    default:
      return t("product.import.progress.preparing");
  }
});
</script>
