import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { ProductSearchRequest } from "./model/ProductSearchRequest";
import { ProductSearchResponse } from "./model/ProductSearchResponse";
import { ProductHierarchySearchRequest } from "./model/ProductHierarchySearchRequest";
import { ProductHierarchySearchResponse } from "./model/ProductHierarchySearchResponse";
import { useErrorHandler } from "../ErrorHandler";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productSearch = async (request: ProductSearchRequest): Promise<ProductSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.productSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const productHierarchySearch = async (
    request: ProductHierarchySearchRequest,
  ): Promise<ProductHierarchySearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.productHierarchySearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { productSearch, productHierarchySearch };
}
