<template>
  <div class="place-items-start mx-6 my-5">
    <InputGroup class="w-full">
      <InputGroupAddon><Checkbox v-model="transferInfo" :binary="true"></Checkbox></InputGroupAddon>

      <InputGroupAddon class="w-full justify-start">{{ t("product.ai-search.productInfo") }}</InputGroupAddon>
    </InputGroup>
    <Editor
      v-model="info"
      editorStyle="height: 22rem"
      :pt="{
        root: { class: 'w-full pl-10 -mt-1' },
        toolbar: {
          class: 'border-t-0',
        },
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const info = defineModel<string>("info");
const transferInfo = defineModel<boolean>("transferInfo");
</script>
