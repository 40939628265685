<template>
  <Dialog
    v-model:visible="isVisible"
    modal
    :dismissableMask="true"
    :closeOnEscape="true"
    class="c-fullscreen-dialog"
    pt:root:class="h-screen w-[50vw]"
    pt:content:class="h-full w-full"
    @hide="closeFullScreen"
    @keydown.esc.stop="closeFullScreen"
  >
    <div class="c-fullscreen-galleria">
      <Galleria
        v-model:activeIndex="activeIndex"
        :value="images"
        :responsiveOptions="responsiveOptions"
        :numVisible="10"
        :circular="true"
        :showItemNavigators="true"
        :showThumbnails="true"
      >
        <template #item="slotProps">
          <div class="relative w-full h-full">
            <img :src="slotProps.item.url" :alt="slotProps.item.name" class="w-full h-full object-contain" />
          </div>
        </template>
        <template #thumbnail="slotProps">
          <div class="grid gap-4 justify-center">
            <img
              :src="resizeImage(slotProps.item.url, 100, 100)"
              :alt="slotProps.item.alt"
              class="object-cover w-20 h-20"
            />
          </div>
        </template>
      </Galleria>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { useImageService } from "@/repositories/image/ImageService";

const props = defineProps<{
  images: ProductImage[];
  initialIndex: number;
}>();

const isVisible = defineModel<boolean>("isVisible", {
  required: true,
});

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "close", index: number): void;
}>();

const activeIndex = ref(props.initialIndex);
const { resizeImage } = useImageService();

const closeFullScreen = () => {
  emit("close", activeIndex.value);
  emit("update:isVisible", false);
};

watch(
  () => isVisible,
  (newValue) => {
    if (newValue) {
      activeIndex.value = props.initialIndex;
    }
  },
);

const responsiveOptions = ref([
  {
    breakpoint: "1300px",
    numVisible: 4,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
  },
]);
</script>

<style scoped lang="scss">
.c-fullscreen-galleria {
  width: 100%;
  height: 100%;

  :deep(.p-galleria) {
    height: 100%;
  }

  :deep(.p-galleria-content) {
    height: 100%;
  }

  :deep(.p-galleria-item-wrapper) {
    height: 100%;
  }

  :deep(.p-galleria-items-container) {
    height: 100%;
  }

  :deep(.p-galleria-items) {
    height: 100%;
  }

  :deep(.p-galleria-nav-button) {
    color: black;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 1;
  }
}
</style>
