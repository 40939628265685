import { NewProduct } from "./NewProduct";
import { VariantProduct } from "./VariantProduct";

export class ProductVariantsRequest {
  parentProduct: NewProduct = new NewProduct();
  variants: VariantProduct[] = [];

  constructor(parentProduct?: NewProduct, variants?: VariantProduct[]) {
    this.parentProduct = parentProduct ?? new NewProduct();
    this.variants = variants ?? [];
  }
}
