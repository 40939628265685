export class LanguageText {
  languageIso = "";
  text = "";

  constructor(languageIso = "en-GB", text = "") {
    this.languageIso = languageIso;
    this.text = text;
  }

  public static createLanguageText(languageIso: string, text: string): LanguageText {
    const languageText = new LanguageText();
    languageText.languageIso = languageIso;
    languageText.text = text;
    return languageText;
  }
}
