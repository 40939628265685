{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "created": "Opprettet",
    "add": "Legg til",
    "save": "Lagre",
    "apply": "Bruk",
    "save-as": "Lagre som",
    "update": "Oppdater",
    "cancel": "Avbryt",
    "ignore": "Ignorer",
    "search": "Søk",
    "clear": "Fjern",
    "remove": "Fjern",
    "yes": "Ja",
    "no": "Nei",
    "ok": "OK",
    "ai-assistant": "AI Assistant",
    "draft": "Utkast",
    "delete": "Slett",
    "close": "Lukk",
    "create": "Opprett",
    "use": "Bruk",
    "options": "Alternativer",
    "preview": "Forhåndsvisning",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "addresses": "Adresser",
    "recommended": "Anbefalt",
    "error": "En feil oppsto",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} produkter",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "back": "Tilbake",
    "delete-confirm": "Vil du slette dette elementet?",
    "save-tooltip": "Ingen endringer",
    "delete-confirm-all": "Er du sikker på at du vil slette alle dokumenter?",
    "unknown": "Ukjent",
    "languages": {
      "en": "Engelsk",
      "no": "Norsk"
    },
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Rett alle rød felter og prøv igjen"
    },
    "error-detail": {
      "not-found": "Ikke funnet",
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "bad-request": "Ugyldig forespørsel",
      "error-occured": "En feil oppsto"
    },
    "freight-agreements": {
      "fixed-shipping": "Fast frakt"
    }
  },
  "attribute": {
    "name": "Navn",
    "value": "Verdi",
    "description": "Beskrivelse",
    "color-code": "Fargekode (hex)",
    "values": "Verdier",
    "state": "Aktiv",
    "loading": "Laster inn attributter...",
    "no-attributes-found": "Ingen attributter funnet",
    "load-failed": "Kunne ikke laste inne attributter, grunn: {errorReason}",
    "label": "Attributter",
    "type": "Type",
    "add-attribute": "Legg til attributt",
    "add-value": "Legg til verdi",
    "edit-attribute": "Endre attributt",
    "types": {
      "standard": "Standard",
      "color": "Farge"
    },
    "error": {
      "duplicate": "Duplikate verdier funnet i listen",
      "already-exists": "Attributten finnes allerede",
      "attribute-active": "Attributten er aktiv. Oppdater til inaktiv og prøv å slette attributten igjen",
      "at-least-one-value": "Minst en verdi er påkrevd",
      "fill-all-values": "Fyll inn alle verdiene i listen"
    },
    "toast": {
      "add-success": {
        "summary": "Attributten er lagt til!"
      },
      "update-success": {
        "summary": "Attributten er oppdatert!"
      },
      "delete-success": {
        "summary": "Attributten er slettet!"
      }
    }
  },
  "product": {
    "search-product": "Søk Produkt",
    "add-product":"Legg Til Produkt",
    "edit-product":"Endre Produkt",
    "product-info": "Produktinformasjon",
    "warehouse-and-client": "Lager og klienter",
    "prices-and-fees": "Priser og gebyr",
    "attributes-and-connections": "Attributter og produkt forbindelser",
    "product-group": {
        "label": "Produktgruppe",
        "filter": "Filter produktgrupper",
        "no-found": "Ingen produktgrupper funnet",
        "name": "Navn",
        "level-1": "Nivå 1",
        "level-2": "Nivå 2",
        "level-3": "Nivå 3",
        "level-4": "Nivå 4",
        "level-5": "Nivå 5",
        "group-level": "Gruppenivå"
    },
    "context-menu": {
      "open": "Åpne"
    },
    "ai-search": {
      "label": "AI motor",
      "header": {
        "edit-ai-prompt": "Rediger AI prompt",
        "ai-assistant": "AI generert produktinformasjon"
      },
      "toast": {
        "success-deleted": {
          "summary": "AI prompt er slettet!!",
          "detail": "AI prompt navn er {name}"
        },
        "success-add": {
          "summary": "AI prompt ble lagt til!",
          "detail": "AI prompt navn er {name}"
        },
        "success-update": {
          "summary": "AI prompt lagres!",
          "detail": "AI prompt navn er {name}"
        }
      },
      "lock": "Låst for redigering",
      "generating": "Genererer...",
      "openaigpt4o_mini": "OpenAIGPT4o_mini",
      "openaigpt4":"OpenAIGPT4",
      "advanced": "Avansert",
      "style": "Stil",
      "style-name": "Stilnavn",
      "prompt": "Prompt",
      "language": "Språk",
      "prod-info-text": "Utfyllende beskrivelse som starter med produsentnavn og produktnavn. Inkluder bruksområder. \n * Egenskaper og fordeler. Listeform. \n * Spesifikasjoner. Listeform. \n * Produktanvendelse.  Listeform.",
     
      "product-name": "Kort og saklig. Starter med produsentnavn (maks 100 tegn)",
      "product-description": "Kort beskrivelse av produktets formål (maks 100 tegn).",
      "method": {
        "label": "Metode",
        "productinfofromlink": "Link",
        "productinfofromdescription": "Productname"
    },"referanse": {
      "label": "Referanse *",
      "product-link": "Lim inn link til produktside her",
      "product-number": "Skriv inn produktnummer her"
  },
      "productInfo": "Produktinformasjon",
      "product": {
        "name": "Produktnavn",
        "prompt": "Skriv inn produktnavn"
      },
      "generate-text": "Generer tekst",
      "net-weight": "Netto vekt",
      "net-weight-unit": "I kg",
      "net-dimensions": "Netto dimensjoner",
      "net-dimensions-unit": "Høyde, bredde og lengde i cm.",
      "product-number": "Produktnummer",
      "netto": "Netto",
      "new": "Ny",
      "edit": "Endre",
      "save-new-style": "Lagre som ny stil",
      "delete-style": "Slett valgte stil",
      "save-changes": "Lagre endringer"   
    },
    "title-add": "Nytt produkt",
    "title-edit": "Produkt",
    "created": "Opprettet:",
    "warehouse": {
      "name": "Navn",
      "label": "Lager",
      "title":"Lager",
      "no-warehouse-found": "Ingen nye lager funnet",
      "dialog-header": "Velg Lager",
      "cost-price": "Kostpris"
    },
    "active-error": {
      "header": "Produktet er aktivt",
      "detail": "Prøv å sette til inaktiv og prøv igjen"
    },
    "domain-link": "Domenekobling",
    "product-links": "Produktkoblinger",
    "fees": "Avgifter",
    "excise-duties": "Særavgifter",
    "number": "Produktnummer",
    "number-input": "Produktnummer, la stå tomt for å generere automatisk",
    "gti-number": "GTIN(EAN)",
    "name": "Produktnavn",
    "description": "Produktbeskrivelse",
    "info": "Produktinfo",
    "open-editor": "Åpne editor",
    "close-editor": "Lukke editor",
    "webshop-active": "Aktiv på webshop",
    "dimension-weight": "Mål og vekt",
    "tax-rate": "Moms",
    "download-link-missing": "Kunne ikke hente dokument, vennligst prøv igjen senere",
    "upload-started": "Opplasting startet",
    "upload-started-details": "Laster opp dokumenter, vennligst vent...",
    "documents": {
      "header": "Dokumenter",
      "title": "Tittel",
      "description": "Beskrivelse",
      "no-documents-found": "Ingen dokumentavtaler funnet for denne leverandøren",
      "name": "Dokumentnavn",
      "type": "Dokumenttype",
      "no-description": "Ingen beskrivelse",
      "unnamed-document": "Navnløst dokument",
      "choose-label": "Legg til dokumenter",
      "drag-and-drop": "Dra og slipp nye dokumenter her",
      "upload": "Last opp dokument",
      "manage": "Administrer dokumenter",
      "select": "Velg dokument",
      "clear": "Tøm dokumenter",
      "supported-formats": "Støttede formater: DOC, DOCX, PDF, TXT, RTF (Maks: 10MB)",
      "manage-product-documents": "Administrer produkt dokumenter"
    },
    "account-group": {
      "label": "Kontogruppe",
      "name": "Navn",
      "no-account-groups-found": "Ingen kontogrupper funnet",
      "create-account-group": "Opprett kontogruppe",
      "update-account-group": "Oppdater kontogruppe",
      "account-group-saved": "Kontogruppe lagret",
      "account-group-deleted": "Kontogruppe slettet",
      "standard-cant-be-altered": "Standard kontogruppe kan ikke endres"
    },
    "header": {
      "add-product": "Legg til produkt",
      "edit-product": "Oppdater produkt"
    },
    "search-header": {
      "products": "Produkter",
      "add-product": "Legg til produkt",
      "open-product": "Åpne",
      "no-product-selected": "Ingen produkt er valgt. Velg et produkt og forsøk igjen.",
      "refresh-product-list": "Last inn på nytt"
    },
    "dimension": {
      "label": "Dimensjoner:",
      "length": "Lengde",
      "width": "Bredde",
      "height": "Høyde",
      "unit": "Enhet",
      "weight": "Vekt"
    },
    "hierarchy": {
      "label": "Produkt nivå",
      "placeholder": "Velg produkt nivå"
    },
    "type": {
      "label": "Produkttype",
      "merchandise": "Vare",
      "servicegoods": "Servicevarer",
      "variant": "Variant"
    },
    "status": {
      "label": "Status",
      "active": "Aktiv",
      "inactive": "Inaktiv",
      "discontinued": "Avviklet"
    },
    "sales-unit": {
      "label": "Salgsenhet",
      "not-found": "Ingen salgsenheter funnet",
      "server-error": "Kunne ikke laste inn salgsenheter",
      "select": "Velg en salgsenhet...",
      "search": "Søk etter en salgsenhet...",
      "loading": "Laster inn salgsenheter...",
      "success-create": {
        "summary": "Salgsenhet ble lagt til!",
        "detail": "Legger nå til salgsenhet for produktet.."
      },
      "success-update": {
        "summary": "Salgsenheten ble oppdatert!",
        "detail": "For salgsenhet {name}"
      },
      "success-delete": {
        "summary": "Salgsenheten er slettet",
        "detail": "For salgsenhet {name}"
      },
      "name": "Navn",
      "description": "Beskrivelse"
    },
    "media": {
      "label": "Media",
      "dialog-header": "Legg til media",
      "no-media-found": "Fant ingen medier",
      "description": "Beskrivelse",
      "media-type": "Mediatype",
      "uri": "Uri",
      "preview": "Forhåndsvisning",
      "title": "Tittel"
    },
    "image": {
      "label": "Bilder",
      "invalid-file-size": "{frist}: Ugyldig filstørrelse!, filstørrelsen skal være mindre enn {last}",
      "invalid-file-limit": "Maksimalt antall filer er overskredet, grensen er maksimalt {frist}",
      "choose-label": "Legg til bilder",
      "drag-and-drop": "Dra og slipp nye bilder her",
      "upload": "Last opp bilde",
      "manage": "Administrer bilder",
      "description": "Beskrivelse",
      "title": "Tittel",
      "no-images-found": "Ingen bilder funnet",
      "select": "Legg til bilde",
      "clear": "Tøm bilder",
      "supported-formats": "Støttede formater: JPEG, PNG, WebP (Maks størrelse: 10MB)",
      "unnamed-image": "Uten navn",
      "no-description": "Ingen beskrivelse",
      "image-name": "Bildenavn",
      "image-description": "Bildebeskrivelse"
    },
    "video": {
      "label": "Video",
      "add": "Legg til",
      "update": "Endre",
      "name": "Navn",
      "uri": "Uri",
      "description": "Beskrivelse",
      "title": "Tittel",
      "no-videos-found": "Ingen videoer funnet",
      "preview": "Forhåndsvisning",
      "no-preview-available": "Ingen forhåndsvisning tilgjengelig"
    },
    "supplier": {
      "new": "Ny leverandør",
      "error": "Minst en leverandør er påkrevet",
      "label": "Leverandører",
      "no-suppliers-found": "Ingen leverandører funnet",
      "name": "Navn",
      "product-number": "Leverandørs varenummer",
      "currency": "Valuta",
      "cost-price": "Innkjøpspris",
      "freight": "Frakt",
      "customs": "Toll",
      "purchase-price": "Innkjøpspris",
      "replacement-cost": "Gjenkjøpspris",
      "calculated-cost": "Kalkulert kostpris",
      "default-supplier": "Hoved",
      "dialog-header": "Velg leverandør",
      "estimated-delivery": "Leveringstid"
    },
    "price": {
      "new": "Ny prisgruppe",
      "loading": "Laster inn prisgrupper...",
      "label": "Pris",
      "no-price-found": "Ingen prisgruppe funnet",
      "price-group-name": "Prisgruppe",
      "listprice": "Listepris",
      "cost-price": "Innkjøpspris",
      "gross-margin": "Bruttomargin",
      "contribution-margin": "DG(%)",
      "listprice-incl-vat": "Listepris inkl. MVA",
      "listprice-excl-vat": "Listepris eksl. MVA",
      "currency": "Currency",
      "toast": {
        "error": {
          "summary": "Kunne ikke laste inn prisinformasjon",
          "detail": "Bruker standard prisverdier"
        }
      },
      "calculate-error": {
        "summary": "Kunne ikke beregne pris",
        "detail": "Vennligst prøv igjen senere"
      }
    },
    "alternative": {
      "label": "Alternativer",
      "not-found": "Ingen alternativer funnet",
      "number": "Produktnummer",
      "name": "Navn",
      "description": "Beskrivelse",
      "dialog-header": "Velg alternative products"
    },
    "accessory": {
      "label": "Tilbehør",
      "no-accessory-found": "Ingen tilbehør funnet",
      "number": "Produktnummer",
      "name": "Navn",
      "description": "Beskrivelse",
      "dialog-header": "Velg tilbehør produkter"
    },
    "attribute": {
      "attribute": "Attributt",
      "attribute-values": "Attributtverdier",
      "label": "Attributter",
      "loading": "Laster inn attributter...",
      "no-attributes-found": "Ingen atributter funnet | Ingen verdier funnet for attributt",
      "select": "Velg en attributt | Velg verdi",
      "name": "Navn",
      "description": "Beskrivelse",
      "values": "Verdier",
      "value": "Verdi",
      "state": "Aktiv",
      "manage-attributes": "Administrer attributter",
      "no-attributes-available": "Ingen attributter tilgjengelig"
    },
    "client": {
      "error": "Minst en klient er påkrevet",
      "dialog-header": "Velg klienter",
      "name": "Navn",
      "label": "Klienter",
      "no-client-found": "Ingen klienter funnet"
    },
    "manufacturer": {
      "label": "Produsent",
      "loading": "Laster inn produsenter...",
      "no-manufacturer-found": "Ingen produsent funnet",
      "active": "Aktive",
      "inactive": "Inactive",
      "add-logo": "Legg til logo",
      "select": "Velg produsent",
      "name": "Navn",
      "description": "Beskrivelse",
      "website-url": "Nettside-URL",
      "status": "Status",
      "logo": "Logo",
      "toast": {
        "success-add": {
          "summary": "Ny produsent ble lagt til!",
          "detail": "Produsent navn {name}"
        },
        "success-update": {
          "summary": "Produsent lagres!",
          "detail": "Produsent navn {name}"
        },
        "success-deleted": {
          "summary": "Produsent er slettet!",
          "detail": "Produsent navn {name}"
        },
        "error": {
          "summary": "Feil ved lagring av produsent",
          "detail": "Vennligst prøv igjen senere"
        }
      }
    },
    "tax": {
      "dialog-header": "Velg moms",
      "edit-tax": "Endre moms",
      "default-selected": "Default Selected",
      "default": "Default"
    },
    "toast": {
      "success-add": {
        "summary": "Nytt produkt ble lagt til",
        "detail": "Produktnavn {name}"
      },
      "success-delete": {
        "summary": "Produktet ble slettet!",
        "detail": "Produktnavn {name}"
      },
      "success-edit": {
        "summary": "Produkt lagres!",
        "detail": "Produkt navn {name}"
      },
      "warn": {
        "summary": "Validering feilet",
        "detail": "Rett alle rød felter og prøv igjen"
      },
      "error-delete": {
        "summary": "Kunne ikke slette produktet",
        "detail": "Vennligst prøv igjen senere"
      },
      "error-edit": {
        "summary": "Feil ved lagring av produkt",
        "detail": "Server feil"
      },
      "reload": {
        "summary": "Laster inn produktet på nytt"
      },
      "open-product-failed": "Kunne ikke åpne produkt"
    },
    "dimension-unit": {
      "m": "m",
      "dm": "dm",
      "cm": "cm",
      "mm": "mm"
    },
    "weight-unit": {
      "t": "t",
      "kg": "kg",
      "g": "g",
      "mg": "mg"
    },
    "list": {
      "title": "Produkter",
      "no-items": "Ingen produkter funnet",
      "loading-items": "Laster inn produkter...",
      "column": {
        "no": "Produktnr.",
        "active-on-webshop": "Active on webshop",
        "name": "Navn"
      }
    },
    "search": {
      "search-label": "Produktsøk:",
      "total-hits": "Ingen produkter funnet | 1 produkt funnet | {totalHits} produkter funnet",
      "add-btn": "Nytt produkt",
      "advanced-btn": "Avansert",
      "select-all": "Velg alle",
      "delete-selected": "Slett valgte",
      "edit-columns": "Tilpass kolonner",
      "header-image": "Hovedbilde",
      "header-product-number": "Produktnr.",
      "header-gtin": "EAN",
      "header-name": "Navn",
      "header-description": "Beskrivelse",
      "header-product-info": "Produktinfo",
      "search-error": "Søk feilet",
      "loading-items": "Laster inn produkter...",
      "product-status": {
        "active": "Aktiv",
        "inactive": "Inaktiv",
        "discontinued": "Utgått"
      }
    },
    "search-filters": {
      "filter": "Filter",
      "header": "Produktfilter",
      "clear": "Tøm filter",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "status": "Status",
      "manufacturer": "Produsent",
      "product": "Produkt",
      "group-level": "Gruppenivå"
    },
    "panel":{
      "close-panels": "Lukk faner",
      "open-panels":"Åpne faner"
    },
    "variants": {
      "label": "Varianter",
      "max-attributes-reached": "Maksimum {maximum} attributter nådd",
      "preview": "Forhåndsvisning",
      "total": "Totale Varianter",
      "attributes": "Attributter",
      "base-product": "Baseprodukt",
      "values": "verdier",
      "select-values": "Velg verdier",
      "selected-variants": "Valgte varianter: {count}",
      "set-new-images": "Sett nye bilder",
      "unknown-attribute": "Ukjent attributt",
      "no-variants-configured": {
        "header": "Ingen varianter konfigurert",
        "description": "Det er ingen registrerte varianter på dette produktet.",
        "add-product": "Legg til et nytt produkt",
        "to-create-new-variant": "for å legge til en ny variant."
      },
      "product-variants": {
        "header": "Produktvarianter",
        "sub-header": "Administrer alle variasjoner av dette produktet",
        "variant-attributes": "Variantattributter",
        "status": "Status",
        "no-variants": "Ingen varianter funnet. Opprett din første variant for å komme i gang."
      },
      "variant-details": {
        "header": "Variantdetaljer",
        "sub-header": "Dette er en variant av følgende produkt:",
        "view-base-product": "Vis grunnprodukt",
        "base-product-tag": "Grunnprodukt"
      }
    }
  }
}
