import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { NewManufacturer } from "@/repositories/manufacturer/model/NewManufacturer";
import { UpdateManufacturer } from "@/repositories/manufacturer/model/UpdateManufacturer";

class ManufacturerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/manufacturers`
        : `${import.meta.env.VITE_APP_API_URL as string}/manufacturers`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Manufacturer[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }: AxiosResponse) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Manufacturer> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }: AxiosResponse) => data);
  }

  public async create(authHeaders: AuthHeaders, manufacturer: NewManufacturer): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", manufacturer);
  }

  public async update(authHeaders: AuthHeaders, manufacturer: UpdateManufacturer): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", manufacturer);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

const manufacturerApi = new ManufacturerApi();
export { manufacturerApi };
