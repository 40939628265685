<template>
  <div class="flex flex-col-reverse sm:flex-row sm:justify-between items-center gap-4 px-4 py-3 bg-gray-50">
    <PrimeButton
      v-if="isEditing"
      :label="t('common.delete')"
      data-testid="delete-btn"
      @click="emit('deleteClicked', $event)"
      class="w-full sm:w-auto mt-2 sm:mt-0"
      severity="danger"
    />
    <PrimeButton
      :label="t(`common.cancel`)"
      data-testid="cancel-btn"
      @click="emit('closeClicked')"
      class="w-full sm:w-auto mt-2 sm:mt-0"
      severity="cancel"
      text
      pt:label:class="font-bold"
      icon="pi pi-times"
    />
    <PrimeButton
      :label="isEditing ? t('common.save') : t('common.add')"
      data-testid="btn-commit"
      @click="emit('saveClicked')"
      icon="pi pi-check"
      :loading="isSaving"
      class="w-full sm:w-auto"
    />
  </div>

  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToAttributeList')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();

defineProps<{
  isEditing: boolean;
  isSaving: boolean;
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "closeClicked"): void;
  (e: "deleteClicked", value: Event): void;
  (e: "stayOnPage"): void;
  (e: "routeToAttributeList"): void;
}>();
</script>
