<template>
  <label for="attribute-state">{{ t("attribute.state") }}</label>
  <Select
    id="attribute-state"
    v-model="attributeState"
    class="inputfield w-full"
    :options="Object.keys(AttributeState)"
    :data-testid="`attribute-state-${languageIso}`"
    :pt:list:data-testid="`attribute-state-list-${languageIso}`"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AttributeState } from "@/models/attribute/AttributeState";

defineProps<{
  languageIso: string;
}>();

const attributeState = defineModel<AttributeState>("attributeState", {
  required: true,
});

const { t } = useI18n();
</script>
