import { ProductHierarchySearchFilters } from "./ProductHierarchySearchFilters";

export class ProductHierarchySearchRequest {
  query = "";
  page = 1;
  pageSize = 100;
  filters = new ProductHierarchySearchFilters();

  constructor(query: string) {
    this.query = query;
  }
}
