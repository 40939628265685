<template>
  <div class="grid grid-cols-12 gap-4 c-datatable-expand">
    <div class="col-span-2 c-expanded-image">
      <img v-if="product.productImages.length > 0" :src="resizeImage(product.productImages[0].url, 200, 200)" />
    </div>
    <div class="col-span-4" v-html="product.productInfo"></div>
    <div class="col-span-6">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-4"><b>GTIN:</b><br />{{ product.gtin }}</div>
        <div class="col-span-4"><b>Gruppenivå:</b></div>
        <div class="col-span-4"><b>Leverandør:</b><br />{{ product.manufacturerId }}</div>
        <div class="col-span-4"><b>Mål:</b></div>
        <div class="col-span-4"><b>Vekt:</b></div>
        <div class="col-span-4"><b>Lager:</b></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Product } from "@/repositories/product/model/Product";
import { useImageService } from "@/repositories/image/ImageService";

const { resizeImage } = useImageService();

defineProps<{
  product: Product;
}>();
</script>

<style lang="scss" scoped>
.c-expanded-image img {
  width: 200px;
  max-width: 100%;
  height: auto;
  background: var(--surface-a);
}
</style>
