<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <label class="block text-xs font-medium text-gray-700">
        {{
          t("product.import.set-default-field", {
            field: t("product.import.system-fields.productgroupnamelevel1").toLowerCase(),
          })
        }}
      </label>
    </div>

    <div v-if="loading" class="flex items-center justify-center py-2">
      <ProgressSpinner style="width: 20px; height: 20px" />
      <span class="ml-2 text-sm text-gray-600">{{ t("common.loading") }}</span>
    </div>

    <div v-else>
      <Select
        v-model="selectedGroup"
        :options="productGroupOptions"
        option-label="name"
        class="w-full"
        :placeholder="
          t('placeholder.select', {
            property: t('product.import.system-fields.productgroupnamelevel1').toLowerCase(),
          })
        "
        @change="applySelection"
      />

      <div class="flex justify-end mt-3">
        <Button
          :label="t('common.clear')"
          size="small"
          severity="secondary"
          :aria-label="t('common.clear')"
          @click="clearSelection"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import type { Reference } from "@/product/models/ImportOptions";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import type { LanguageText } from "@/models/language/LanguageText";
import { i18n } from "@/locales/i18n";
import type { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const loading = ref(true);
const selectedGroup = ref<Reference | null>(null);
const productGroupNames = ref<ProductGroupName[]>([]);

const referenceStore = useProductImportReferenceStore();
const optionsStore = useProductImportOptionsStore();
const { clearMappingForColumn } = useProductImportSessionStore();
const { importOptions } = storeToRefs(optionsStore);
const { t } = useI18n();
const locale = ref(i18n.global.locale);

onMounted(async () => {
  try {
    productGroupNames.value = await referenceStore.getProductGroups();

    selectedGroup.value = importOptions.value.defaultValues.productGroupLevel1;
  } finally {
    loading.value = false;
  }
});

const getLocalizedText = (names: LanguageText[]): string => {
  const localizedText = names.find((n) => n.languageIso === locale.value)?.text;
  if (localizedText) return localizedText;

  const defaultText = names.find((n) => n.languageIso === "en-GB")?.text;
  if (defaultText) return defaultText;

  return names[0]?.text ?? "";
};

const productGroupOptions = computed(() =>
  productGroupNames.value.map((group) => ({
    id: group.id,
    name: getLocalizedText(group.names),
  })),
);

const clearSelection = () => {
  selectedGroup.value = null;
  optionsStore.setDefaultProductGroup(null);
};

const applySelection = () => {
  optionsStore.setDefaultProductGroup(selectedGroup.value);
  clearMappingForColumn("productGroupNameLevel1");
};
</script>
