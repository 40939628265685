<template>
  <FloatLabelDropdownPanel
    :selectedItemName="selectedProductGroupName"
    :label="label"
    :selectLabel="t('placeholder.select', { property: t('product.product-group.group-level').toLowerCase() })"
    @toggleDropdownPanel="toggleProductGroupSearch"
    :data-testid="`c-product-group-dropdown-${level}`"
  />
  <div v-show="showProductGroupSearchList" class="c-product-group-dropdown">
    <GroupLevelSearchList
      ref="groupLevelSearchListRef"
      :productGroupNames="productGroupNames"
      :productHierarchies="productHierarchies"
      :loading="loading"
      :level="level"
      @productGroupSelected="onProductGroupSelected"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import GroupLevelSearchList from "./GroupLevelSearchList.vue";
import { ProductGroupNameByLanguage } from "@/product-hierarchy/model/ProductGroupNameByLanguage";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";
import { useI18n } from "vue-i18n";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";

import { LanguageIsoType } from "@/models/language/LanguageIsoType";

const props = defineProps<{
  label: string;
  selectedLevelId: string;
  productGroupNames: ProductGroupNameByLanguage[];
  level: number;
  selectedGroupLevelInfo: string[] | undefined;
  groupLevels: ProductGroupName[] | undefined;
  languageIso: LanguageIsoType;
}>();

const emit = defineEmits<{
  (event: "productGroupSelected", data: ProductGroupNameByLanguage): void;
  (event: "update:selectedGroupLevelInfo", data: string[]): void;
}>();

const { t } = useI18n();
const loading = ref(false);
const productHierarchies = ref<ProductHierarchy[]>([]);
const showProductGroupSearchList = ref<boolean>(false);
const groupLevelSearchListRef = ref();

const selectedProductGroupName = computed<string>(() => {
  const groupLevel = props.groupLevels?.find((productGroup) => productGroup.id === props.selectedLevelId);
  const nameByLanguage = groupLevel?.names.find((name) => name.languageIso === props.languageIso)?.text;
  return nameByLanguage ?? "";
});

const toggleProductGroupSearch = () => {
  showProductGroupSearchList.value = !showProductGroupSearchList.value;
  if (showProductGroupSearchList.value) {
    nextTick(() => {
      groupLevelSearchListRef.value.focusSearchInput();
    });
  }
};

const onProductGroupSelected = (data: ProductGroupNameByLanguage) => {
  showProductGroupSearchList.value = false;
  emit("update:selectedGroupLevelInfo", [data.id]);
  emit("productGroupSelected", data);
};
</script>
