<template>
  <div class="flex flex-col col-span-12 px-6 md:p-0 md:px-8">
    <label class="col-span-12">{{ t("product.dimension.label") }}</label>
    <div class="mt-2">
      <InputGroup>
        <FloatLabel variant="on">
          <InputNumber
            id="product-dimension-height"
            v-model="dimensionHeightComputed"
            v-tooltip.focus.bottom="{
              value: t('placeholder.type', { property: t('product.dimension.height').toLowerCase() }),
              showDelay: 1000,
              hideDelay: 300,
            }"
            data-testid="product-dimension-height"
            :allowEmpty="false"
            class="py-0"
            :maxFractionDigits="5"
          />
          <label for="product-dimension-height">{{ t("product.dimension.height") }}</label>
        </FloatLabel>
        <InputGroupAddon>
          <Button
            :label="dimensionUnitLabelComputed"
            data-testid="product-dimension-height-btn"
            class="w-full bg-transparent text-black"
            text
            @click="toggleDimensionMenu"
        /></InputGroupAddon>
      </InputGroup>
    </div>

    <div class="mt-2">
      <InputGroup>
        <FloatLabel variant="on">
          <InputNumber
            id="product-dimension-width"
            v-model="dimensionWidthComputed"
            v-tooltip.focus.bottom="{
              value: t('placeholder.type', { property: t('product.dimension.width').toLowerCase() }),
              showDelay: 1000,
              hideDelay: 300,
            }"
            data-testid="product-dimension-width"
            :allowEmpty="false"
            class="py-0"
            :maxFractionDigits="5"
          />
          <label for="product-dimension-width">{{ t("product.dimension.width") }}</label>
        </FloatLabel>
        <InputGroupAddon>
          <Button
            :label="dimensionUnitLabelComputed"
            data-testid="product-dimension-width-btn"
            class="w-full bg-transparent text-black"
            text
            @click="toggleDimensionMenu"
        /></InputGroupAddon>
      </InputGroup>
    </div>

    <div class="mt-2">
      <InputGroup>
        <FloatLabel variant="on">
          <InputNumber
            id="product-dimension-length"
            v-model="dimensionLengthComputed"
            v-tooltip.focus.bottom="{
              value: t('placeholder.type', { property: t('product.dimension.length').toLowerCase() }),
              showDelay: 1000,
              hideDelay: 300,
            }"
            data-testid="product-dimension-length"
            :allowEmpty="false"
            :maxFractionDigits="5"
            class="py-0"
          />
          <label for="product-dimension-length">{{ t("product.dimension.length") }}</label>
        </FloatLabel>
        <InputGroupAddon>
          <Button
            :label="dimensionUnitLabelComputed"
            data-testid="product-dimension-length-btn"
            class="w-full bg-transparent text-black"
            text
            @click="toggleDimensionMenu"
        /></InputGroupAddon>
      </InputGroup>
    </div>
    <div class="mt-2">
      <InputGroup>
        <FloatLabel variant="on">
          <InputNumber
            id="product-weight"
            v-model="weightValueComputed"
            v-tooltip.focus.bottom="{
              value: t('placeholder.type', { property: t('product.dimension.weight').toLowerCase() }),
              showDelay: 1000,
              hideDelay: 300,
            }"
            data-testid="product-weight"
            :allowEmpty="false"
            :maxFractionDigits="5"
            class="py-0"
          />
          <label for="product-weight">{{ t("product.dimension.weight") }}</label>
        </FloatLabel>
        <InputGroupAddon>
          <Button
            :label="weightUnitLabelComputed"
            data-testid="product-weight-unit-btn"
            class="w-full bg-transparent text-black"
            text
            @click="toggleWeightMenu"
        /></InputGroupAddon>
      </InputGroup>
    </div>

    <Menu id="dimension_menu" ref="dimensionMenu" :model="dimensionItems" :popup="true" />
    <Menu id="weight_menu" ref="weightMenu" :model="weightItems" :popup="true" />

    <!-- Hack for PrimeVue 4 - Css for Input Group -->
    <InputGroup style="display: none"> <InputGroupAddon></InputGroupAddon><InputText placeholder="" /> </InputGroup>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { Dimension } from "@/repositories/product/model/Dimension";
import { UnitOfSize } from "@/repositories/product/model/UnitOfSize";
import { UnitOfWeight } from "@/repositories/product/model/UnitOfWeight";
import { Weight } from "@/repositories/product/model/Weight";
import cloneDeep from "lodash.clonedeep";

const { t } = useI18n();
const dimensionMenu = ref();
const weightMenu = ref();

const props = defineProps<{
  dimension: Dimension | null;
  weight: Weight | null;
}>();

const emit = defineEmits<{
  (e: "update:dimension", value: Dimension): void;
  (e: "update:weight", value: Weight | null): void;
}>();

const defaultDimensionUnit = UnitOfSize.M;
const copyDimensionProp = () => {
  return props.dimension !== null
    ? cloneDeep(props.dimension)
    : ({
        unitOfSize: defaultDimensionUnit,
        length: 0,
        width: 0,
        height: 0,
      } as Dimension);
};

const defaultWeightUnit = UnitOfWeight.Kg;
const copyWeightProp = () => {
  return props.weight !== null
    ? cloneDeep(props.weight)
    : ({
        unitOfWeight: defaultWeightUnit,
        value: 0,
      } as Weight);
};

const dimensionUnitLabelComputed = computed(() => {
  return props.dimension ? props.dimension.unitOfSize.toLowerCase() : defaultDimensionUnit.toLowerCase();
});

const weightUnitLabelComputed = computed(() => {
  return props.weight ? props.weight.unitOfWeight.toLowerCase() : defaultWeightUnit.toLowerCase();
});

const dimensionHeightComputed = computed({
  get: () => {
    return props.dimension?.height ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.height = value;
    emit("update:dimension", dimensionCopy);
  },
});

const dimensionWidthComputed = computed({
  get: () => {
    return props.dimension?.width ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.width = value;
    emit("update:dimension", dimensionCopy);
  },
});

const dimensionLengthComputed = computed({
  get: () => {
    return props.dimension?.length ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.length = value;
    emit("update:dimension", dimensionCopy);
  },
});

const weightValueComputed = computed({
  get: () => {
    return props.weight?.value ?? 0;
  },
  set: (value) => {
    const weightCopy = copyWeightProp();
    weightCopy.value = value;
    emit("update:weight", weightCopy);
  },
});

const dimensionItems = ref([
  {
    label: t("common.options"),
    items: Object.values(UnitOfSize).map((unit) => {
      return {
        label: unit.toLowerCase(),
        icon: "pi pi-circle",
        command: () => {
          onSelectedUnit(unit);
        },
      };
    }),
  },
]);

const weightItems = ref([
  {
    label: t("common.options"),
    items: Object.values(UnitOfWeight).map((unit) => {
      return {
        label: unit.toLowerCase(),
        icon: "pi pi-circle",
        command: () => {
          onSelectedWeight(unit);
        },
      };
    }),
  },
]);

const onSelectedUnit = (selectedDimensionUnit: UnitOfSize) => {
  const dimensionCopy = copyDimensionProp();
  dimensionCopy.unitOfSize = selectedDimensionUnit;
  emit("update:dimension", dimensionCopy);
};

const onSelectedWeight = (selectedWeightUnit: UnitOfWeight) => {
  const weightCopy = copyWeightProp();
  weightCopy.unitOfWeight = selectedWeightUnit;
  emit("update:weight", weightCopy);
};

const toggleDimensionMenu = (event: Event) => {
  if (dimensionMenu.value) {
    dimensionMenu.value.toggle(event);
  }
};

const toggleWeightMenu = (event: Event) => {
  if (weightMenu.value) {
    weightMenu.value.toggle(event);
  }
};
</script>
