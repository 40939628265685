import { NIL as emptyUuid } from "uuid";

export class AIPrompt {
  id = emptyUuid;
  companyId = "";
  promptTitle = "";
  productTitle = "";
  prompt = "";
  date = new Date().toJSON();
  documentType = "";

  public static createNewAiPromp(prompt: AIPrompt): AIPrompt {
    const newPrompt = new AIPrompt();
    newPrompt.promptTitle = prompt.promptTitle;
    newPrompt.productTitle = prompt.productTitle;
    newPrompt.prompt = prompt.prompt;
    newPrompt.date = prompt.date;
    return newPrompt;
  }
}
