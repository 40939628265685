<template>
  <label for="manufacturer-websiteUrl" :class="{ 'c-focused': isInputFocused }">
    {{ t("product.manufacturer.website-url") }}
  </label>

  <InputText
    v-model="manufacturerWebsiteUrlComputed"
    type="text"
    class="inputfield w-full"
    maxlength="500"
    :placeholder="t('placeholder.type', { property: t('product.manufacturer.website-url').toLowerCase() })"
    data-testid="manufacturer-url"
    @focus="selectAllOnFocus"
    @blur="isInputFocused = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { useI18n } from "vue-i18n";

const props = defineProps<{
  manufacturerWebsiteUrl: string;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerWebsiteUrl", value: string): void;
}>();

const { t } = useI18n();
const isInputFocused = ref(false);

const manufacturerWebsiteUrlComputed = computed<string>({
  get() {
    return props.manufacturerWebsiteUrl;
  },
  set(value: string) {
    emit("update:manufacturerWebsiteUrl", value);
  },
});

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
  isInputFocused.value = true;
};
</script>
