<template>
  <PrimeDialog
    v-model:visible="visible"
    :closable="false"
    :header="t('product-hierarchy-dialog.edit-dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    :style="{ width: '25vw' }"
    @show="onShow"
    @keydown.esc="closeDialog"
    class="c-product-hierarchy-dialog-dialog"
  >
    <div class="c-product-hierarchy-dialog-content mt-6">
      <div class="flex overflow-hidden items-center">
        <div class="flex-none items-center px-8">
          <label for="level-1">
            {{ t(`product-hierarchy-dialog.label-level-1`) }}
          </label>
        </div>
        <div class="grow">
          <Select
            id="level-1"
            :options="productGroupNamesComputed"
            optionLabel="name"
            optionValue="id"
            data-testid="level-1"
            v-model="selectedLevel1Id"
            class="w-full p-inputwrapper-focus"
            :placeholder="t('product-hierarchy-dialog.select-level')"
            :class="{ 'p-invalid': val.selectedLevel1Id.$error }"
            :loading="loading"
            ref="level1Ref"
            pt:list:data-testid="level-1-list"
          />

          <small class="p-error" v-if="val.$error" data-testid="level1-error">
            {{ val.$errors[0].$message }}
          </small>
        </div>
      </div>

      <div class="flex overflow-hidden items-center mt-4">
        <div class="flex-none items-center px-8">
          <label for="level-2">
            {{ t(`product-hierarchy-dialog.label-level-2`) }}
          </label>
        </div>
        <div class="grow">
          <Select
            id="level-2"
            :options="productGroupNamesComputed"
            optionLabel="name"
            optionValue="id"
            data-testid="level-2"
            v-model="selectedLevel2Id"
            class="w-full"
            :placeholder="t('product-hierarchy-dialog.select-level')"
            :loading="loading"
            pt:list:data-testid="level-2-list"
          />
        </div>
      </div>

      <div class="flex overflow-hidden items-center mt-4">
        <div class="flex-none items-center px-8">
          <label for="level-3">
            {{ t(`product-hierarchy-dialog.label-level-3`) }}
          </label>
        </div>
        <div class="grow">
          <Select
            id="level-3"
            :options="productGroupNamesComputed"
            optionLabel="name"
            optionValue="id"
            data-testid="level-3"
            v-model="selectedLevel3Id"
            class="w-full"
            :placeholder="t('product-hierarchy-dialog.select-level')"
            :loading="loading"
            pt:list:data-testid="level-3-list"
          />
        </div>
      </div>

      <div v-if="levels > 0" class="flex overflow-hidden items-center mt-4">
        <div class="flex-none items-center px-8">
          <label for="level-4">
            {{ t(`product-hierarchy-dialog.label-level-4`) }}
          </label>
        </div>
        <div class="grow">
          <Select
            id="level-4"
            :options="productGroupNamesComputed"
            optionLabel="name"
            optionValue="id"
            data-testid="level-4"
            v-model="selectedLevel4Id"
            class="w-full"
            :placeholder="t('product-hierarchy-dialog.select-level')"
            :loading="loading"
            pt:list:data-testid="level-4-list"
          />
        </div>
      </div>

      <div v-if="levels > 1" class="flex overflow-hidden items-center mt-4">
        <div class="flex-none items-center px-8">
          <label for="level-5">
            {{ t(`product-hierarchy-dialog.label-level-5`) }}
          </label>
        </div>
        <div class="grow">
          <Select
            id="level-5"
            :options="productGroupNamesComputed"
            optionLabel="name"
            optionValue="id"
            data-testid="level-5"
            v-model="selectedLevel5Id"
            class="w-full"
            :placeholder="t('product-hierarchy-dialog.select-level')"
            :loading="loading"
            pt:list:data-testid="level-5-list"
          />
        </div>
      </div>

      <div class="flex justify-center mt-4">
        <PrimeButton
          text
          class="c-circular-button2 mr-4"
          @click="levels++"
          data-testid="btn-edit-more-levels"
          :disabled="levels > 1"
        >
          <i class="pi pi-plus c-default-btn-bg c-circular-icon"></i>
          <span class="px-4">{{ t("product-hierarchy-dialog.button-add-levels") }}</span>
        </PrimeButton>
      </div>
    </div>

    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-dialog-btn"
            @click="closeDialog"
            class="c-dialog-default-button mr-4"
            severity="cancel"
            text
            :pt="{
              label: 'font-bold -mx-6',
            }"
            icon="pi pi-times"
          />
          <PrimeButton
            :label="t('common.save')"
            data-testid="btn-save-product-hierarchy"
            @click="onSave"
            class="c-dialog-default-button c-dialog-success-button"
            icon="pi pi-check"
            :pt="{
              label: '-mx-4',
            }"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, nextTick, ComponentPublicInstance, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useAuth } from "@cumulus/event-bus";
import { ProductHierarchy } from "../model/ProductHierarchy";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";
import { LanguageIsoType } from "../../models/language/LanguageIsoType";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";

const productHierarchyStore = useProductHierarchyStore();
const { loading, productGroupNames } = storeToRefs(productHierarchyStore);

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  productHierarchy: ProductHierarchy;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

const productGroupNamesComputed = computed(() =>
  productGroupNames.value.map((productGroupName) =>
    ProductGroupNameByLanguage.createFromProductGroupName(productGroupName, languageIso.value),
  ),
);

const selectedLevel1Id = ref("");
const selectedLevel2Id = ref("");
const selectedLevel3Id = ref("");
const selectedLevel4Id = ref("");
const selectedLevel5Id = ref("");

onMounted(() => {
  if (props.productHierarchy.level1) {
    selectedLevel1Id.value = props.productHierarchy.level1.id;
  }
  if (props.productHierarchy.level2) {
    selectedLevel2Id.value = props.productHierarchy.level2.id;
  }
  if (props.productHierarchy.level3) {
    selectedLevel3Id.value = props.productHierarchy.level3.id;
  }
  if (props.productHierarchy.level4) {
    selectedLevel4Id.value = props.productHierarchy.level4.id;
    levels.value++;
  }
  if (props.productHierarchy.level5) {
    selectedLevel5Id.value = props.productHierarchy.level5.id;
    levels.value++;
  }
});

const level1Ref = ref<ComponentPublicInstance | null>(null);
const onShow = async () => {
  nextTick(async () => {
    if (level1Ref.value != null) {
      await timeout(200); //Wait for the dialog to be rendered before focus dropdown
      level1Ref.value.$el.focus();
    }
  });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  const productHierarchy = new ProductHierarchy();
  productHierarchy.id = props.productHierarchy.id;
  productHierarchy.companyId = props.productHierarchy.companyId;
  productHierarchy.level1 = getGroup(selectedLevel1Id.value);
  productHierarchy.level2 = getGroup(selectedLevel2Id.value);
  productHierarchy.level3 = getGroup(selectedLevel3Id.value);
  productHierarchy.level4 = getGroup(selectedLevel4Id.value);
  productHierarchy.level5 = getGroup(selectedLevel5Id.value);

  if (
    props.productHierarchy.level1?.id !== productHierarchy.level1?.id ||
    props.productHierarchy.level2?.id !== productHierarchy.level2?.id ||
    props.productHierarchy.level3?.id !== productHierarchy.level3?.id ||
    props.productHierarchy.level4?.id !== productHierarchy.level4?.id ||
    props.productHierarchy.level5?.id !== productHierarchy.level5?.id
  ) {
    const success = await productHierarchyStore.updateProductHierarchy(productHierarchy);

    if (success) {
      closeDialog();
    }
  }
};

const getGroup = (id: string) => {
  if (id === "") {
    return null;
  }

  return productGroupNames.value.find((productGroupName) => productGroupName.id === id) ?? null;
};

const closeDialog = async () => {
  visible.value = false;
};

const levels = ref(0);

const rules = {
  selectedLevel1Id: {
    required,
  },
};

const val = useVuelidate(rules, { selectedLevel1Id });
</script>

<style lang="scss" scoped>
.c-default-btn-bg {
  background-color: var(--success-btn-bg);
  font-size: 8px;
  padding: 0.4rem;
}
</style>
