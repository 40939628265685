import { httpClient, AuthHeaders } from "@cumulus/http";
import { SalesUnit } from "@/repositories/sales-unit/model/SalesUnit";

class SalesUnitApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/sales-units`
        : `${import.meta.env.VITE_APP_API_URL as string}/sales-units`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<SalesUnit[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<SalesUnit> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, salesUnit: SalesUnit): Promise<SalesUnit> {
    return await httpClient(this.uri, authHeaders)
      .post("/", salesUnit)
      .then(({ data }) => data);
  }

  public async update(authHeaders: AuthHeaders, salesUnit: SalesUnit): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", salesUnit);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

const salesUnitApi = new SalesUnitApi();
export { salesUnitApi };
