import { Price } from "./Price";
import { ProductAttribute } from "./ProductAttribute";
import { ProductImage } from "./ProductImage";
import { v4 as uuidv4 } from "uuid";
import { SupplierPrice } from "./SupplierPrice";

export class VariantProduct {
  id = uuidv4();
  productImages: ProductImage[] = [];
  productNumber: string = "";
  gtin: string = "";
  defaultSupplierPrice: SupplierPrice = new SupplierPrice();
  name: string = "";
  variantAttributes: ProductAttribute[] = [];
  prices: Price[] = [];
}
