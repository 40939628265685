<template>
  <div
    v-for="level in numberOfLevelsUsed"
    :key="level"
    class="col-span-12"
    :data-testid="`c-product-group-level-${level}`"
  >
    <SelectGroupLevel
      :label="t(`product.product-group.level-${level}`)"
      :selectedLevelId="getGroupLevelIdForSelectedLevel(level)"
      :productGroupNames="getOptionsForLevel(level, languageIso)"
      :level="level"
      @productGroupSelected="setGroupForLevel(level, $event)"
      :groupLevels="groupLevels"
      :selectedGroupLevelInfo="selectedGroupLevelInfo"
      @update:selectedGroupLevelInfo="updateSelectedGroupLevelInfo"
      :languageIso="languageIso"
    />
  </div>
</template>

<script setup lang="ts">
import SelectGroupLevel from "./SelectGroupLevel.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { useProductGroupFunctions } from "@/utils/productGroupFunctions";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { useAuth } from "@cumulus/event-bus";
import { GroupLevelInfo } from "@/product-hierarchy/model/GroupLevelInfo";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";

const { t } = useI18n();
const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

const productHierarchies = defineModel<ProductHierarchy[]>("productHierarchies");

const groupLevelInfo = ref<GroupLevelInfo>(new GroupLevelInfo());
const { numberOfLevelsUsed, setGroupForLevel, getGroupLevelIdForSelectedLevel, getOptionsForLevel } =
  useProductGroupFunctions(productHierarchies, groupLevelInfo);

const props = defineProps<{
  selectedGroupLevelInfo: string[];
  groupLevels: ProductGroupName[];
}>();

const emit = defineEmits<{
  (event: "update:selectedGroupLevelInfo", value: string[]): void;
}>();

const updateSelectedGroupLevelInfo = (newVal: string[]) => {
  const updatedInfo = [...props.selectedGroupLevelInfo, ...newVal];
  emit("update:selectedGroupLevelInfo", updatedInfo);
};

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});
</script>
