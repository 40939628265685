<template>
  <FloatLabelInput
    :inputType="'text'"
    :label="t('product.gti-number')"
    v-model:value="gtiNumberComputed"
    :maxlength="8000"
    :placeholder="t('placeholder.type', { property: t('product.gti-number').toLowerCase() })"
    dataTestId="product-gti-number"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  gtin: string | null;
}>();

const emit = defineEmits<{
  (e: "update:gtin", value: string): void;
}>();

const gtiNumberComputed = computed<string>({
  get: () => {
    return props.gtin ?? "";
  },
  set: (value) => {
    emit("update:gtin", value);
  },
});
</script>
