import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { Manufacturer } from "./Manufacturer";

export class UpdateManufacturer {
  imagesToDelete: ImageInfo[] = [];
  manufacturer: Manufacturer = new Manufacturer();

  public static createUpdateManufacturer(manufacturer: Manufacturer): UpdateManufacturer {
    const updateManufacturer = new UpdateManufacturer();
    updateManufacturer.manufacturer = manufacturer;
    return updateManufacturer;
  }
}
