<template>
  <div class="place-items-center mr-10">
    <div class="mb-8 w-full">
      <FloatLabel variant="on">
        <Select
          id="ai-method"
          v-model="selectedAiMethod"
          :options="dropdownOptions"
          data-testid="ai-method"
          optionLabel="name"
          optionValue="value"
          class="inputfield w-full"
          :disabled="editable"
        />
        <label for="ai-method">{{ t("product.ai-search.method.label") }}</label>
      </FloatLabel>
    </div>
    <div class="w-full">
      <FloatLabel variant="on">
        <PrimeTextarea
          v-model="selectedReferance"
          id="ai-product-referance"
          data-testid="ai-product-referance"
          :autoResize="true"
          rows="1"
          cols="30"
          :type="inputType"
          class="inputfield w-full py-2"
          :maxlength="1000"
          :isRequired="true"
          autofocus="true"
          v-tooltip.focus.bottom="{
            value: placeholderText,
            showDelay: 1000,
            hideDelay: 300,
          }"
          :disabled="editable"
        />
        <label for="ai-product-referance">{{ t("product.ai-search.referanse.label") }}</label>
      </FloatLabel>
    </div>
  </div>
  <small class="p-error ml-8" v-if="val.referance.$error" data-testid="ai-product-referance-error">
    {{ val.referance.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
import { AIMethods } from "@/models/product-info/AIMethods";

const { t } = useI18n();
const selectedReferance = defineModel<string>("referance");
const selectedAiMethod = defineModel<string>("aiMethod");

defineProps<{
  editable: boolean;
}>();

const dropdownOptions = computed(() => {
  const aiMethods = Object.values(AIMethods);
  return aiMethods.map((method) => {
    return {
      name: t(`product.ai-search.method.${method.toLowerCase()}`),
      value: method,
    };
  });
});

const placeholderText = computed(() => {
  return selectedAiMethod.value === AIMethods.ProductInfoFromLink
    ? t("product.ai-search.referanse.product-link")
    : t("product.ai-search.referanse.product-number");
});

const inputType = computed(() => {
  return selectedAiMethod.value === AIMethods.ProductInfoFromLink ? "url" : "text";
});

const rules = computed(() => {
  return {
    referance: {
      required,
      ...(selectedAiMethod.value === AIMethods.ProductInfoFromLink && { url }),
    },
  };
});

const val = useVuelidate(rules, { referance: selectedReferance });

watch(selectedAiMethod, () => {
  val.value.$touch();
  val.value.$validate();
});
</script>
