export interface Reference {
  id: string;
  name: string;
}

export interface TaxReference {
  id: string;
  rate: number;
}

export class DefaultValueMapping {
  name: string | null = null;
  warehouses: Reference[] = [];
  clients: Reference[] = [];
  salesUnit: Reference | null = null;
  productGroupLevel1: Reference | null = null;
  mainSupplier: Reference | null = null;
  priceGroup: Reference | null = null;
  taxOutgoing: TaxReference | null = null;
  productType: string | null = null;
  productStatus: string | null = null;
}

export class ImportOptions {
  overwriteExisting = false;
  createNew = false;
  updateEmpty = false;
  productIdentifier = "";
  defaultValues = new DefaultValueMapping();
}
