<template>
  <div class="c-product-image-upload" :tabindex="0" @keydown="handleKeydown">
    <div class="col-12 md:col-12 lg:col-12 p-0 mb-2">
      <ImageUploader
        :productImages="productImages"
        @imageUploaded="emit('imageAdded', $event)"
        @clearImagesClicked="emit('clearImagesClicked')"
        @openImageManagementModal="openImageManagementModal"
      />
    </div>
  </div>
  <ImageManagementModal
    v-if="imageManagementModal"
    v-model:visible="imageManagementModal"
    :productImages="productImages"
    @addImage="emit('imageAdded', $event)"
    @updateImage="(index, updatedImage) => emit('updateImage', index, updatedImage)"
    @updateImageOrder="emit('updateImageOrder', $event)"
    @deleteImage="emit('imageRemoved', $event)"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import ImageUploader from "./ImageUploader.vue";
import ImageManagementModal from "./ImageManagementModal.vue";

const imageManagementModal = ref(false);
const tabIndexRef = ref(0);

const props = defineProps<{
  productImages: ProductImage[];
}>();

const emit = defineEmits<{
  (e: "imageAdded", value: ProductImage): void;
  (e: "imageRemoved", value: string): void;
  (e: "updateImage", index: number, updatedImage: ProductImage): void;
  (e: "updateImageOrder", value: ProductImage[]): void;
  (e: "openImageModal", value: ProductImage, index: number): void;
  (e: "clearImagesClicked"): void;
}>();

const uploadOnKeyPress = () => {
  const uploadButton = document.getElementById("upload-btn");
  if (uploadButton) {
    uploadButton.focus();
    uploadButton.click();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    event.preventDefault();
    if (props.productImages.length > 0) {
      const titleInput = document.getElementById("image-title-input");
      if (titleInput) {
        titleInput.focus();
      }
    } else {
      uploadOnKeyPress();
    }
  } else if (event.key === "Tab") {
    const titleInput = document.getElementById("image-title-input");
    if (document.activeElement === titleInput) {
      tabIndexRef.value = 0;
    } else {
      tabIndexRef.value = -1;
    }
  }
};

const openImageManagementModal = () => {
  imageManagementModal.value = true;
};
</script>
