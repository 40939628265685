<template>
  <div class="c-chips">
    <ul v-if="hasActiveFilters" class="c-chips-multiple-container">
      <li class="c-chips-token-clear-filter">
        <span>{{ t("product.search-filters.clear") }}</span>
        <span class="c-chips-token-icon pi pi-times-circle" data-testid="clear-filter-btn" @click="clearFilters">
        </span>
      </li>
      <FilterItem
        v-if="filters.productStatuses.length > 0"
        :values="filters.productStatuses.map((status) => t(`product.status.${status.toLowerCase()}`))"
        filterType="productStatuses"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.manufacturers.length > 0"
        :values="manufacturersSelectedComputed"
        filterType="manufacturers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.groupLevels.length > 0"
        :values="groupLevelSelectedComputed"
        filterType="groupLevels"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>
<script setup lang="ts">
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";

import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import FilterItem from "./FilterItem.vue";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";

import { useAuth } from "@cumulus/event-bus";
import { useProductLanguageFunctions } from "@/utils/productLanguageFunctions";

const { t } = useI18n();
const { getUser } = useAuth();
const languageIso = ref<string>("en-GB");
const { transformLanguageCode } = useProductLanguageFunctions();

const props = defineProps<{
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
  groupLevels: ProductGroupName[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: ProductSearchFilters): void;
}>();
const clearFilters = () => {
  const filters = new ProductSearchFilters();
  emit("update:filters", filters);
};

const removeFilter = (filterType: keyof ProductSearchFilters | string, index: number) => {
  const filters = { ...props.filters };

  const filterToRemove = filters[filterType as keyof ProductSearchFilters];
  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  }

  emit("update:filters", filters);
};

const manufacturersSelectedComputed = computed<string[]>(() => {
  return props.filters.manufacturers.map((manufacturerId) => {
    const manufacturer = props.manufacturers.find((m) => m.id === manufacturerId);
    return manufacturer?.name ?? "";
  });
});

const groupLevelSelectedComputed = computed<string[]>(() => {
  return props.filters.groupLevels.flatMap((groupLevelId) => {
    const groupLevel = props.groupLevels.find((m) => m.id === groupLevelId);
    const nameByLanguage = groupLevel?.names.find((name) => name.languageIso === languageIso.value)?.text;
    return nameByLanguage ? [nameByLanguage] : [""];
  });
});

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.productStatuses.length > 0 ||
    props.filters.manufacturers.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.productNames.length > 0 ||
    props.filters.groupLevels.length > 0
  );
});

onMounted(async () => {
  const userLanguage = (await getUser()).getEmployee()?.languageCode ?? "en-GB";
  languageIso.value = transformLanguageCode(userLanguage);
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
