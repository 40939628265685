import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { VariantProduct } from "@/repositories/product/model/VariantProduct";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { Price } from "@/repositories/product/model/Price";
import cloneDeep from "lodash.clonedeep";
import { AttributeType } from "@/models/attribute/AttributeType";

export interface TrackedBaseProductProperties {
  name: string;
  productImages: ProductImage[];
  supplierPrices: SupplierPrice[];
  prices: Price[];
}

export const useVariantStore = defineStore("variants", () => {
  const variants = ref<VariantProduct[]>([]);

  const generateVariants = (
    productAttributes: ProductAttribute[],
    baseProductProps: TrackedBaseProductProperties,
    attributes: AttributeByLanguageIso[],
  ) => {
    const variantCombinations = generateVariantCombinations(productAttributes);

    variants.value = variantCombinations.map((combination) => {
      const variant = new VariantProduct();

      variant.variantAttributes = Object.entries(combination).map(([attributeId, valueId]) => ({
        attributeId,
        attributeValueIds: [valueId],
      }));

      const variantAttributes = variant.variantAttributes
        .map((attr) => {
          const attribute = attributes.find((a) => a.id === attr.attributeId);
          if (attribute?.attributeType === AttributeType.Color) {
            const description = attribute.values.find((v) => v.id === attr.attributeValueIds[0])?.description;
            return description;
          } else {
            const value = attribute?.values.find((v) => v.id === attr.attributeValueIds[0])?.value;
            return value;
          }
        })
        .filter(Boolean)
        .join(" - ");

      variant.name = `${baseProductProps.name} - ${variantAttributes}`;

      const defaultSupplierPrice = cloneDeep(baseProductProps.supplierPrices.find((sp) => sp.isDefaultSupplier));
      if (defaultSupplierPrice) {
        defaultSupplierPrice.productNumber = "";
        variant.defaultSupplierPrice = defaultSupplierPrice;
      }
      variant.prices = cloneDeep(baseProductProps.prices);
      variant.productImages = cloneDeep(baseProductProps.productImages);
      variant.productNumber = "";
      variant.gtin = "";

      return variant;
    });
  };

  const generateVariantCombinations = (productAttributes: ProductAttribute[]) => {
    const validAttributes = productAttributes.filter((attr) => attr.attributeId && attr.attributeValueIds.length > 0);

    if (validAttributes.length === 0) return [];

    const getCombinations = (index: number, current: Record<string, string>): Record<string, string>[] => {
      if (index === validAttributes.length) {
        return [current];
      }

      const attribute = validAttributes[index];
      const combinations: Record<string, string>[] = [];

      for (const valueId of attribute.attributeValueIds) {
        const newCombination = { ...current };
        newCombination[attribute.attributeId] = valueId;
        combinations.push(...getCombinations(index + 1, newCombination));
      }

      return combinations;
    };

    return getCombinations(0, {});
  };

  const updateVariantsFromBaseProduct = (
    baseProductProps: TrackedBaseProductProperties,
    attributes: AttributeByLanguageIso[],
  ) => {
    variants.value = variants.value.map((variant) => {
      const variantAttributes = variant.variantAttributes
        .map((attr) => {
          const attribute = attributes.find((a) => a.id === attr.attributeId);
          if (attribute?.attributeType === AttributeType.Color) {
            const description = attribute.values.find((v) => v.id === attr.attributeValueIds[0])?.description;
            return description;
          } else {
            const value = attribute?.values.find((v) => v.id === attr.attributeValueIds[0])?.value;
            return value;
          }
        })
        .filter(Boolean)
        .join(" - ");

      variant.name = `${baseProductProps.name} - ${variantAttributes}`;

      const defaultSupplierPrice = cloneDeep(baseProductProps.supplierPrices.find((sp) => sp.isDefaultSupplier));
      if (defaultSupplierPrice) {
        defaultSupplierPrice.productNumber = "";
        variant.defaultSupplierPrice = defaultSupplierPrice;
      }

      variant.productImages = cloneDeep(baseProductProps.productImages);
      variant.prices = cloneDeep(baseProductProps.prices);

      return variant;
    });
  };

  const updateBulkVariants = (variantsToUpdate: VariantProduct[]) => {
    variantsToUpdate.forEach((variant) => {
      const index = variants.value.findIndex((v) => v.id === variant.id);
      if (index !== -1) {
        variants.value[index] = variant;
      }
    });
  };

  const totalVariants = computed(() => variants.value.length);

  return {
    variants,
    totalVariants,
    generateVariants,
    updateVariantsFromBaseProduct,
    updateBulkVariants,
  };
});
