<template>
  <div>
    <DataTable
      :value="productHierarchiesComputed"
      dataKey="id"
      class="c-datatable"
      :loading="loading"
      @row-dblclick="onRowDblClick"
      v-model:selection="selectedProductHierarchys"
      :stripedRows="true"
      selectionMode="multiple"
      @row-select="onRowSelected"
      data-testid="product-hierarchy-list"
      tabindex="0"
      v-model:filters="filters"
      filterDisplay="menu"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="75vh"
      sortField="level1"
      :sortOrder="-1"
      :globalFilterFields="['global', 'level1.name', 'level2.name', 'level3.name', 'level4.name', 'level5.name']"
    >
      <template #header>
        <div class="flex justify-between">
          <span class="p-input-icon-left">
            <i class="pi pi-filter" />
            <InputText v-model="filters['global'].value" :placeholder="t('product-hierarchy.filter')" />
          </span>

          <div>
            <div class="flex overflow-hidden">
              <div class="grow flex items-center justify-center">
                <PrimeButton
                  @click.stop="showAddProductHierarchyDialog = true"
                  data-testid="btn-add-product-hierarchy"
                  size="small"
                  class="c-success-button"
                  v-if="!toggleSelectRows"
                >
                  <i class="pi pi-plus c-circular-icon"></i>
                  <span class="px-4">{{ t("product-hierarchy.add") }}</span>
                </PrimeButton>

                <PrimeButton
                  @click.stop="onConfirmDelete(null, $event)"
                  data-testid="btn-delete-hierarchies"
                  size="small"
                  class="c-delete-button w-full"
                  v-if="toggleSelectRows"
                >
                  <i class="pi pi-trash c-circular-icon"></i>
                  <div class="px-4 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                    {{ t("product-hierarchy.delete-selected") }}
                  </div>
                </PrimeButton>
                <PrimeButton
                  icon="pi pi-cog"
                  class="c-hierarchy-cog p-button-rounded p-button-text ml-2"
                  @click.stop="toggleSelectRows = !toggleSelectRows"
                  data-testid="btn-hierarchy-options"
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <Column selectionMode="multiple" headerStyle="width: 3rem" v-if="toggleSelectRows"></Column>

      <Column
        :header="t('product-hierarchy.header-level1')"
        sortable
        field="level1.name"
        v-if="columns.findIndex((c) => c.field == 'level1' && !c.hidden) !== -1"
      >
        <template #body="slotProps">
          <span :data-testid="`product-hierarchy-row-${slotProps.index}-level-1`">
            {{ slotProps.data.level1?.name }}
          </span>
        </template>

        <template #filter="{ filterModel, filterCallback, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="t('product-hierarchy.filter-by', { field: field })"
          />
        </template>
      </Column>

      <Column
        :header="t('product-hierarchy.header-level2')"
        sortable
        field="level2.name"
        v-if="columns.findIndex((c) => c.field == 'level2' && !c.hidden) !== -1"
      >
        <template #body="slotProps">
          <span :data-testid="`product-hierarchy-row-${slotProps.index}-level-2`">
            {{ slotProps.data.level2?.name }}
          </span>
        </template>

        <template #filter="{ filterModel, filterCallback, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="t('product-hierarchy.filter-by', { field: field })"
          />
        </template>
      </Column>

      <Column
        :header="t('product-hierarchy.header-level3')"
        sortable
        field="level3.name"
        v-if="columns.findIndex((c) => c.field == 'level3' && !c.hidden) !== -1"
      >
        <template #body="slotProps">
          <span :data-testid="`product-hierarchy-row-${slotProps.index}-level-3`">
            {{ slotProps.data.level3?.name }}
          </span>
        </template>

        <template #filter="{ filterModel, filterCallback, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="t('product-hierarchy.filter-by', { field: field })"
          />
        </template>
      </Column>

      <Column
        :header="t('product-hierarchy.header-level4')"
        sortable
        field="level4.name"
        v-if="columns.findIndex((c) => c.field == 'level4' && !c.hidden) !== -1"
      >
        <template #body="slotProps">
          <span :data-testid="`product-hierarchy-row-${slotProps.index}-level-4`">
            {{ slotProps.data.level4?.name }}
          </span>
        </template>

        <template #filter="{ filterModel, filterCallback, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="t('product-hierarchy.filter-by', { field: field })"
          />
        </template>
      </Column>

      <Column
        :header="t('product-hierarchy.header-level5')"
        sortable
        field="level5.name"
        v-if="columns.findIndex((c) => c.field == 'level5' && !c.hidden) !== -1"
      >
        <template #body="slotProps">
          <span :data-testid="`product-hierarchy-row-${slotProps.index}-level-5`">
            {{ slotProps.data.level5?.name }}
          </span>
        </template>

        <template #filter="{ filterModel, filterCallback, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="t('product-hierarchy.filter-by', { field: field })"
          />
        </template>
      </Column>

      <Column style="width: 2rem" headerClass="c-td-edit-header" bodyClass="c-td-edit-body">
        <template #body="{ data, index }">
          <div class="flex items-center justify-center w-4rem2 m-2222">
            <div class="flex justify-evenly">
              <PrimeButton
                icon="pi pi-pencil"
                class="c-edit-button p-button-rounded p-button-text mr-2"
                @click.stop="editProductHierarchy(data)"
                :data-testid="`btn-hierarchy-edit-${index}`"
              />
              <PrimeButton
                icon="pi pi-trash"
                class="c-delete-button p-button-rounded p-button-text mr-2"
                @click.stop="onConfirmDelete(data, $event)"
                :data-testid="`btn-hierarchy-delete-${index}`"
              />
            </div>
          </div>
        </template>
      </Column>

      <template #empty>{{ t("product-hierarchy.empty-list") }} </template>
      <template #loading>{{ t("product-hierarchy.loading-list") }}</template>
    </DataTable>

    <AddProductHierarchy
      v-if="showAddProductHierarchyDialog"
      v-model:showDialog="showAddProductHierarchyDialog"
      :selectedProductGroupName="selectedProductGroupNamesComputed"
    />
    <EditProductHierarchy
      v-if="showEditProductHierarchyDialog"
      v-model:showDialog="showEditProductHierarchyDialog"
      :productHierarchy="selectedProductHierarchy"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ref, watch, computed, onMounted } from "vue";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { ProductHierarchy } from "../model/ProductHierarchy";
import { ProductHierarchyByLanguage } from "../model/ProductHierarchyByLanguage";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";
import { FilterMatchMode } from "@primevue/core/api";
import { DataTableColumn } from "../model/DataTableColumn";
import { LanguageIsoType } from "../../models/language/LanguageIsoType";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";
import { useAuth } from "@cumulus/event-bus";
import { useConfirm } from "primevue/useconfirm";

import AddProductHierarchy from "../components/AddProductHierarchy.vue";
import EditProductHierarchy from "../components/EditProductHierarchy.vue";

const { t } = useI18n();

const productHierarchyStore = useProductHierarchyStore();
const { loading, productHierarchies } = storeToRefs(productHierarchyStore);

onMounted(async () => await productHierarchyStore.getProductHierarchies());

const showAddProductHierarchyDialog = ref<boolean>(false);
const showEditProductHierarchyDialog = ref<boolean>(false);
const selectedProductGroupNamesComputed = ref<ProductGroupNameByLanguage>(new ProductGroupNameByLanguage());
const selectedProductHierarchy = ref<ProductHierarchy>(new ProductHierarchy());
const selectedProductHierarchys = ref<ProductHierarchyByLanguage[] | null>(null);

const editProductHierarchy = (productHierarchyByLanguage: ProductHierarchyByLanguage) => {
  const productHierarchy = productHierarchies.value.find(
    (ph: ProductHierarchy) => ph.id === productHierarchyByLanguage.id,
  );

  if (productHierarchy) {
    selectedProductHierarchy.value = productHierarchy;
    showEditProductHierarchyDialog.value = true;
  }
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  editProductHierarchy(event.data as ProductHierarchyByLanguage);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editProductHierarchy(event.data as ProductHierarchyByLanguage);
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  "level1.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  "level2.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  "level3.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  "level4.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  "level5.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const columns = ref<DataTableColumn[]>([
  {
    field: "level1",
    header: t("product-hierarchy.header-level1"),
    filterField: "level1.name",
    hidden: false,
  },
  {
    field: "level2",
    header: t("product-hierarchy.header-level2"),
    filterField: "level2.name",
    hidden: false,
  },
  {
    field: "level3",
    header: t("product-hierarchy.header-level3"),
    filterField: "level3.name",
    hidden: true,
  },
  {
    field: "level4",
    header: t("product-hierarchy.header-level4"),
    filterField: "level4.name",
    hidden: true,
  },
  {
    field: "level5",
    header: t("product-hierarchy.header-level5"),
    filterField: "level5.name",
    hidden: true,
  },
] as DataTableColumn[]);

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

const productHierarchiesComputed = computed(() => {
  return productHierarchies.value.map((productHierarchy) =>
    ProductHierarchyByLanguage.createFromProductHierarchy(productHierarchy, languageIso.value),
  );
});

watch(
  () => productHierarchiesComputed.value,
  () => {
    if (columns.value[4].hidden === false) return;

    //Check if level3, level4 or level5 is used
    productHierarchies.value.forEach((productHierarchy) => {
      if (productHierarchy.level3 !== undefined && productHierarchy.level3 !== null) {
        columns.value[2].hidden = false;
      }

      if (productHierarchy.level4 !== undefined && productHierarchy.level4 !== null) {
        columns.value[3].hidden = false;
      }

      if (productHierarchy.level5 !== undefined && productHierarchy.level5 !== null) {
        columns.value[4].hidden = false;
        return;
      }
    });
  },
);

const toggleSelectRows = ref<boolean>(false);

const confirm = useConfirm();
const onConfirmDelete = (productHierarchyByLanguage: ProductHierarchyByLanguage | null, event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      if (productHierarchyByLanguage) {
        await deleteProductHierarchy(productHierarchyByLanguage);
      } else {
        await deleteSelectedItems();
      }
    },
  });
};

const deleteProductHierarchy = async (productHierarchy: ProductHierarchyByLanguage) => {
  await productHierarchyStore.deleteProductHierarchy(productHierarchy.id);
};

const deleteSelectedItems = async () => {
  selectedProductHierarchys.value?.forEach(async (productHierarchy) => {
    if (productHierarchy?.id) {
      await productHierarchyStore.deleteProductHierarchy(productHierarchy?.id);
    }
  });
};

watch(
  () => toggleSelectRows.value,
  (newValue) => {
    if (!newValue) {
      selectedProductHierarchys.value = null;
    }
  },
);
</script>

<style scoped lang="scss">
.c-product-group-name:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row.p-highlight .p-button.p-button-text {
  color: var(--primary-color-text);
}

.c-edit-button.p-button.p-button-text,
.c-delete-button.p-button.p-button-text {
  visibility: hidden;
}

.c-delete-button.p-button.p-button-text:hover {
  color: var(--delete-btn-bg);
}

.c-edit-button.p-button.p-button-text:hover {
  color: var(--success-btn-bg);
}

.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover,
.p-datatable-hoverable .p-datatable-tbody > tr[data-p-selected="true"] {
  .c-edit-button,
  .c-delete-button {
    visibility: visible;
  }
}

.c-hierarchy-cog.p-button:focus {
  box-shadow: none;
}
:deep(.p-datatable) .p-datatable-header {
  background: none;
  border: none;
}
</style>
