<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="isEditing ? t('common.save') : t('common.add')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    data-testid="account-group-modal"
  >
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <FloatLabelInput
          :inputType="'text'"
          :label="t('product.account-group.name')"
          v-model:value="accountGroup.name"
          id="product-name"
          :placeholder="t('placeholder.type', { property: t('product.account-group.name').toLowerCase() })"
          dataTestId="account-group-name"
          :isRequired="true"
          :maxlength="8000"
        />
        <small class="p-error pl-6" v-if="val.name.$error" data-testid="account-group-name-error">
          {{ val.name.$errors[0].$message }}
        </small>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between">
        <PrimeButton data-testid="account-group-cancel-button" class="c-circular-button mr-4" @click="visible = false">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>

        <PrimeButton
          v-if="isEditing"
          class="c-circular-button mr-4"
          @click="onConfirmDelete"
          data-testid="account-group-delete-button"
          :disabled="isDeleting"
        >
          <ProgressSpinner v-if="isDeleting" class="c-spinner" />
          <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <ConfirmDialog data-testid="account-group-confirm-delete"></ConfirmDialog>

        <PrimeButton
          data-testid="account-group-save-button"
          class="c-circular-button mr-4"
          @click="saveAccountGroup"
          :disabled="isSaving"
        >
          <ProgressSpinner v-if="isSaving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{
            isEditing
              ? t("product.account-group.update-account-group")
              : t("product.account-group.create-account-group")
          }}</span>
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { AccountGroup } from "@/product/models/AccountGroup";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NIL as emptyUuid, v4 as uuidv4 } from "uuid";
import { useAccountGroupStore } from "@/stores/AccountGroupStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useConfirm } from "primevue/useconfirm";

const props = defineProps<{
  showDialog: boolean;
  selectedAccountGroup?: AccountGroup;
}>();

const emit = defineEmits<{
  (e: "accountGroupCreated", value: AccountGroup): void;
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { addAccountGroup, updateAccountGroup, deleteAccountGroup } = useAccountGroupStore();
const { t } = useI18n();
const isSaving = ref(false);
const isDeleting = ref(false);
const isEditing = ref(false);
const toast = useCumulusToast(useToast());
const confirm = useConfirm();

const accountGroup = ref<AccountGroup>(new AccountGroup());

const saveAccountGroup = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  try {
    isSaving.value = true;
    if (isEditing.value) {
      await updateAccountGroup(accountGroup.value);
    } else {
      accountGroup.value.id = uuidv4();
      await addAccountGroup(accountGroup.value);
      emit("accountGroupCreated", accountGroup.value);
    }

    toast.add({
      severity: "success",
      summary: t("product.account-group.account-group-saved"),
      closable: true,
    });

    visible.value = false;
  } finally {
    isSaving.value = false;
  }
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      deleteAccountGroupById();
    },
  });
};

const deleteAccountGroupById = async () => {
  try {
    isDeleting.value = true;
    await deleteAccountGroup(accountGroup.value.id);
    toast.add({
      severity: "success",
      summary: t("product.account-group.account-group-deleted"),
    });
    visible.value = false;
  } finally {
    isDeleting.value = false;
  }
};

onMounted(() => {
  if (props.selectedAccountGroup) {
    accountGroup.value = { ...props.selectedAccountGroup };
  }
  accountGroup.value.id === emptyUuid ? (isEditing.value = false) : (isEditing.value = true);
});

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, accountGroup);
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
</style>
