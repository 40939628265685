<template>
  <div class="space-y-10">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div class="space-y-2">
        <AttributeName
          v-model:attributeName="attributeName"
          :languageIso="languageIso"
          @update:attributeName="emit('setAttributeName', languageIso, $event)"
        />
      </div>
      <div class="space-y-2">
        <AttributeType v-model:attributeType="attribute.type" :languageIso="languageIso" />
      </div>
      <div class="space-y-2">
        <AttributeState v-model:attributeState="attribute.attributeState" :languageIso="languageIso" />
      </div>
    </div>
    <AttributeValues
      :attributeValues="attributeValues"
      :attributeType="attribute.type"
      :languageIso="languageIso"
      @updateValue="emit('setAttributeValue', languageIso, $event)"
      @addValue="emit('addNewAttributeValue')"
      @deleteValue="emit('deleteValue', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import AttributeName from "./AttributeName.vue";
import AttributeType from "./AttributeType.vue";
import AttributeValues from "./AttributeValues.vue";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { Attribute } from "@/models/attribute/Attribute";
import AttributeState from "./AttributeState.vue";

defineProps<{
  languageIso: string;
}>();

const emit = defineEmits<{
  (e: "setAttributeName", languageIso: string, value: string): void;
  (e: "setAttributeValue", languageIso: string, value: AttributeValueByLanguage): void;
  (e: "addNewAttributeValue"): void;
  (e: "deleteValue", value: string): void;
}>();

const attribute = defineModel<Attribute>("attribute", {
  required: true,
});

const attributeName = defineModel<string>("attributeName", {
  required: true,
});

const attributeValues = defineModel<AttributeValueByLanguage[]>("attributeValues", {
  required: true,
});
</script>
