<template>
  <teleport v-if="isMounted" to="#teleport-product-toolbar">
    <div class="inline-block">
      <Button
        class="c-circular-button mr-4"
        @click="emit('onSave')"
        data-testid="btn-commit"
        id="btn-commit"
        style="border-color: var(--p-button-outlined-secondary-border-color)"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4 font-normal">{{ t("common.save") }}</span>
      </Button>
    </div>
  </teleport>

  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToProductList')"
    @saveClicked="emit('onSave')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();

defineProps<{
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "onSave"): void;
  (e: "onCancel"): void;
  (e: "routeToProductList"): void;
  (e: "stayOnPage"): void;
}>();

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>
<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
