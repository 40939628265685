<template>
  <FloatLabelDropdown
    id="product-status"
    dataTestId="product-status"
    v-model:value="selectedStatusType"
    :options="dropdownOptions"
    :label="t(`product.status.label`)"
    :isFloatingLabel="isFloatingLabel"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import { Options } from "@/repositories/product/model/Options";
import { FloatLabelDropdown } from "@cumulus/components";

const { t } = useI18n();
const productStatusTypes = ref<Options[]>([]);
const languageKey = "product.status.";

const props = defineProps<{
  productStatus: string;
}>();

const emit = defineEmits<{
  (e: "update:productStatus", value: string): void;
}>();

const selectedStatusType = computed<string>({
  get: () => {
    return props.productStatus ?? ProductStatus.Active;
  },
  set: (value) => {
    emit("update:productStatus", value);
  },
});

const fetchStatusTypes = () => {
  for (const prStatus in ProductStatus) {
    productStatusTypes.value.push({
      name: t(`${languageKey}${prStatus.toString().toLowerCase()}`),
      code: prStatus.toString(),
    });
  }

  if (selectedStatusType.value === "" && productStatusTypes.value.length > 0) {
    selectedStatusType.value = productStatusTypes.value[0].code;
  }
};
onMounted(fetchStatusTypes);

const dropdownOptions = computed(() => {
  return productStatusTypes.value.map((productStatusType) => {
    return { name: productStatusType.name, value: productStatusType.code };
  });
});

const isFloatingLabel = computed(() => {
  return selectedStatusType.value !== "";
});
</script>
