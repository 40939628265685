import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { SalesUnit } from "./model/SalesUnit";
import { salesUnitApi } from "./SalesUnitApi";

export const useSalesUnitStore = defineStore("salesUnit", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const salesUnits = ref<SalesUnit[]>([]);
  const loading = ref(false);
  const saving = ref(false);

  const salesUnitById = (id: string): SalesUnit => {
    return salesUnits.value.find((salesUnit) => salesUnit.id === id) ?? new SalesUnit();
  };

  const fetchSalesUnits = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      salesUnits.value = await salesUnitApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const createSalesUnit = async (salesUnit: SalesUnit): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await salesUnitApi.create(authHeaders, salesUnit);
      salesUnits.value.push(salesUnit);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateSalesUnit = async (salesUnit: SalesUnit): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await salesUnitApi.update(authHeaders, salesUnit);
      const index = salesUnits.value.findIndex((cg) => cg.id === salesUnit.id);
      salesUnits.value[index] = salesUnit;
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const deleteSalesUnit = async (salesUnit: SalesUnit): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await salesUnitApi.delete(authHeaders, salesUnit.id);
      salesUnits.value = salesUnits.value.filter((cg) => cg.id !== salesUnit.id);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  return {
    salesUnits,
    loading,
    saving,
    salesUnitById,
    fetchSalesUnits,
    createSalesUnit,
    updateSalesUnit,
    deleteSalesUnit,
  };
});
