import { ProductGroupNameByLanguage } from "./ProductGroupNameByLanguage";
import { ProductHierarchy } from "./ProductHierarchy";

export class ProductHierarchyByLanguage {
  id = "";
  level1: ProductGroupNameByLanguage | null = null;
  level2: ProductGroupNameByLanguage | null = null;
  level3: ProductGroupNameByLanguage | null = null;
  level4: ProductGroupNameByLanguage | null = null;
  level5: ProductGroupNameByLanguage | null = null;

  public static createFromProductHierarchy(productHierarchy: ProductHierarchy, languageIso: string) {
    const gl = new ProductHierarchyByLanguage();
    gl.id = productHierarchy.id;
    gl.level1 = ProductGroupNameByLanguage.createFromProductGroupName(productHierarchy?.level1, languageIso);
    gl.level2 = ProductGroupNameByLanguage.createFromProductGroupName(productHierarchy?.level2, languageIso);
    gl.level3 = ProductGroupNameByLanguage.createFromProductGroupName(productHierarchy?.level3, languageIso);
    gl.level4 = ProductGroupNameByLanguage.createFromProductGroupName(productHierarchy?.level4, languageIso);
    gl.level5 = ProductGroupNameByLanguage.createFromProductGroupName(productHierarchy?.level5, languageIso);
    return gl;
  }
}
