import { Weight } from "./Weight";
import { Dimension } from "./Dimension";
import { ProductType } from "./ProductType";
import { Media } from "./Media";
import { SupplierPrice } from "./SupplierPrice";
import { Price } from "./Price";
import { ProductAttribute } from "./ProductAttribute";
import { ProductImage } from "./ProductImage";
import { ProductStatus } from "./ProductStatus";
import { NIL as emptyUuid } from "uuid";
import { ProductTax } from "./ProductTax";
import { ProductDocument } from "./ProductDocument";

export class NewProduct {
  name = "";
  productNumber = "";
  gtin = "";
  description = "";
  salesUnitId = "";
  productHierarchyId = "";
  weight = new Weight();
  dimension = new Dimension();
  productType: ProductType = ProductType.Merchandise;
  productInfo = "";
  media: Media[] = [];
  productImages: ProductImage[] = [];
  supplierPrices: SupplierPrice[] = [];
  prices: Price[] = [];
  alternativeProductIds: string[] = [];
  accessoryProductIds: string[] = [];
  attributes: ProductAttribute[] = [];
  clientIds: string[] = [];
  warehouseIds: string[] = [];
  manufacturerId = emptyUuid;
  accountGroupId = emptyUuid;
  taxes: ProductTax[] = [];
  activeOnWebshop = true;
  productStatus: ProductStatus = ProductStatus.Active;
  documents: ProductDocument[] = [];
  parentProductId: string | null = null;
  variantChildProductIds: string[] = [];
  variantAttributes: ProductAttribute[] = [];
}
