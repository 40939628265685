<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button"
          @click="emit('importProductHierarchyClicked')"
          data-testid="btn-import-product-hierarchy"
          disabled
        >
          <i class="pi pi-file-import c-default-btn-bg c-circular-icon"></i>
          <span class="px-4">{{ t("product-hierarchy-toolbar.import-product-hierarchy") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "importProductHierarchyClicked"): void;
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.c-default-btn-bg {
  background-color: var(--default-btn-bg);
}
</style>
