import { LanguageIsoType } from "../language/LanguageIsoType";
import { LanguageText } from "../language/LanguageText";
import { AttributeState } from "./AttributeState";
import { AttributeType } from "./AttributeType";
import { AttributeValue } from "./AttributeValue";
import { v4 as uuid } from "uuid";

export class Attribute {
  id = "";
  names: LanguageText[] = [];
  type: AttributeType = AttributeType.Standard;
  values: AttributeValue[] = [];
  attributeState: AttributeState = AttributeState.Active;

  constructor() {
    this.id = uuid();
    this.names = [new LanguageText(LanguageIsoType.English, ""), new LanguageText(LanguageIsoType.Norwegian, "")];
  }
}
