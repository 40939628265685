import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@cumulus/http";
import { type Warehouse } from "./model/Warehouse";

class WarehouseApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE as string}/warehouses`
        : `${import.meta.env.VITE_APP_API_URL as string}/warehouses`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Warehouse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }: AxiosResponse) => data);
  }
}
const warehouseApi = new WarehouseApi();
export { warehouseApi };
