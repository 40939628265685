import { setupI18n } from "@cumulus/locale";
import { LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import commonEnLocale from "../locales/en.json";
import commonNoLocale from "../locales/no.json";
import productHierarchyEnLocale from "../product-hierarchy/locales/en.json";
import productHierarchyNoLocale from "../product-hierarchy/locales/no.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const commonMessages = locale === "en" ? commonEnLocale : commonNoLocale;
  const productGroupMessages = locale === "en" ? productHierarchyEnLocale : productHierarchyNoLocale;
  const messages = { ...commonMessages, ...productGroupMessages };
  return messages as unknown as LocaleMessageDictionary<VueMessageType>;
}

export const i18n = setupI18n(
  { ...commonEnLocale, ...productHierarchyEnLocale } as unknown as string,
  loadMessagesAsync,
);
