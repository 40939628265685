import { NIL as emptyUuid } from "uuid";
import { ProductRelationType } from "./ProductRelationType";

export class ProductRelation {
  id: string = emptyUuid;
  productId: string = emptyUuid;
  relationType: ProductRelationType = ProductRelationType.None;

  constructor(productId: string, relationType: ProductRelationType, id: string = emptyUuid) {
    this.id = id;
    this.productId = productId;
    this.relationType = relationType;
  }
}
