import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { salesUnitApi } from "./SalesUnitApi";
import type { SalesUnit } from "./model/SalesUnit";

export function useSalesUnitService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getSalesUnits = async (): Promise<SalesUnit[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await salesUnitApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };
  return { getSalesUnits };
}
