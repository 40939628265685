<template>
  <div class="space-y-6">
    <RegularProductCard v-if="product.structureType === ProductStructureType.Standard" />

    <ParentProductVariantsCard
      v-else-if="product.structureType === ProductStructureType.VariantParent"
      :product="product"
      :childProducts="childProducts"
      :attributes="attributes"
    />

    <ChildProductVariantCard
      v-else-if="product.structureType === ProductStructureType.VariantChild"
      :product="product"
      :parentProduct="parentProduct"
      :attributes="attributes"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import { i18n } from "@/locales/i18n";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { Product } from "@/repositories/product/model/Product";
import { useProductStore } from "@/repositories/product/ProductStore";
import RegularProductCard from "./RegularProductCard.vue";
import ParentProductVariantsCard from "./ParentProductVariantsCard.vue";
import ChildProductVariantCard from "./ChildProductVariantCard.vue";
import { ProductStructureType } from "@/repositories/product/model/ProductStructureType";

const props = defineProps<{
  product: Product;
}>();

const { getAttributes } = useAttribute();
const { getProductById, getProductsByIds } = useProductStore();

const loading = ref(false);
const childProducts = ref<Product[]>([]);
const parentProduct = ref<Product | null>(null);
const attributes = ref<AttributeByLanguageIso[]>([]);

const loadAttributesByPortalLanguage = async () => {
  loading.value = true;
  const languageIso = i18n.global.locale.value === "nb-NO" ? LanguageIsoType.Norwegian : LanguageIsoType.English;
  try {
    attributes.value = await getAttributes(languageIso);
  } finally {
    loading.value = false;
  }
};

const loadChildProducts = async () => {
  loading.value = true;
  try {
    childProducts.value = await getProductsByIds(props.product.variantChildProductIds ?? []);
  } finally {
    loading.value = false;
  }
};

const loadParentProduct = async () => {
  if (props.product.parentProductId) {
    parentProduct.value = await getProductById(props.product.parentProductId);
  }
};

watch(
  () => props.product.id,
  async () => {
    if (props.product.id) {
      await loadAttributesByPortalLanguage();

      if (props.product.structureType === ProductStructureType.VariantParent) {
        await loadChildProducts();
      } else if (props.product.parentProductId) {
        await loadParentProduct();
      }
    }
  },
  { once: true },
);
</script>
