<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <div v-if="defaultOutgoingTax !== null" class="text-sm">
        {{ t("product.import.default-tax-info") }}
      </div>
      <div v-else class="text-sm text-yellow-600">
        {{ t("product.import.no-taxes") }}
      </div>
    </div>
    <div class="flex justify-end mt-3">
      <Button
        :label="t('common.clear')"
        size="small"
        severity="secondary"
        :aria-label="t('common.clear')"
        @click="clearSelection"
      />
      <Button :label="t('common.apply')" size="small" :disabled="defaultOutgoingTax === null" @click="applySelection" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TaxReference } from "@/product/models/ImportOptions";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import type { Tax } from "@/repositories/tax/model/Tax";
import { TaxType } from "@/repositories/tax/model/TaxType";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const referenceStore = useProductImportReferenceStore();
const optionsStore = useProductImportOptionsStore();
const { clearMappingForColumn } = useProductImportSessionStore();

const defaultOutgoingTax = ref<TaxReference | null>(null);
const allTaxes = ref<Tax[]>([]);
const loading = ref(false);
const activeCountries = ref<string[]>(["NO"]);

onMounted(async () => {
  try {
    loading.value = true;
    allTaxes.value = await referenceStore.getAvailableTaxes();
    setDefaultTaxes();
  } finally {
    loading.value = false;
  }
});

const setDefaultTaxes = () => {
  activeCountries.value.forEach((countryIso) => {
    const defaultTax = getDefaultTax(TaxType.Outgoing, countryIso);
    if (defaultTax === null) {
      return;
    } else {
      const outgoing = {
        id: defaultTax.id,
        rate: defaultTax.rate,
      } as TaxReference;
      defaultOutgoingTax.value = outgoing;
    }
  });
};

const getDefaultTax = (taxType: TaxType, countryIso: string) => {
  return (
    allTaxes.value?.find(
      (tax) => tax.taxType === taxType && tax.countryIso === countryIso && tax.isDefaultTaxForProduct,
    ) ?? null
  );
};

const clearSelection = () => {
  optionsStore.setDefaultTaxes(null);
};

const applySelection = () => {
  optionsStore.setDefaultTaxes(defaultOutgoingTax.value);
  clearMappingForColumn("vat");
};
</script>
