import { useAuth } from "@cumulus/event-bus";
import { manufacturerApi } from "@/repositories/manufacturer/ManufacturerApi";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { Manufacturer } from "./model/Manufacturer";

export function useManufacturer() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getManufacturers = async (): Promise<Manufacturer[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await manufacturerApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getManufacturers,
  };
}
