import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";

import { companyApi } from "@/product/api/company/CompanyApi";
import { productApi } from "@/repositories/product/ProductApi";
import { LanguageViewModel } from "@/product/models/LanguageViewModel";
import { ProductWithLanguage, Language } from "@/product/models/ProductWithLanguage";

export const useProductLanguageStore = defineStore("productLanguageStore", () => {
  const { t } = useI18n();
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const loading = ref(false);
  const saving = ref(false);

  const defaultLanguage = ref("");
  const allProductIds = ref<string[]>([]);
  const languages = ref<LanguageViewModel[]>([]);

  const currentIndex = ref(0);
  const productsLanguages = ref<ProductWithLanguage[]>([]);
  let productsLanguagesUnchanged: ProductWithLanguage[] = [];

  const getAvailableLanguages = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      const company = await companyApi.get(authHeaders);

      //TODO: Removethis when the backend is ready
      company.defaultLanguageIso = "nb-NO";
      company.activeLanguageIsos = ["nb-NO", "en-GB", "sv-SE"];

      defaultLanguage.value = company.defaultLanguageIso;

      for (const languageIso of company.activeLanguageIsos) {
        const language = new LanguageViewModel();
        language.name = t(`common.language-iso.${languageIso.toLowerCase()}`);
        language.code = languageIso;

        if (languageIso === defaultLanguage.value) {
          language.name += ` (${t("product-info-dialog.default-language")})`;
        }

        languages.value.push(language);
      }
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const init = async (ids: string[]): Promise<void> => {
    currentIndex.value = 0;
    allProductIds.value = ids;
    productsLanguages.value = [];
    productsLanguagesUnchanged = [];

    if (ids.length > 0) {
      await getActiveProductLanguages(ids[0]);
    }
  };

  const getActiveProductLanguages = async (productId: string): Promise<void> => {
    if (allProductIds.value.length === 0) {
      throw new Error("Missing call init()");
    }

    try {
      loading.value = true;

      const authHeaders = await getAuthHeaders();
      const productLanguages = await productApi.getProductLanguages(authHeaders, productId);

      const productWithLanguage = new ProductWithLanguage();
      productWithLanguage.productId = productId;
      productWithLanguage.languages = productLanguages.map((productLanguage) => {
        const language = new Language();
        language.languageIso = productLanguage.languageIso;
        language.name = productLanguage.name;
        language.description = productLanguage.description;
        language.productInfo = productLanguage.productInfo;
        return language;
      });

      productsLanguages.value.push(productWithLanguage);
      productsLanguagesUnchanged.push(structuredClone(productWithLanguage));
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const activeProductLanguages = computed<ProductWithLanguage>(() => {
    if (productsLanguages.value.length < currentIndex.value) {
      throw new Error("Index out of bounds");
    }
    return productsLanguages.value[currentIndex.value];
  });

  const nextProduct = async () => {
    if (currentIndex.value < allProductIds.value.length - 1) {
      const index = currentIndex.value + 1;
      if (index > productsLanguages.value.length - 1) {
        await getActiveProductLanguages(allProductIds.value[index]);
      }
      currentIndex.value = index;
    }
  };

  const previousProduct = async () => {
    if (currentIndex.value > 0) {
      currentIndex.value--;
    }
  };

  const getChangedProductsLanguages = (): ProductWithLanguage[] => {
    return productsLanguages.value.filter((newObj) => {
      const originalObj = productsLanguagesUnchanged.find((origObj) => origObj.productId === newObj.productId);
      return originalObj && JSON.stringify(originalObj) !== JSON.stringify(newObj);
    });
  };

  const saveChanges = async (): Promise<void> => {
    try {
      saving.value = true;
      const changedProductsLanguages = getChangedProductsLanguages();
      const authHeaders = await getAuthHeaders();
      await productApi.updateProductsLanguages(authHeaders, changedProductsLanguages);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  return {
    init,
    getAvailableLanguages,
    getActiveProductLanguages,
    nextProduct,
    previousProduct,
    getChangedProductsLanguages,
    saveChanges,
    languages,
    productsLanguages,
    defaultLanguage,
    activeProductLanguages,
    currentIndex,
    loading,
    saving,
  };
});
