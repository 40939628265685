<template>
  <div class="mb-8">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">{{ t("product.import.recent-imports") }}</h2>
      <router-link :to="{ name: 'product-import-history' }" class="text-blue-600 hover:text-blue-700">
        {{ t("product.import.view-all-imports") }}
      </router-link>
    </div>

    <div v-if="loading" class="flex justify-center py-4">
      <ProgressSpinner />
    </div>

    <div v-else-if="recentImports.length === 0" class="text-gray-500 text-center py-4">
      {{ t("product.import.no-recent-imports") }}
    </div>

    <div v-else class="space-y-4">
      <div
        v-for="session in recentImports"
        :key="session.id"
        class="border rounded-lg p-4 hover:bg-gray-50 cursor-pointer"
        @click="navigateToImport(session)"
      >
        <div class="flex justify-between items-start">
          <div>
            <h3 class="font-medium">{{ session.originalFileName }}</h3>
            <p class="text-sm text-gray-500">
              {{ d(session.createdAt) }}
            </p>
          </div>
          <StatusBadge :status="session.status" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import StatusBadge from "./StatusBadge.vue";
import type { ProductImportSessionSummary } from "@/product/models/ProductImportSessionSummary";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const { t, d } = useI18n();
const router = useRouter();
const sessionStore = useProductImportSessionStore();

const recentImports = ref<ProductImportSessionSummary[]>([]);
const loading = ref(true);

const emit = defineEmits<{
  (e: "openImportSession", value: string): void;
}>();

const fetchRecentImports = async () => {
  try {
    const response = await sessionStore.getImportSessions(1, 3);
    recentImports.value = response.sessionSummaries;
  } finally {
    loading.value = false;
  }
};

const navigateToImport = (session: ProductImportSessionSummary) => {
  let step = "1";

  switch (session.status) {
    case "FileUploaded":
    case "Created":
    case "Mapping":
    case "MappingCompleted":
    case "Validating":
      step = "2";
      break;
    case "ValidationComplete":
      step = "3";
      break;
    case "Importing":
      step = "3";
      break;
    case "ImportCompleted":
      emit("openImportSession", session.id);
      break;
  }

  router.push({
    name: "product-import",
    params: { id: session.id, step },
  });
};

onMounted(fetchRecentImports);
</script>
