<template>
  <Tabs value="0" class="c-media-tabs border rounded-lg">
    <TabList
      class="px-4"
      :pt="{
        activebar: '!z-0',
      }"
    >
      <Tab value="0">{{ t("product.image.label") }}</Tab>
      <Tab value="1" data-testid="video-tab">{{ t("product.video.label") }}</Tab>
      <Tab value="2" data-testid="document-tab">{{ t("product.documents.header") }}</Tab>
    </TabList>
    <TabPanels class="p-0 bg-transparent">
      <TabPanel value="0">
        <ProductImages
          v-model:productImages="productImages"
          @imageAdded="addImage"
          @imageRemoved="removeImage"
          @updateImage="updateImage"
          @updateImageOrder="updateImageOrder"
          @clearImagesClicked="clearImages"
        />
      </TabPanel>
      <TabPanel value="1" style="min-height: 510px">
        <ProductVideoCarousel v-model:productVideos="productVideos" />
      </TabPanel>
      <TabPanel value="2" style="min-height: 510px">
        <ProductDocumentManager v-model:documents="productDocuments" />
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<script setup lang="ts">
import { ProductImage } from "@/repositories/product/model/ProductImage";
import ProductImages from "./product-images/ProductImages.vue";
import { useI18n } from "vue-i18n";
import ProductVideoCarousel from "./product-videoes/ProductVideoCarousel.vue";
import { Media } from "@/repositories/product/model/Media";
import { ProductDocument } from "@/repositories/product/model/ProductDocument";
import ProductDocumentManager from "./product-documents/ProductDocumentManager.vue";
const { t } = useI18n();

const productImages = defineModel<ProductImage[]>("productImages", {
  required: true,
});

const productVideos = defineModel<Media[]>("productVideos", {
  required: true,
});

const productDocuments = defineModel<ProductDocument[]>("productDocuments", {
  required: true,
});

const addImage = (image: ProductImage) => {
  productImages.value.push(image);
};

const removeImage = (url: string) => {
  productImages.value = productImages.value.filter((image) => image.url !== url);
};

const updateImageOrder = (images: ProductImage[]) => {
  productImages.value = images;
};

const updateImage = (index: number, updatedImage: ProductImage) => {
  productImages.value[index] = updatedImage;
};

const clearImages = () => {
  productImages.value = [];
};
</script>

<style scoped lang="scss">
.c-media-tabs :deep(.p-tablist-tab-list) {
  background-color: transparent;
}
</style>
