<template>
  <div class="mt-6">
    <FloatLabelInput
      :inputType="'text'"
      :label="t('product.number')"
      v-model:value="productNumberComputed"
      id="product-number"
      :focusInput="focusInput"
      :placeholder="t('placeholder.type', { property: t('product.number-input').toLowerCase() })"
      dataTestId="product-number"
    />
    <small class="p-error" v-if="val.productNumber.$error" data-testid="product-number-error">
      {{ val.productNumber.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { computed } from "vue";
import { requiredIf } from "@vuelidate/validators";

const props = defineProps<{
  productNumber: string;
  focusInput: boolean;
  isEditing: boolean;
}>();

const { t } = useI18n();

const rules = {
  productNumber: {
    required: requiredIf(props.isEditing),
  },
};

const val = useVuelidate(rules, props);

const emit = defineEmits<{
  (e: "update:productNumber", value: string): void;
}>();

const productNumberComputed = computed<string>({
  get: () => {
    return props.productNumber ? props.productNumber : "";
  },
  set: (value) => {
    emit("update:productNumber", value);
  },
});
</script>
