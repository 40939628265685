<template>
  <div
    class="border rounded-lg p-3 h-full flex flex-col bg-white shadow-sm hover:shadow-md transition-shadow duration-200 justify-center"
  >
    <FileUpload
      accept="image/jpeg,image/png,image/webp"
      :multiple="true"
      :maxFileSize="10000000"
      :auto="true"
      :customUpload="true"
      pt:root:class="h-full"
      @uploader="uploader"
    >
      <template #header="{ chooseCallback }">
        <div class="flex flex-wrap justify-center items-center flex-1 gap-4">
          <div class="flex gap-2">
            <PrimeButton class="c-circular-button mr-4" @click="chooseCallback()">
              <i class="pi pi-images c-default-button c-circular-icon"></i>
              <span class="px-4"> {{ t("product.image.select") }} </span>
            </PrimeButton>
          </div>
        </div>
      </template>
      <template #content>
        <div class="flex items-center justify-center flex-col p-4 h-full">
          <i class="pi pi-cloud-upload text-4xl mb-4 text-blue-500" />
          <p class="mb-2 text-lg text-center">{{ t("product.image.drag-and-drop") }}</p>
          <p class="text-base text-gray-500 text-center">{{ t("product.image.supported-formats") }}</p>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { FileUploadUploaderEvent } from "primevue/fileupload";
import { useImageService } from "@/repositories/image/ImageService";
import { useAuth } from "@cumulus/event-bus";
import { ProductImage } from "@/repositories/product/model/ProductImage";

const { t } = useI18n();
const { uploadImage } = useImageService();
const { getAuthHeaders } = useAuth();

const emit = defineEmits<{
  (e: "addImage", value: ProductImage): void;
}>();

const uploader = async (event: FileUploadUploaderEvent) => {
  const files = event.files;
  if (files !== undefined && files !== null) {
    try {
      const authHeaders = await getAuthHeaders();
      for (const file of files as File[]) {
        const imageInfo = await uploadImage(file, authHeaders);
        emit("addImage", imageInfo);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};
</script>
