<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :style="{ width: '110vw', maxWidth: '90%', height: '75vh', maxHeight: '90%' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :closable="true"
    :closeOnEscape="true"
    pt:content:class="h-full"
    :contentStyle="{ height: '100%', overflow: 'hidden' }"
  >
    <template #header>
      <div class="flex justify-center w-full">
        <div class="font-bold whitespace-nowrap">{{ t("product.relations.dialog-add-header") }}</div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-6 h-full" style="height: 100%; overflow: hidden">
      <div class="col-span-12 lg:col-span-12">
        <ProductSearchInput
          class="w-full my-2"
          :pageSize="pageSize"
          ref="productSearchInput"
          :page="page"
          @update:searchResults="onSearchResultsUpdated"
          @update:isSearching="($event: boolean) => (loading = $event)"
          @focus-search-result="onFocusSearchResult"
          autofocus
        />
      </div>
      <div class="col-span-12 lg:col-span-12" style="height: 100%; overflow: hidden">
        <DataTable
          v-show="searchResultsComputed.length > 0"
          :value="searchResultsComputed"
          v-model:selection="selectedProducts"
          dataKey="id"
          data-testid="search-result-table"
          :autoLayout="true"
          :lazy="true"
          ref="searchResultTable"
          :paginator="true"
          responsiveLayout="scroll"
          selectionMode="multiple"
          scrollable
          scrollHeight="flex"
          :loading="loading"
          :rows="pageSize"
          :totalRecords="totalRecords"
          :currentPageReportTemplate="
            t('common.current-page-template', {
              first: '{first}',
              last: '{last}',
              totalRecords: '{totalRecords}',
            })
          "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          @page="onPage"
        >
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

          <Column field="productImages">
            <template #body="{ data }">
              <img
                v-if="data.productImages.length > 0"
                :src="resizeImage(data.productImages[0].url, 30, 30)"
                class="c-product-image"
                width="30"
                height="30"
                :alt="`${data.productImages[0].name}-product-image`"
              />
            </template>
          </Column>

          <Column field="productNumber" :header="t('product.relations.header-product-number')">
            <template #body="{ data }">
              {{ data.productNumber }}
            </template>
          </Column>

          <Column field="name" :header="t('product.relations.header-name')">
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>

          <Column field="description" :header="t('product.relations.header-description')">
            <template #body="{ data }">
              {{ data.description }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4 pt-5">
        <Select
          v-model="selectedType"
          :options="relationTypes"
          optionLabel="label"
          optionValue="type"
          :placeholder="t('product.relations.select-relation-type')"
        ></Select>
        <Button
          type="button"
          data-testid="btn-add"
          @click="save"
          class="c-success-button mr-8"
          :disabled="selectedProducts.length === 0 || selectedType === ProductRelationType.None"
          v-tooltip.top="tooltipComputed"
        >
          <i class="pi pi-check"></i>
          <span class="px-2">{{ t("product.relations.button-add") }}</span>
        </Button>
        <Button @click="visible = false" data-testid="btn-cancel" severity="cancel" text>
          <i class="pi pi-times"></i>
          <span class="px-2 font-bold">{{ t("common.cancel") }}</span>
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { computed, ref } from "vue";
import { Product } from "@/repositories/product/model/Product";
import ProductSearchInput from "./ProductSearchInput.vue";
import { type DataTablePageEvent } from "primevue/datatable";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { useImageService } from "@/repositories/image/ImageService";
import { v4 as uuidv4 } from "uuid";

const { t } = useI18n();
const { resizeImage } = useImageService();

const selectedType = ref<ProductRelationType>(ProductRelationType.None);
const relationTypes = [
  { label: t("product.relations.accessory"), type: ProductRelationType.Accessory },
  { label: t("product.relations.accessoryto"), type: ProductRelationType.AccessoryTo },
  { label: t("product.relations.alternativecrosssell"), type: ProductRelationType.AlternativeCrossSell },
  { label: t("product.relations.alternativeupsell"), type: ProductRelationType.AlternativeUpSell },
  { label: t("product.relations.alternativedownsell"), type: ProductRelationType.AlternativeDownSell },
];

const visible = defineModel<boolean>("visible", { required: true, default: false });

const props = defineProps<{
  relations: ProductRelation[];
  productId: string;
}>();

const emit = defineEmits<{
  (e: "addRelations", value: ProductRelation[]): void;
}>();

const loading = ref(false);
const searchResults = ref<Product[]>([]);
const totalRecords = ref(0);
const selectedProducts = ref<Product[]>([]);
const page = ref(1);
const pageSize = 25;
const productSearchInput = ref();
const searchResultTable = ref();

const searchResultsComputed = computed(() => {
  return searchResults.value.filter((x) => !isCurrentProduct(x) && !isAlreadySelected(x));
});

const isCurrentProduct = (searchProduct: Product) => searchProduct.id === props.productId;
const isAlreadySelected = (searchProduct: Product) => props.relations.some((x) => x.productId === searchProduct.id);

const onSearchResultsUpdated = (products: Product[], totalHits: number) => {
  searchResults.value = products;
  totalRecords.value = totalHits;
  selectedProducts.value = [];
};

const onFocusSearchResult = () => {
  searchResultTable.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const onPage = async (event: DataTablePageEvent) => {
  page.value = event.page + 1;
};

const save = () => {
  const newRelations = selectedProducts.value.map((x) => new ProductRelation(x.id, selectedType.value, uuidv4()));
  emit("addRelations", newRelations);
  visible.value = false;
  selectedProducts.value = [];
  searchResults.value = [];
};

const tooltipComputed = computed(() => {
  if (selectedProducts.value.length === 0) return "Velg minst ett produkt";

  if (selectedType.value === ProductRelationType.None) return "Velg relasjonstype";

  return "";
});
</script>

<style scoped lang="scss"></style>
