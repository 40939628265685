<template>
  <div class="place-items-center mx-6 mt-5">
    <InputGroup>
      <InputGroupAddon><Checkbox v-model="transferDescription" :binary="true"></Checkbox></InputGroupAddon>
      <FloatLabel variant="on">
        <InputText
          v-model="description"
          id="ai-prod-desc-result"
          data-testid="ai-prod-desc-result"
          type="text"
          class="inputfield w-full"
          :maxlength="100"
        />
        <label for="ai-prod-desc-result">{{ t("product.description") }}</label>
      </FloatLabel>
    </InputGroup>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const description = defineModel<string>("description");
const transferDescription = defineModel<boolean>("transferDescription");
</script>
