<template>
  <div class="place-items-center">
    <span for="lock-ai-prompt" class="text-sm">{{ t("product.ai-search.lock") }}</span>
    <div>
      <i :class="iconClass" style="font-size: 2rem"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const lockPrompt = defineModel<boolean>("lockPrompt");

const iconClass = computed(() => (lockPrompt.value ? "pi pi-lock" : "pi pi-lock-open"));
</script>
