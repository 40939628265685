import { defineStore } from "pinia";
import { ref } from "vue";
import { DefaultValueMapping, ImportOptions, type Reference, type TaxReference } from "@/product/models/ImportOptions";

export const useProductImportOptionsStore = defineStore("productImportDefaults", () => {
  const importOptions = ref<ImportOptions>({
    overwriteExisting: true,
    createNew: true,
    updateEmpty: false,
    productIdentifier: "",
    defaultValues: new DefaultValueMapping(),
  });

  const resetState = (): void => {
    importOptions.value = {
      overwriteExisting: true,
      createNew: true,
      updateEmpty: false,
      productIdentifier: "",
      defaultValues: new DefaultValueMapping(),
    };
  };

  const setOptions = (options: ImportOptions) => {
    importOptions.value = options;
  };

  const setDefaultName = (name: string | null) => {
    importOptions.value.defaultValues.name = name;
  };

  const setDefaultProductType = (type: string | null) => {
    importOptions.value.defaultValues.productType = type;
  };

  const setDefaultProductStatus = (status: string | null) => {
    importOptions.value.defaultValues.productStatus = status;
  };

  const setDefaultProductGroup = (group: Reference | null) => {
    importOptions.value.defaultValues.productGroupLevel1 = group;
  };

  const setDefaultClients = (clients: Reference[]) => {
    importOptions.value.defaultValues.clients = clients;
  };

  const setDefaultSalesUnit = (salesUnit: Reference | null) => {
    importOptions.value.defaultValues.salesUnit = salesUnit;
  };

  const setDefaultWarehouses = (warehouses: Reference[]) => {
    importOptions.value.defaultValues.warehouses = warehouses;
  };

  const setDefaultSupplier = (supplier: Reference | null) => {
    importOptions.value.defaultValues.mainSupplier = supplier;
  };

  const setDefaultPriceGroup = (priceGroup: Reference | null) => {
    importOptions.value.defaultValues.priceGroup = priceGroup;
  };

  const setDefaultTaxes = (taxes: TaxReference | null) => {
    importOptions.value.defaultValues.taxOutgoing = taxes;
  };

  return {
    importOptions,
    setOptions,
    setDefaultName,
    setDefaultProductType,
    setDefaultProductStatus,
    setDefaultProductGroup,
    setDefaultClients,
    setDefaultSalesUnit,
    setDefaultWarehouses,
    setDefaultSupplier,
    setDefaultPriceGroup,
    setDefaultTaxes,
    resetState,
  };
});
