<template>
  <ProgressSpinner v-if="uploadInProgress" />
  <div v-else>
    <img
      v-if="logoUrlComputed?.length > 0"
      :src="resizeImage(logoUrlComputed, 100, 50)"
      data-testid="manufacturer-logo-img"
    />
    <div v-else class="logo-empty"><i class="pi pi-image"></i></div>

    <FileUpload
      accept="image/jpeg,image/png,image/webp"
      mode="basic"
      :maxFileSize="10000000"
      :fileLimit="1"
      :auto="true"
      :customUpload="true"
      @uploader="upload($event as unknown as FileUploadUploadEvent)"
      data-testid="manufacturer-logo"
    >
      <template #empty>
        <p>{{ t("product.manufacturer.add-logo") }}</p>
      </template>
    </FileUpload>
  </div>
</template>

<script setup lang="ts">
import { useImageService } from "@/repositories/image/ImageService";
import { FileUploadUploadEvent } from "primevue/fileupload";
import { useAuth } from "@cumulus/event-bus";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ImageInfo } from "@/repositories/image/model/ImageInfo";

const props = defineProps<{
  logo: ImageInfo;
}>();

const emit = defineEmits<{
  (e: "update:logo", value: ImageInfo): void;
}>();

const { uploadImage, resizeImage } = useImageService();
const { getAuthHeaders } = useAuth();
const { t } = useI18n();

const uploadInProgress = ref(false);

const logoUrlComputed = computed<string>(() => {
  return props.logo.url === "about:blank" ? "" : props.logo.url;
});

const upload = async (event: FileUploadUploadEvent) => {
  const files = event.files;
  if (!files) {
    return;
  }

  uploadInProgress.value = true;

  try {
    const authHeaders = await getAuthHeaders();
    for (const file of files as File[]) {
      const imageInfo = await uploadImage(file, authHeaders);
      emit("update:logo", imageInfo);
    }
  } finally {
    uploadInProgress.value = false;
  }
};
</script>

<style scoped lang="scss">
.logo-empty {
  width: 100px;
  height: 50px;
  background: var(--surface-b);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-empty i {
  font-size: 2rem;
  color: var(--text-color-secondary);
}
</style>
