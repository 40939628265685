import { ProductStatus } from "@/repositories/product/model/ProductStatus";

export class ProductSearchFilters {
  manufacturers: string[] = [];
  productStatuses: ProductStatus[] = [];
  productNumbers: string[] = [];
  productNames: string[] = [];
  groupLevels: string[] = [];

  public equals = (other: ProductSearchFilters): boolean => {
    return (
      this.manufacturers === other.manufacturers &&
        this.productStatuses === other.productStatuses &&
        this.productNumbers === other.productNumbers &&
        this.productNames === other.productNames,
      this.groupLevels === other.groupLevels
    );
  };
}
