<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <Button class="c-circular-button" @click="router.push({ name: 'product-search', params: { query: '*' } })">
          <i class="pi pi-arrow-left c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.back") }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();
</script>
