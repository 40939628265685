<template>
  <Card class="transition-all duration-200 hover:shadow-lg">
    <template #content>
      <div class="text-center space-y-6">
        <div class="relative inline-block mx-auto">
          <div class="w-16 h-16 bg-white rounded-2xl shadow flex items-center justify-center">
            <i class="pi pi-sitemap text-2xl text-blue-600"></i>
          </div>
        </div>

        <div class="space-y-3 max-w-md mx-auto">
          <h3 class="text-xl font-semibold">{{ t("product.variants.no-variants-configured.header") }}</h3>
          <p>{{ t("product.variants.no-variants-configured.description") }}</p>
          <p>
            <Button link class="p-0 text-blue-600 underline" @click="navigateToAddProduct">
              {{ t("product.variants.no-variants-configured.add-product") }}
            </Button>
            {{ t("product.variants.no-variants-configured.to-create-new-variant") }}
          </p>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();

const navigateToAddProduct = () => {
  const url = router.resolve({ name: "product-add" }).href;
  window.open(url, "_blank");
};
</script>
