<template>
  <Card class="w-full mt-6">
    <template #header>
      <div class="flex items-center justify-between p-6">
        <div class="flex items-center gap-2">
          <i class="pi pi-box text-lg"></i>
          <h3 class="text-lg font-medium">{{ t("product.variants.preview") }}</h3>
        </div>
        <InputGroup class="w-80">
          <PrimeButton icon="pi pi-search" />
          <InputText v-model="searchQuery" :placeholder="t('common.search')" />
        </InputGroup>
      </div>
    </template>
    <template #content>
      <ContextMenu ref="cm" :model="contextMenuModel" />
      <DataTable
        :value="filteredVariants"
        :paginator="true"
        :rows="5"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        stripedRows
        contextMenu
        editMode="row"
        dataKey="id"
        responsiveLayout="scroll"
        class="c-datatable"
        size="small"
        v-model:editingRows="editingRows"
        v-model:selection="selectedVariants"
        selectionMode="multiple"
        @row-edit-save="onRowEditSave"
        @rowContextmenu="onRowContextMenu"
      >
        <Column :header="t('product.search.header-image')" style="width: 100px">
          <template #body="{ data }">
            <img
              v-if="data.productImages.length > 0"
              :src="data.productImages[0]?.url || '/api/placeholder/80/80'"
              class="w-20 h-20 object-cover rounded"
              :alt="data.name"
            />
          </template>
        </Column>

        <Column field="productNumber" :header="t('product.number')">
          <template #body="{ data }">
            {{ data.productNumber ? data.productNumber : "-" }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>

        <Column field="gtin" :header="t('product.gti-number')">
          <template #body="{ data }">
            {{ data.gtin ? data.gtin : "-" }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>

        <Column field="supplierProductNumber" :header="t('product.supplier.product-number')">
          <template #body="{ data }">
            {{ data.supplierProductNumber ? data.supplierProductNumber : "-" }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>

        <Column field="name" :header="t('product.name')">
          <template #body="{ data }">
            <div class="flex items-center gap-2">
              <i class="pi pi-tag text-sm text-gray-500" />
              <span>{{ data.name }}</span>
            </div>
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>

        <Column v-for="attr in usedAttributes" :key="attr.id" :header="attr.name">
          <template #body="{ data }">
            <template v-if="isColorAttribute(attr.id)">
              <div class="flex items-center gap-2">
                <ColorPicker
                  :modelValue="
                    getAttributeValueAdditionalProperty(attr.id, getVariantAttributeValueId(data, attr.id), 'hexCode')
                  "
                  :disabled="true"
                  pt:preview:class="!opacity-100"
                />
                <span>{{ getAttributeValueDescription(attr.id, getVariantAttributeValueId(data, attr.id)) }}</span>
              </div>
            </template>
            <template v-else>
              {{ getAttributeValue(attr.id, getVariantAttributeValueId(data, attr.id)) }}
            </template>
          </template>
        </Column>

        <Column field="defaultSupplierPrice.purchasePrice" :header="t('product.supplier.purchase-price')">
          <template #body="{ data }">
            {{ data.defaultSupplierPrice.purchasePrice ? n(data.defaultSupplierPrice.purchasePrice, "decimal") : "-" }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              v-model="data.defaultSupplierPrice.purchasePrice"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              mode="decimal"
            />
          </template>
        </Column>

        <Column :header="t('product.price.listprice')">
          <template #body="{ data }">
            {{ data.prices[0].listPrice ? n(data.prices[0].listPrice, "decimal") : "-" }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              v-model="data.prices[0].listPrice"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              mode="decimal"
            />
          </template>
        </Column>

        <Column :rowEditor="true" style="width: 10%" bodyStyle="text-align:center" />
      </DataTable>
    </template>
  </Card>

  <BulkImageManagementModal
    v-if="showImageDialog"
    v-model:visible="showImageDialog"
    :selectedVariants="selectedVariants"
    @updateVariants="handleBulkImageUpdate"
  />
</template>

<script setup lang="ts">
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { useAttributeFunctions } from "@/product/composables/attributeFunctions";
import { VariantProduct } from "@/repositories/product/model/VariantProduct";
import { DataTableRowContextMenuEvent, DataTableRowEditSaveEvent } from "primevue/datatable";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import BulkImageManagementModal from "./BulkImageManagementModal.vue";

const props = defineProps<{
  variants: VariantProduct[];
  attributes: AttributeByLanguageIso[];
}>();

const emit = defineEmits<{
  (e: "variantUpdated", index: number, data: VariantProduct): void;
  (e: "variantsBulkUpdated", data: VariantProduct[]): void;
}>();

const { t, n } = useI18n();
const cm = ref();
const searchQuery = ref("");
const editingRows = ref<VariantProduct[]>([]);
const selectedVariants = ref<VariantProduct[]>([]);
const attributes = ref(props.attributes);
const showImageDialog = ref(false);
const { getAttributeValueAdditionalProperty, getAttributeValue, isColorAttribute } = useAttributeFunctions(attributes);

const contextMenuModel = ref([
  { label: t("product.variants.set-new-images"), icon: "pi pi-image", command: () => (showImageDialog.value = true) },
]);

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  cm.value.show(event.originalEvent);
};

const handleBulkImageUpdate = (updatedVariants: VariantProduct[]) => {
  selectedVariants.value = updatedVariants;
  emit("variantsBulkUpdated", updatedVariants);

  showImageDialog.value = false;
};

const usedAttributes = computed<AttributeByLanguageIso[]>(() => {
  const attributeIds = new Set<string>();
  props.variants.forEach((variant) => {
    variant.variantAttributes.forEach((attr) => {
      attributeIds.add(attr.attributeId);
    });
  });

  return Array.from(attributeIds)
    .map((id) => props.attributes.find((attr) => attr.id === id))
    .filter((attr): attr is AttributeByLanguageIso => attr !== undefined);
});

const getVariantAttributeValueId = (variant: VariantProduct, attributeId: string): string => {
  const variantAttr = variant.variantAttributes.find((attr) => attr.attributeId === attributeId);
  if (!variantAttr || !variantAttr.attributeValueIds[0]) return "";

  return variantAttr.attributeValueIds[0];
};

const getAttributeValueDescription = (attributeId: string, attributeValueId: string): string => {
  const attribute = attributes.value.find((attr) => attr.id === attributeId);
  if (!attribute) return "";

  const value = attribute.values.find((val) => val.id === attributeValueId);
  return value?.description || "";
};

const filteredVariants = computed(() => {
  if (!searchQuery.value) return props.variants;

  const query = searchQuery.value.toLowerCase();
  return props.variants.filter((variant) => {
    return (
      variant.name.toLowerCase().includes(query) ||
      variant.productNumber.toLowerCase().includes(query) ||
      variant.gtin.toLowerCase().includes(query)
    );
  });
});

const onRowEditSave = (event: DataTableRowEditSaveEvent) => {
  const { newData, index } = event;
  emit("variantUpdated", index, newData);
};
</script>
