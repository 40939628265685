import { ProductGroupNameByLanguage } from "./ProductGroupNameByLanguage";
import { v4 as uuidv4 } from "uuid";

export class GroupLevelInfo {
  id = uuidv4();
  groupLevel1 = new ProductGroupNameByLanguage();
  groupLevel2 = new ProductGroupNameByLanguage();
  groupLevel3 = new ProductGroupNameByLanguage();
  groupLevel4 = new ProductGroupNameByLanguage();
  groupLevel5 = new ProductGroupNameByLanguage();
}
