<template>
  <div class="ml-6 mb-4">
    <FloatLabel variant="on">
      <InputText
        id="product-description"
        v-model="descriptionComputed"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('product.description').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="product-description"
        class="w-full"
        type="text"
        maxlength="8000"
      />
      <label for="product-description">{{ t("product.description") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  description: string | null;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value) => {
    emit("update:description", value);
  },
});
</script>
