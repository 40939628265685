import { ProductSearchFilters } from "./ProductSearchFilters";

export class ProductSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new ProductSearchFilters();

  constructor(query: string) {
    this.query = query;
  }

  public equals = (other: ProductSearchRequest): boolean => {
    return (
      this.query === other.query &&
      this.page === other.page &&
      this.pageSize === other.pageSize &&
      this.sortBy === other.sortBy &&
      this.sortOrder === other.sortOrder &&
      this.filters.equals(other.filters)
    );
  };
}
