import { useAuth } from "@cumulus/event-bus";
import { PriceGroup } from "./model/PriceGroup";
import { useErrorHandler } from "../ErrorHandler";
import { priceGroupApi } from "./PriceGroupApi";

export function usePriceGroup() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getDefaultPriceGroupByUsersClient = async (): Promise<PriceGroup> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await priceGroupApi.getDefault(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getDefaultPriceGroupByUsersClient };
}
