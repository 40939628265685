import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Attribute } from "@/models/attribute/Attribute";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

export const useAddAttributeStore = defineStore("addAttributeStore", () => {
  const attribute = ref<Attribute>(new Attribute());
  const initialAttribute = ref<Attribute>(cloneDeep(attribute.value));

  const isAttributeModified = computed(() => {
    return !isEqual(attribute.value, initialAttribute.value);
  });

  const attributeNamesByLanguage = ref<Record<LanguageIsoType, string>>({
    [LanguageIsoType.English]: "",
    [LanguageIsoType.Norwegian]: "",
  });

  const attributeValuesByLanguage = ref<Record<LanguageIsoType, AttributeValueByLanguage[]>>({
    [LanguageIsoType.English]: [],
    [LanguageIsoType.Norwegian]: [],
  });

  const languageModified = ref<Record<LanguageIsoType, boolean>>({
    [LanguageIsoType.English]: false,
    [LanguageIsoType.Norwegian]: false,
  });

  const setAttributeName = (language: LanguageIsoType, value: string) => {
    attributeNamesByLanguage.value[language] = value;
    const index = attribute.value.names.findIndex((n) => n.languageIso === language);
    if (index !== -1) {
      attribute.value.names[index].text = value;
    } else {
      attribute.value.names.push(new LanguageText(language, value));
    }
    languageModified.value[language] = true;
    copyLanguageValues(language);
  };

  const setAttributeValue = (language: LanguageIsoType, newValue: AttributeValueByLanguage) => {
    const values = attributeValuesByLanguage.value[language];
    const index = values.findIndex((v) => v.id === newValue.id);
    if (index !== -1) {
      values[index] = newValue;
    } else {
      values.push(newValue);
    }

    const attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
    if (attributeValue) {
      attributeValue.setValueForLanguage(language, newValue.value);
      attributeValue.setDescriptionForLanguage(language, newValue.description);
      attributeValue.additionalProperties = newValue.additionalProperties;
    } else {
      const newAttributeValue = new AttributeValue();
      newAttributeValue.id = newValue.id;
      newAttributeValue.setValueForLanguage(language, newValue.value);
      newAttributeValue.setDescriptionForLanguage(language, newValue.description);
      newAttributeValue.additionalProperties = newValue.additionalProperties;
      attribute.value.values.push(newAttributeValue);
    }
    languageModified.value[language] = true;
    setAdditionalPropertiesForAllLanguages(newValue);

    copyLanguageValues(language);
  };

  const setAdditionalPropertiesForAllLanguages = (newValue: AttributeValueByLanguage) => {
    Object.values(LanguageIsoType).forEach((lang) => {
      const values = attributeValuesByLanguage.value[lang];
      const index = values.findIndex((v) => v.id === newValue.id);
      if (index !== -1) {
        values[index].additionalProperties = newValue.additionalProperties;
      } else {
        values.push(newValue);
      }

      const attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
      if (attributeValue) {
        attributeValue.additionalProperties = newValue.additionalProperties;
      } else {
        const newAttributeValue = new AttributeValue();
        newAttributeValue.id = newValue.id;
        newAttributeValue.additionalProperties = newValue.additionalProperties;
        attribute.value.values.push(newAttributeValue);
      }
    });
  };

  const addNewAttributeValue = () => {
    const newValue = new AttributeValue();
    attribute.value.values.push(newValue);
    Object.values(LanguageIsoType).forEach((lang) => {
      attributeValuesByLanguage.value[lang].push({
        id: newValue.id,
        value: "",
        description: "",
        additionalProperties: {},
      });
    });
  };

  const copyLanguageValues = (sourceLanguage: LanguageIsoType) => {
    const targetLanguage =
      sourceLanguage === LanguageIsoType.English ? LanguageIsoType.Norwegian : LanguageIsoType.English;

    if (!languageModified.value[targetLanguage]) {
      attributeNamesByLanguage.value[targetLanguage] = attributeNamesByLanguage.value[sourceLanguage];
      const nameIndex = attribute.value.names.findIndex((n) => n.languageIso === targetLanguage);
      if (nameIndex !== -1) {
        attribute.value.names[nameIndex].text = attributeNamesByLanguage.value[sourceLanguage];
      } else {
        attribute.value.names.push(new LanguageText(targetLanguage, attributeNamesByLanguage.value[sourceLanguage]));
      }

      attributeValuesByLanguage.value[sourceLanguage].forEach((sourceValue) => {
        const targetValue = attributeValuesByLanguage.value[targetLanguage].find((v) => v.id === sourceValue.id);
        if (targetValue) {
          targetValue.value = sourceValue.value;
          targetValue.description = sourceValue.description;
        } else {
          attributeValuesByLanguage.value[targetLanguage].push({ ...sourceValue });
        }

        const attributeValue = attribute.value.values.find((v) => v.id === sourceValue.id);
        if (attributeValue) {
          attributeValue.setValueForLanguage(targetLanguage, sourceValue.value);
          attributeValue.setDescriptionForLanguage(targetLanguage, sourceValue.description);
        }
      });
    }
  };

  const deleteValue = (valueId: string) => {
    attribute.value.values = attribute.value.values.filter((v) => v.id !== valueId);

    Object.values(LanguageIsoType).forEach((lang) => {
      attributeValuesByLanguage.value[lang] = attributeValuesByLanguage.value[lang].filter((v) => v.id !== valueId);
    });
  };

  const resetStore = () => {
    attribute.value = new Attribute();
    initialAttribute.value = cloneDeep(attribute.value);
    attributeNamesByLanguage.value = {
      [LanguageIsoType.English]: "",
      [LanguageIsoType.Norwegian]: "",
    };
    attributeValuesByLanguage.value = {
      [LanguageIsoType.English]: [],
      [LanguageIsoType.Norwegian]: [],
    };
    languageModified.value = {
      [LanguageIsoType.English]: false,
      [LanguageIsoType.Norwegian]: false,
    };
  };

  return {
    attribute,
    attributeNamesByLanguage,
    attributeValuesByLanguage,
    isAttributeModified,
    setAttributeName,
    setAttributeValue,
    addNewAttributeValue,
    deleteValue,
    copyLanguageValues,
    resetStore,
  };
});
