import { defineStore } from "pinia";
import { ref } from "vue";
import type { ProductImportInitializeResponse } from "@/product/models/ProductImportInitializeResponse";
import type { ProductImportMapRequest } from "@/product/models/ProductImportMapRequest";
import type { ValidationResultsResponse } from "@/product/models/ValidationResultsResponse";
import type { ParsedRow } from "@/product/models/ParsedRow";
import type { ColumnMappingSuggestionResponse } from "@/product/models/ColumnMappingSuggestionResponse";
import type { ProductImportSessionResponse } from "@/product/models/ProductImportSessionResponse";
import type { ProductImportPreviewResponse } from "@/product/models/ProductImportPreviewResponse";
import { SystemField } from "@/product/models/SystemField";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { productImportApi } from "../api/import/ProductImportApi";
import { ColumnMapping } from "../models/ColumnMapping";
import type { MappedImportProduct } from "../models/MappedImportProduct";
import type { ProductImportSession } from "../models/ProductImportSession";
import { useProductImportOptionsStore } from "./ProductImportOptionsStore";

export const useProductImportSessionStore = defineStore("productImportSession", () => {
  const importId = ref<string>("");
  const columns = ref<string[]>([]);
  const rows = ref<ParsedRow[]>([]);
  const systemFields = ref<SystemField[]>([]);
  const suggestedMappings = ref<ColumnMapping[]>([]);
  const confidenceScores = ref<Record<string, number>>({});
  const columnMappings = ref<ColumnMapping[]>([]);

  const loading = ref({
    initializeImport: false,
    validationResult: false,
    systemFields: false,
    columnMappingSuggestions: false,
    importSessions: false,
    importSession: false,
    importSessionSummary: false,
    importSessionPreview: false,
    updateProduct: false,
    startImport: false,
  });

  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { setOptions } = useProductImportOptionsStore();

  const resetState = (): void => {
    columns.value = [];
    rows.value = [];
    suggestedMappings.value = [];
    confidenceScores.value = {};
    columnMappings.value = [];
  };

  const clearMappingForColumn = (systemFieldName: string): void => {
    const mapping = columnMappings.value.find((m) => m.systemField === systemFieldName);
    if (mapping) {
      mapping.systemField = "";
    }
  };

  const setSuggestions = (mappings: ColumnMapping[], scores: Record<string, number>) => {
    suggestedMappings.value = mappings;
    confidenceScores.value = scores;

    columnMappings.value = mappings.map((mapping) => ({ ...mapping }));
  };

  const initializeImport = async (formData: FormData): Promise<ProductImportInitializeResponse> => {
    try {
      loading.value.initializeImport = true;
      const authHeaders = await getAuthHeaders();
      const response = await productImportApi.initializeImport(authHeaders, formData);

      importId.value = response.id;
      columns.value = response.columns;
      rows.value = response.rows;
      setOptions(response.options);

      if (response.suggestedMappings && response.suggestedMappings.length > 0) {
        setSuggestions(response.suggestedMappings, response.confidenceScores || {});
      } else {
        columnMappings.value = response.columns.map((col) => ({
          importColumn: col,
          systemField: "",
        }));
      }

      return response;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.initializeImport = false;
    }
  };

  const getWebPubSubUrl = async (): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getWebPubSubUrl(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const startMapping = async (request: ProductImportMapRequest): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await productImportApi.startMapping(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getSystemFields = async (): Promise<SystemField[]> => {
    try {
      if (systemFields.value.length === 0) {
        loading.value.systemFields = true;
        const authHeaders = await getAuthHeaders();
        systemFields.value = await productImportApi.getSystemFields(authHeaders);
      }
      return systemFields.value;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.systemFields = false;
    }
  };

  const getValidationResult = async (
    id: string,
    page: number,
    pageSize: number,
  ): Promise<ValidationResultsResponse> => {
    try {
      loading.value.validationResult = true;
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getValidationResult(authHeaders, id, page, pageSize);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.validationResult = false;
    }
  };

  const getColumnMappingSuggestions = async (
    columns: string[],
    previewRows: ParsedRow[],
  ): Promise<ColumnMappingSuggestionResponse> => {
    try {
      loading.value.columnMappingSuggestions = true;
      const authHeaders = await getAuthHeaders();
      const response = await productImportApi.getColumnMappingSuggestions(authHeaders, columns, previewRows);

      if (response.mappings && response.mappings.length > 0) {
        setSuggestions(response.mappings, response.confidenceScores || {});
      }

      return response;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.columnMappingSuggestions = false;
    }
  };

  const getImportSessions = async (
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: string,
  ): Promise<ProductImportSessionResponse> => {
    try {
      loading.value.importSessions = true;
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getImportSessions(authHeaders, page, pageSize, sortBy, sortOrder);
    } catch (error) {
      console.error("Error fetching import sessions:", error);
      throw error;
    } finally {
      loading.value.importSessions = false;
    }
  };

  const getImportSession = async (id: string): Promise<ProductImportSession> => {
    try {
      loading.value.importSession = true;
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getImportSession(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.importSession = false;
    }
  };

  const getImportSessionPreview = async (id: string): Promise<ProductImportPreviewResponse> => {
    try {
      loading.value.importSessionPreview = true;
      importId.value = id;

      resetState();

      const authHeaders = await getAuthHeaders();
      const response = await productImportApi.getImportSessionPreview(authHeaders, id);

      columns.value = response.columns;
      rows.value = response.rows;
      setOptions(response.options);

      if (response.suggestedMappings && response.suggestedMappings.length > 0) {
        setSuggestions(response.suggestedMappings, response.confidenceScores || {});
      } else {
        columnMappings.value = response.columns.map((col) => ({
          importColumn: col,
          systemField: "",
        }));
      }

      return response;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.importSessionPreview = false;
    }
  };

  const updateProduct = async (id: string, rowNumber: number, product: MappedImportProduct): Promise<void> => {
    try {
      loading.value.updateProduct = true;
      const authHeaders = await getAuthHeaders();
      await productImportApi.updateMappedProduct(authHeaders, id, rowNumber, product);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.updateProduct = false;
    }
  };

  const startImport = async (id: string): Promise<void> => {
    try {
      loading.value.startImport = true;
      const authHeaders = await getAuthHeaders();
      await productImportApi.startImport(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value.startImport = false;
    }
  };

  return {
    importId,
    columns,
    rows,
    systemFields,
    suggestedMappings,
    confidenceScores,
    columnMappings,
    loading,
    clearMappingForColumn,
    resetState,
    setSuggestions,
    initializeImport,
    getWebPubSubUrl,
    startMapping,
    getSystemFields,
    getValidationResult,
    getColumnMappingSuggestions,
    getImportSessions,
    getImportSession,
    getImportSessionPreview,
    updateProduct,
    startImport,
  };
});
