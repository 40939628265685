<template>
  <div class="flex place-items-start mx-4 mt-4">
    <FloatLabel variant="on" :pt:root:class="`w-full mx-2`">
      <Textarea
        id="ai-prod-info"
        v-model="prompt"
        class="inputfield w-full pt-3"
        dataTestId="ai-prod-info"
        :autoResize="false"
        rows="15"
        style="overflow-y: auto"
        :disabled="isDisabled"
      />
      <label for="delivery-address-lines">{{ t("product.ai-search.productInfo") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AIStandardPrompt } from "@/models/product-info/AIStandardPrompt";
import { computed } from "vue";

const { t } = useI18n();
const productInfo = defineModel<{ promptTitle: string }>("productInfo");

const prompt = defineModel<string>("prompt");

const isDisabled = computed(() => {
  return (
    productInfo.value?.promptTitle === AIStandardPrompt.DetailedPrompt ||
    productInfo.value?.promptTitle === AIStandardPrompt.SimplePrompt
  );
});
</script>
