<template>
  <div class="c-product-document-upload" :tabindex="0" @keydown="handleKeydown">
    <div class="col-12 md:col-12 lg:col-12 p-0">
      <DocumentUploader
        :documents="documents"
        @documentUploaded="addDocument($event)"
        @clearDocumentsClicked="clearDocuments"
      />
      <ProductDocumentCarousel
        v-if="documents.length > 0"
        :documents="documents"
        @updateDocument="updateDocument"
        @deleteDocument="removeDocument"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { ref } from "vue";
import { ProductDocument } from "@/repositories/product/model/ProductDocument";
import DocumentUploader from "./DocumentUploader.vue";
import ProductDocumentCarousel from "./ProductDocumentCarousel.vue";
import { useConfirm } from "primevue/useconfirm";

const { t } = useI18n();

const confirm = useConfirm();
const tabIndexRef = ref(0);

const documents = defineModel<ProductDocument[]>("documents", {
  required: true,
});
const uploadOnKeyPress = () => {
  const uploadButton = document.getElementById("upload-btn");
  if (uploadButton) {
    uploadButton.focus();
    uploadButton.click();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    event.preventDefault();
    if (documents.value.length > 0) {
      const titleInput = document.getElementById("document-title-input");
      if (titleInput) {
        titleInput.focus();
      }
    } else {
      uploadOnKeyPress();
    }
  } else if (event.key === "Tab") {
    const titleInput = document.getElementById("document-title-input");
    if (document.activeElement === titleInput) {
      tabIndexRef.value = 0;
    } else {
      tabIndexRef.value = -1;
    }
  }
};

const addDocument = (document: ProductDocument) => {
  documents.value.push(document);
};

const removeDocument = async (url: string) => {
  documents.value = documents.value.filter((document) => document.documentUrl !== url);
};

const updateDocument = (index: number, updateDocument: ProductDocument) => {
  documents.value[index] = updateDocument;
};

const clearDocuments = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm-all"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      documents.value = [];
    },
  });
};
</script>
