<template>
  <DataTable
    :value="pricesComputed"
    class="c-product-price-table c-datatable"
    dataKey="id"
    data-testid="product-price"
    :autoLayout="true"
    responsiveLayout="stack"
    breakpoint="991px"
    :loading="loading"
    stripedRows
  >
    <template #loading>{{ t("product.price.loading") }}</template>

    <Column field="priceGroupName" :header="t(`product.price.price-group-name`)">
      <template #body="{ data }">
        {{ data.priceGroupName ? t(`product.price.${data.priceGroupName.toLowerCase()}`) : "" }}
      </template>
    </Column>

    <Column field="currencyIso" :header="t(`product.price.currency`)">
      <template #body="{ data }">
        {{ data.currencyIso }}
      </template>
    </Column>

    <Column :header="t(`product.price.cost-price`)">
      <template #body>
        {{ n(mainSupplierCostPrice, "currency", clientCurrencyIso) }}
      </template>
    </Column>

    <Column :header="t(`product.price.gross-margin`)">
      <template #body="{ data }">
        <InputNumber
          :modelValue="PriceFunctions.grossMargin(data.listPrice, mainSupplierCostPrice)"
          mode="decimal"
          :allow-empty="false"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          id="price-gross-margin"
          v-bind:class="data.grossMargin < 0 ? 'c-warning' : ''"
          @update:modelValue="updateGrossMargin($event, data)"
          @focus="selectAllOnFocus"
          :aria-label="t('product.price.gross-margin')"
        />
      </template>
    </Column>

    <Column :header="t(`product.price.contribution-margin`)">
      <template #body="{ data }">
        <InputNumber
          :modelValue="PriceFunctions.contributionMargin(data.listPrice, mainSupplierCostPrice)"
          suffix="%"
          mode="decimal"
          :allow-empty="false"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :min="0"
          :max="100"
          id="price-contribution-margin"
          v-bind:class="data.contributionMargin < 0 ? 'c-warning' : ''"
          @update:modelValue="updateContributionMargin($event, data)"
          @focus="selectAllOnFocus"
          :aria-label="t('product.price.contribution-margin')"
        />
      </template>
    </Column>

    <Column :header="t(`product.price.listprice-excl-vat`)">
      <template #body="{ data }">
        <InputNumber
          :modelValue="data.listPrice"
          mode="decimal"
          :min="0"
          :allow-empty="false"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          id="price-list-price-excl-vat"
          v-bind:class="data.listPrice < 0 ? 'c-warning' : ''"
          @update:modelValue="updateListPriceExclVat($event, data)"
          @focus="selectAllOnFocus"
          :aria-label="t('product.price.listprice-excl-vat')"
        />
      </template>
    </Column>

    <Column :header="t(`product.price.listprice-incl-vat`)">
      <template #body="{ data }">
        <InputNumber
          :modelValue="PriceFunctions.priceIncVatFromExVat(data.listPrice, props.vatPercentage)"
          mode="decimal"
          :min="0"
          :allow-empty="false"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          id="price-list-price-incl-vat"
          v-bind:class="data.listPriceInclVat < 0 ? 'c-warning' : ''"
          @update:modelValue="updateListPriceInclVat($event, data)"
          @focus="selectAllOnFocus"
          :aria-label="t('product.price.listprice-incl-vat')"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { Price } from "@/repositories/product/model/Price";
import { usePriceFunctions } from "../PriceFunctions";

const { t, n } = useI18n();
const PriceFunctions = usePriceFunctions();

const loading = ref(false);

const props = defineProps<{
  prices: Price[];
  mainSupplierCostPrice: number;
  clientCurrencyIso: string;
  vatPercentage: number | null;
}>();

const emit = defineEmits<{
  (e: "updatePrice", value: { price: Price }): void;
}>();

const pricesComputed = computed<Price[]>(() => {
  return props.prices.length > 0 ? cloneDeep(props.prices) : [];
});

const updateGrossMargin = (value: number, price: Price) => {
  if (PriceFunctions.grossMargin(price.listPrice, props.mainSupplierCostPrice) !== value) {
    price.listPrice = PriceFunctions.priceFromGrossMargin(props.mainSupplierCostPrice, value);
    emit("updatePrice", { price });
  }
};

const updateContributionMargin = (value: number, price: Price) => {
  if (PriceFunctions.contributionMargin(price.listPrice, props.mainSupplierCostPrice) !== value) {
    price.listPrice = PriceFunctions.priceFromContributionMargin(props.mainSupplierCostPrice, value);
    emit("updatePrice", { price });
  }
};

const updateListPriceExclVat = (value: number, price: Price) => {
  if (price.listPrice !== value) {
    price.listPrice = value;
    emit("updatePrice", { price });
  }
};

const updateListPriceInclVat = (value: number, price: Price) => {
  if (PriceFunctions.priceIncVatFromExVat(price.listPrice, props.vatPercentage) !== value) {
    price.listPrice = PriceFunctions.priceFromIncVat(value, props.vatPercentage);
    emit("updatePrice", { price });
  }
};

const selectAllOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};
</script>

<style scoped lang="scss">
.c-product-price-table {
  :deep(.p-datatable-thead > tr > th) {
    padding: 0 1rem;
  }

  :deep(.p-inputnumber-input) {
    max-width: 8rem;
  }

  :deep(.c-warning .p-inputnumber-input) {
    color: #ff0000;
  }
}
.c-product-price-column-remove {
  min-width: 3rem;
  padding: 0;

  :deep(.p-column-header-content) {
    float: right;
  }
}
</style>
