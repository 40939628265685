import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";
import { ApiRequestError } from "@cumulus/http/dist/error-handler";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  enum SeverityType {
    Error = "error",
    Warning = "warn",
    Success = "success",
  }

  const handleError = async (error: unknown): Promise<void> => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    const summary = t("common.error-detail.error-occured");

    let errorMessage = "";
    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    } else if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    } else if (error instanceof BadRequestError) {
      const errorMessages: string[] = error.message.map((message: ApiRequestError) => message.errorMessage);

      if (error.message[0].errorKey === "ProductHierarchyExist") {
        addToast(SeverityType.Warning, t("product-hierarchy.toast.exists"), "");
        return;
      }

      if (error.message[0].errorKey === "ProductHierarchyIsActive") {
        addToast(SeverityType.Warning, t("product-hierarchy.toast.is-active"), "");
        return;
      }

      if (error.message[0].errorKey === "ProductGroupNameExist") {
        addToast(SeverityType.Warning, t("product-group-name.toast.exists"), "");
        return;
      }

      if (error.message[0].errorKey === "ProductGroupNameIsActive") {
        addToast(SeverityType.Warning, t("product-group-name.toast.is-active"), "");
        return;
      }

      if (errorMessages.length > 0) {
        errorMessages.forEach((m) => addToast(SeverityType.Error, summary, m));
      } else {
        errorMessage = t("common.error-detail.bad-request");
      }
    } else {
      errorMessage = t("common.error-detail.unknown");
    }

    if (errorMessage !== "") {
      addToast(SeverityType.Error, summary, errorMessage);
    }
  };

  const addToast = (severity: SeverityType, summary: string, message: string): void => {
    toast.add({
      severity: severity,
      summary: summary,
      detail: message,
      closable: true,
    });
  };

  return { handleError, SeverityType, addToast };
}
