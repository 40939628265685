import { Tax } from "@/repositories/tax/model/Tax";
import { TaxType } from "@/repositories/tax/model/TaxType";

export class ProductTax {
  taxId = "";
  taxRate = 0;
  taxType = TaxType.Incoming;
  countryIso = "";

  constructor(tax: Tax | null = null) {
    if (tax !== null) {
      this.taxId = tax.id;
      this.taxRate = tax.rate;
      this.taxType = tax.taxType;
      this.countryIso = tax.countryIso;
    }
  }
}
