<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <label class="block text-xs font-medium text-gray-700">
        {{
          t("product.import.set-default-field", {
            field: t("product.import.system-fields.name").toLowerCase(),
          })
        }}
      </label>
    </div>

    <InputText
      v-model="defaultName"
      class="w-full"
      :placeholder="t('placeholder.type', { property: t('product.import.system-fields.name').toLowerCase() })"
    />

    <div class="flex justify-end mt-3">
      <Button
        :label="t('common.clear')"
        size="small"
        severity="secondary"
        :aria-label="t('common.clear')"
        @click="clearSelection"
      />
      <Button :label="t('common.apply')" size="small" :disabled="!defaultName" @click="applySelection" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const optionsStore = useProductImportOptionsStore();
const { importOptions } = storeToRefs(optionsStore);
const { clearMappingForColumn } = useProductImportSessionStore();
const { t } = useI18n();

const defaultName = ref(importOptions.value.defaultValues.name);

const clearSelection = () => {
  defaultName.value = null;
  optionsStore.setDefaultName(null);
};

const applySelection = () => {
  optionsStore.setDefaultName(defaultName.value);
  clearMappingForColumn("name");
};
</script>
