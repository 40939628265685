import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { manufacturerApi } from "@/repositories/manufacturer/ManufacturerApi";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { Manufacturer } from "./model/Manufacturer";
import { NewManufacturer } from "./model/NewManufacturer";
import { UpdateManufacturer } from "./model/UpdateManufacturer";

export const useManufacturerStore = defineStore("manufacturer", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const manufacturers = ref<Manufacturer[]>([]);
  const loading = ref(false);
  const saving = ref(false);

  const manufacturerById = (id: string): Manufacturer => {
    return manufacturers.value.find((manufacturer) => manufacturer.id === id) ?? new Manufacturer();
  };

  const manufacturerByName = (name: string): Manufacturer => {
    return manufacturers.value.find((manufacturer) => manufacturer.name === name) ?? new Manufacturer();
  };

  const fetchManufacturers = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      manufacturers.value = await manufacturerApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const createManufacturer = async (manufacturer: NewManufacturer): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await manufacturerApi.create(authHeaders, manufacturer);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const updateManufacturer = async (manufacturer: Manufacturer): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      const updateManufacturer = new UpdateManufacturer(manufacturer);
      await manufacturerApi.update(authHeaders, updateManufacturer);
      const index = manufacturers.value.findIndex((cg) => cg.id === manufacturer.id);
      manufacturers.value[index] = manufacturer;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const deleteManufacturer = async (manufacturerId: string): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await manufacturerApi.delete(authHeaders, manufacturerId);
      manufacturers.value = manufacturers.value.filter((cg) => cg.id !== manufacturerId);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  return {
    manufacturers,
    loading,
    saving,
    manufacturerById,
    manufacturerByName,
    fetchManufacturers,
    createManufacturer,
    updateManufacturer,
    deleteManufacturer,
  };
});
