<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <label class="block text-xs font-medium text-gray-700">
        {{
          t("product.import.set-default-field", {
            field: t("product.import.system-fields.producttype").toLowerCase(),
          })
        }}
      </label>
    </div>

    <Select
      v-model="selectedType"
      :options="productTypes"
      class="w-full"
      :placeholder="t('placeholder.select', { property: t('product.import.system-fields.producttype').toLowerCase() })"
      @change="applySelection"
    />

    <div class="flex justify-end mt-3">
      <Button
        :label="t('common.clear')"
        size="small"
        severity="secondary"
        :aria-label="t('common.clear')"
        @click="clearSelection"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { ProductType } from "@/repositories/product/model/ProductType";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

const optionsStore = useProductImportOptionsStore();
const { importOptions } = storeToRefs(optionsStore);
const { clearMappingForColumn } = useProductImportSessionStore();
const { t } = useI18n();

const productTypes = ref<ProductType[]>(Object.values(ProductType));

const selectedType = ref(importOptions.value.defaultValues.productType);

const clearSelection = () => {
  selectedType.value = null;
  optionsStore.setDefaultProductType(null);
};

const applySelection = () => {
  optionsStore.setDefaultProductType(selectedType.value);
  clearMappingForColumn("productType");
};
</script>
