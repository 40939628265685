import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { productHierarchyApi } from "./ProductHierarchyApi";
import type { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";

export function useProductHierarchyService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductGroupNames = async (): Promise<ProductGroupName[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productHierarchyApi.getProductGroupNames(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getProductGroupNames,
  };
}
