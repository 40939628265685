import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Attribute } from "@/models/attribute/Attribute";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import isEqual from "lodash.isequal";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";

export const useEditAttributeStore = defineStore("editAttributeStore", () => {
  const attribute = ref<Attribute>(new Attribute());
  const initialAttribute = ref<Attribute>(attribute.value);
  const { languages } = useProductLanguageFunctions();

  const isAttributeModified = computed(() => {
    return !isEqual(attribute.value, initialAttribute.value);
  });

  const attributeNamesByLanguageComputed = computed(() => {
    return languages.reduce(
      (acc, lang) => {
        acc[lang.code] = attribute.value.names.find((name) => name.languageIso === lang.code)?.text || "";
        return acc;
      },
      {} as Record<string, string>,
    );
  });

  const attributeValuesByLanguageComputed = computed(() => {
    return languages.reduce(
      (acc, lang) => {
        acc[lang.code] = attribute.value.values.map((value) => ({
          id: value.id,
          value: value.getValueForLanguage(lang.code),
          description: value.getDescriptionForLanguage(lang.code),
          additionalProperties: value.additionalProperties,
        }));
        return acc;
      },
      {} as Record<string, AttributeValueByLanguage[]>,
    );
  });

  const setAttributeName = (language: string, value: string) => {
    const index = attribute.value.names.findIndex((n) => n.languageIso === language);
    if (index !== -1) {
      attribute.value.names[index].text = value;
    } else {
      attribute.value.names.push(new LanguageText(language, value));
    }
  };

  const setAttributeValue = (language: string, newValue: AttributeValueByLanguage) => {
    let attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
    if (!attributeValue) {
      attributeValue = new AttributeValue();
      attributeValue.id = newValue.id;
      attribute.value.values.push(attributeValue);
    }

    attributeValue.setValueForLanguage(language, newValue.value);
    attributeValue.setDescriptionForLanguage(language, newValue.description);
    attributeValue.additionalProperties = newValue.additionalProperties;
  };

  const addNewAttributeValue = () => {
    const newValue = new AttributeValue();
    attribute.value.values.push(newValue);
  };

  const deleteValue = (valueId: string) => {
    attribute.value.values = attribute.value.values.filter((v) => v.id !== valueId);
  };

  const resetStore = () => {
    attribute.value = new Attribute();
  };

  return {
    attribute,
    attributeNamesByLanguageComputed,
    attributeValuesByLanguageComputed,
    isAttributeModified,
    initialAttribute,
    setAttributeName,
    setAttributeValue,
    addNewAttributeValue,
    deleteValue,
    resetStore,
  };
});
