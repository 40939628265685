<template>
  <div class="col-span-12 lg:col-span-12">
    <DataTable :value="relationsComputed" :autoLayout="true" dataKey="relationId" class="w-full table-fixed">
      <Column field="productImages" class="w-[10%] min-w-[61px] overflow-hidden text-ellipsis">
        <template #body="{ data }">
          <img
            v-if="data.productImages.length > 0"
            :src="resizeImage(data.productImages[0].url, 30, 30)"
            class="c-product-image"
            width="30"
            height="30"
            :alt="`${data.productImages[0].name}-product-image`"
          />
        </template>
      </Column>

      <Column
        field="productNumber"
        :header="t('product.relations.header-product-number')"
        class="w-[10%] min-w-[110px] overflow-hidden text-ellipsis"
      >
        <template #body="{ data }">
          {{ data.productNumber }}
        </template>
      </Column>

      <Column
        field="name"
        :header="t('product.relations.header-name')"
        class="w-[30%] min-w-[100px] overflow-hidden text-ellipsis"
      >
        <template #body="{ data }">
          {{ data.name }}
        </template>
      </Column>

      <Column
        field="description"
        :header="t('product.relations.header-description')"
        class="w-[30%] overflow-hidden text-ellipsis"
      >
        <template #body="{ data }">
          {{ data.description }}
        </template>
      </Column>

      <Column
        field="relationType"
        :header="t('product.relations.relation-type')"
        v-if="relationTypes.length > 1"
        class="w-[15%] min-w-[200px] overflow-hidden text-ellipsis"
      >
        <template #body="{ data }">
          <Select
            v-model="data.relationType"
            :options="relationTypesTranslated"
            optionLabel="label"
            optionValue="type"
            @change="setRelationType(data.relationId, $event)"
            class="w-[180px] max-w-[180px]"
          ></Select>
        </template>
      </Column>

      <Column v-else class="w-[15%] min-w-[200px] overflow-hidden text-ellipsis">
        <template #body>
          <!-- Empty cell to maintain column structure -->
        </template>
      </Column>

      <Column class="w-[5%] overflow-hidden text-ellipsis">
        <template #body="{ data, index }">
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-secondary p-button-text"
            @click.stop="removeRelation(data.relationId)"
            :data-testid="'btn-remove-' + index"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { Product } from "@/repositories/product/model/Product";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { useImageService } from "@/repositories/image/ImageService";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { type SelectChangeEvent } from "primevue";

const { t } = useI18n();
const { resizeImage } = useImageService();

const props = defineProps<{
  products: Product[];
  relations: ProductRelation[];
  relationTypes: ProductRelationType[];
}>();

const emit = defineEmits<{
  removeRelation: [value: string];
  setRelationType: [relationId: string, relationType: ProductRelationType];
}>();

const relationTypesTranslated = computed(() => {
  return props.relationTypes.map((type) => {
    return {
      label: t(`product.relations.${ProductRelationType[type].toLowerCase()}`),
      type,
    };
  });
});

class ProductRelationInfo {
  relationId: string = "";
  productNumber: string = "";
  name: string = "";
  description: string = "";
  productImages: ProductImage[] = [];
  relationType: ProductRelationType = ProductRelationType.None;
}

const relationsComputed = computed<ProductRelationInfo[]>(() => {
  return props.relations
    .filter((r) => props.relationTypes.indexOf(r.relationType) !== -1)
    .map((r) => {
      const product = props.products.find((p) => p.id === r.productId);
      const info = new ProductRelationInfo();
      info.relationId = r.id;
      info.relationType = r.relationType;
      info.productNumber = product?.productNumber || "";
      info.name = product?.name || "";
      info.description = product?.description || "";
      info.productImages = product?.productImages || [];
      return info;
    });
});

const removeRelation = (relationId: string) => {
  emit("removeRelation", relationId);
};

const setRelationType = (relationId: string, event: SelectChangeEvent) => {
  emit("setRelationType", relationId, event.value);
};
</script>
