<template>
  <div class="border-t bg-gray-50 p-3">
    <div class="flex justify-between items-center mb-2">
      <div v-if="defaultPriceGroup" class="text-sm">
        {{ t("product.import.using-default-price-group", { name: defaultPriceGroup.name }) }}
      </div>
      <div v-else class="text-sm text-yellow-600">
        {{ t("product.import.no-default-price-group") }}
      </div>
    </div>
    <div class="flex justify-end mt-3">
      <Button
        :label="t('common.clear')"
        size="small"
        severity="secondary"
        :aria-label="t('common.clear')"
        @click="clearSelection"
      />
      <Button :label="t('common.apply')" size="small" :disabled="!defaultPriceGroup" @click="applySelection" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Reference } from "@/product/models/ImportOptions";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const referenceStore = useProductImportReferenceStore();
const optionsStore = useProductImportOptionsStore();
const { clearMappingForColumn } = useProductImportSessionStore();

const defaultPriceGroup = ref<Reference | null>(null);
const loading = ref(false);

onMounted(async () => {
  try {
    loading.value = true;
    defaultPriceGroup.value = await referenceStore.getDefaultPriceGroup();
  } finally {
    loading.value = false;
  }
});

const clearSelection = () => {
  optionsStore.setDefaultPriceGroup(null);
};

const applySelection = () => {
  optionsStore.setDefaultPriceGroup(defaultPriceGroup.value);
  clearMappingForColumn("priceGroupNames");
};
</script>
