<template>
  <div class="mx-3 py-8 !w-1/5 inline-flex mr-2">
    <InputGroup>
      <InputGroupAddon>
        <Checkbox v-model="transfer" :binary="true"></Checkbox>
      </InputGroupAddon>
      <FloatLabel variant="on">
        <InputNumber
          :id="`ai-dimension-${label}`"
          v-model="dimension"
          :defaultValue="0"
          :data-testid="`ai-dimension-${label}`"
          type="number"
          class="inputfield w-full"
          :maxlength="20"
          :min="0"
          :allowEmpty="false"
        />
        <label :for="`ai-dimension-${label}`">{{ label }}</label>
      </FloatLabel>
      <InputGroupAddon>{{ unit }}</InputGroupAddon>
    </InputGroup>
  </div>
</template>

<script setup lang="ts">
const dimension = defineModel<number>("dimension", { required: true });
const transfer = defineModel<boolean>("transfer", { required: true });
const label = defineModel<string>("label");
const unit = defineModel<string>("unit");
</script>
