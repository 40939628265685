<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :header="t('product.import.results.title')"
    data-testid="import-results-dialog"
    class="w-[50rem]"
  >
    <div class="mb-6">
      <div
        :class="[
          'flex items-center p-4 rounded-lg border',
          hasErrors ? 'bg-red-50 border-red-200' : 'bg-green-50 border-green-200',
        ]"
      >
        <i
          :class="[
            'pi text-2xl mr-2',
            hasErrors ? 'pi-exclamation-circle text-red-500' : 'pi-check-circle text-green-500',
          ]"
        ></i>
        <div>
          <div class="font-semibold">
            {{
              hasErrors
                ? t("product.import.results.completed-with-errors")
                : t("product.import.results.completed-successfully")
            }}
          </div>
          <div class="text-sm text-gray-600">
            {{ t("product.import.results.completed-on", { date: d(session?.completedAt ?? "") }) }}
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 gap-4 mb-6">
      <div class="p-4 bg-gray-50 rounded-lg border">
        <div class="text-sm text-gray-600">{{ t("product.import.results.total-products") }}</div>
        <div class="text-xl font-semibold">{{ session?.totalImportingProducts || 0 }}</div>
      </div>
      <div class="p-4 bg-gray-50 rounded-lg border">
        <div class="text-sm text-gray-600">{{ t("product.import.results.successfully-imported") }}</div>
        <div class="text-xl font-semibold text-green-600">{{ session?.importedProducts || 0 }}</div>
      </div>
      <div class="p-4 bg-gray-50 rounded-lg border">
        <div class="text-sm text-gray-600">{{ t("product.import.results.failed") }}</div>
        <div class="text-xl font-semibold text-red-600">{{ session?.importErrors.length || 0 }}</div>
      </div>
    </div>

    <div v-if="hasErrors" class="mb-6">
      <div class="bg-white rounded-lg border">
        <div class="p-4 border-b">
          <h3 class="font-semibold flex items-center">
            <i class="pi pi-times-circle text-red-500 mr-2"></i>
            {{ t("product.import.results.error-details") }}
          </h3>
        </div>
        <div class="divide-y max-h-60 overflow-auto">
          <div v-for="error in session?.importErrors" :key="error.productNumber" class="p-4">
            <div class="font-medium">#{{ error.rowNumber }} - {{ error.productNumber }}</div>
            <div class="text-sm text-red-600 mt-1">{{ error.error }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-50 p-4 rounded-lg border">
      <div class="text-sm text-gray-600">{{ t("product.import.results.original-file") }}</div>
      <div class="font-medium">{{ session?.originalFileName }}</div>
    </div>

    <template #footer>
      <div class="flex justify-between">
        <Button :label="t('common.close')" severity="primary" @click="close" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { ProductImportSession } from "../../models/ProductImportSession";

const visible = defineModel<boolean>("visible", {
  required: true,
});

const props = defineProps<{
  session: ProductImportSession | null;
}>();

const { t, d } = useI18n();

const hasErrors = computed(() => (props.session?.importErrors?.length ?? 0) > 0);

const close = () => {
  visible.value = false;
};
</script>
