<template>
  <div>
    <label for="webshop-active" data-testid="webshop-active-label" class="font-bold">{{
      t("product.webshop-active")
    }}</label>

    <Checkbox
      v-model="activeOnWebshopComputed"
      inputId="webshop-active"
      :binary="true"
      data-testid="webshop-active"
      class="inputfield w-full"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, type WritableComputedRef } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  activeOnWebshop: boolean;
}>();

const emit = defineEmits<{
  (e: "update:activeOnWebshop", value: boolean): void;
}>();

const activeOnWebshopComputed: WritableComputedRef<boolean> = computed({
  get: () => {
    return props.activeOnWebshop ?? true;
  },
  set: (value) => {
    emit("update:activeOnWebshop", value);
  },
});
</script>
