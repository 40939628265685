import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { ManufacturerStatus } from "./ManufacturerStatus";
import { Manufacturer } from "./Manufacturer";

export class NewManufacturer {
  name = "";
  description = "";
  logo: ImageInfo = new ImageInfo();
  websiteUrl = "";
  manufacturerStatus = ManufacturerStatus.Active;

  public static createNewManufacturer(manufacturer: Manufacturer): NewManufacturer {
    const newManufacturer = new NewManufacturer();
    newManufacturer.name = manufacturer.name;
    newManufacturer.description = manufacturer.description;
    newManufacturer.logo = manufacturer.logo;
    newManufacturer.websiteUrl = manufacturer.websiteUrl;
    newManufacturer.manufacturerStatus = manufacturer.manufacturerStatus;
    return newManufacturer;
  }
}
