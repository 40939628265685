<template>
  <h2 class="text-center">{{ t("product.search-filters.status") }}</h2>

  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md"
    :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
  >
    <div class="centered-child">
      <label class="font-light" :for="'customer-type-' + value.status.toLowerCase()">{{
        t(`product.search.product-status.${value.status.toLowerCase()}`)
      }}</label>
    </div>

    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :inputId="'product-status-' + value.status.toLowerCase()"
        :data-testid="'product-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>
  <hr class="c-seperator-line my-8" />

  <h2 class="text-center">{{ t("product.search-filters.manufacturer") }}</h2>
  <MultiSelect
    id="inventory-manufacturers"
    v-model="selectedManufacturerComputed"
    data-testid="inventory-manufacturers"
    :placeholder="t('placeholder.select', { property: t('product.search-filters.manufacturer').toLowerCase() })"
    optionLabel="name"
    optionValue="id"
    :options="manufacturers"
    display="chip"
    class="w-full field"
    :filter="true"
    :loading="loading"
    pt:list:data-testid="inventory-manufacturers-list"
  />

  <hr class="c-seperator-line my-8" />

  <h2 class="text-center">{{ t("product.search-filters.group-level") }}</h2>

  <GroupLevelForm
    v-model:selectedGroupLevelInfo="selectedGroupLevelInfoComputed"
    :groupLevels="groupLevels"
    :productHierarchies="productHierarchies"
  />
</template>

<script setup lang="ts">
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import GroupLevelForm from "./GroupLevelForm.vue";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";
const { t } = useI18n();

const props = defineProps<{
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
  loading: boolean;
  groupLevels: ProductGroupName[];
  productHierarchies: ProductHierarchy[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: ProductSearchFilters): void;
}>();

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(ProductStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<ProductStatus[]>({
  get: () => props.filters.productStatuses,
  set: (value) => {
    const filters = { ...props.filters, productStatuses: value };
    emit("update:filters", filters);
  },
});

const selectedManufacturerComputed = computed<string[]>({
  get: () => props.filters.manufacturers,
  set: (value) => {
    const filters = { ...props.filters, manufacturers: value };
    emit("update:filters", filters);
  },
});

const selectedGroupLevelInfoComputed = computed<string[]>({
  get: () => props.filters.groupLevels,
  set: (value) => {
    const filters = { ...props.filters, groupLevels: value };
    emit("update:filters", filters);
  },
});
</script>
