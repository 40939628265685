import { useAuth } from "@cumulus/event-bus";
import { type Warehouse } from "./model/Warehouse";

import { useErrorHandler } from "../ErrorHandler";
import { warehouseApi } from "./WarehouseApi";
import { warehouseProductApi } from "./WarehouseProductApi";
import { type WarehouseProduct } from "./model/WarehouseProduct";

export function useWarehouse() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWarehouseProducts = async (productId: string): Promise<WarehouseProduct[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseProductApi.getByProductId(authHeaders, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllWarehouses, getWarehouseProducts };
}
