<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`product.client.dialog-header`)"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc.stop="escapeDialog"
    :style="{ width: '20rem' }"
  >
    <div class="c-client-add-dialog">
      <DataTable
        :value="filteredClients"
        class="c-datatable"
        dataKey="id"
        data-testid="product-client-add"
        filterDisplay="menu"
        :autoLayout="true"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-select="onRowSelect"
        :globalFilterFields="['name']"
        stripedRows
      >
        <template #empty>{{ t(`product.client.no-client-found`) }}</template>

        <Column field="name" :header="t(`product.client.name`)" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <PrimeButton
        :label="t(`common.cancel`)"
        data-testid="cancel-btn"
        @click="escapeDialog"
        class="c-dialog-default-button"
        autofocus
        severity="cancel"
        text
        :pt="{
          label: 'font-bold -mx-6',
        }"
        icon="pi pi-times"
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Client } from "@/repositories/client/model/Client";
import { DataTableRowSelectEvent } from "primevue/datatable";

const { t } = useI18n();

const props = defineProps<{
  showDialogAdd: boolean;
  selectedClients: string[];
  allClients: Client[];
}>();

const emit = defineEmits<{
  (e: "addClient", value: string): void;
  (e: "update:showDialogAdd", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialogAdd,
  set: (value) => emit("update:showDialogAdd", value),
});

const filteredClients = computed<Client[]>(() => {
  const result = props.allClients.filter((client: Client) => {
    const index = props.selectedClients?.findIndex((c: string) => c === client.id);
    return index === -1;
  });
  return result;
});

const onRowSelect = (event: DataTableRowSelectEvent) => {
  const client = event.data as Client;
  emit("addClient", client.id);
  escapeDialog();
};

const escapeDialog = async () => {
  visible.value = false;
  document.getElementById("add-client-button")?.focus();
};
</script>

<style scoped lang="scss">
.c-client-add-dialog {
  padding: 0 1.5rem;
  border: none;
}
</style>
