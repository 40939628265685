<template>
  <div class="card" v-if="documents.length > 0">
    <Carousel
      :value="documents"
      :numVisible="3"
      :numScroll="1"
      :responsiveOptions="responsiveOptions"
      :style="{ maxHeight: '25rem' }"
      data-testid="product-document-carousel"
      class="mt-5"
    >
      <template #item="{ data, index }">
        <DocumentCard
          :document="data"
          @deleteDocument="() => emit('deleteDocument', data.documentUrl)"
          @updateDocument="(document: ProductDocument) => emit('updateDocument', index, document)"
          :index="index"
          :data-testid="'c-document-card-' + index"
        />
      </template>
    </Carousel>
  </div>
  <span v-else class="text-gray-500 text-center block mt-4">
    {{ t("product.documents.no-documents-found") }}
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import DocumentCard from "./DocumentCard.vue";
import { ProductDocument } from "@/repositories/product/model/ProductDocument";

const { t } = useI18n();

defineProps<{
  documents: ProductDocument[];
}>();

const emit = defineEmits<{
  (e: "deleteDocument", documentUrl: string): void;
  (e: "updateDocument", index: number, updatedDocument: ProductDocument): void;
}>();

const responsiveOptions = ref([
  {
    breakpoint: "2100px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "1023px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "750",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);
</script>
